import { secureAPI } from '../services/API';
import { checkTokenValidate } from './../util/helper';
const basePath = '/vendor_credits';

//get all vendor credits
async function getAllVendorCredits(searchParam = '', selectElement = null) {
	let url = '/vendor_credits?status=A' + searchParam;
	if (selectElement) {
		url = `${url}&select=${selectElement}`;
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
// get one vendor credit based on id
async function getAVendorCredit(vc_id, content_type = '') {
	content_type = content_type ? `?content_type=${content_type}` : '';
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/vendor_credits/${vc_id}${content_type}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
//vendor credit create
async function createVendorCredit(payload) {

	const formData = new FormData();
	// add invoice_payload to form data
	if (payload && Object.keys(payload).length > 0) {
		formData.append('json_string', JSON.stringify({ ...payload }));
	}

	return new Promise((resolve, reject) => {
		secureAPI()
			.post('/vendor_credits', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}

			})
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
//update vendor credit
async function updateVendorcredit(payload, id) {
	const formData = new FormData();
	// add invoice_payload to form data
	if (payload && Object.keys(payload).length > 0) {
		formData.append('json_string', JSON.stringify({ ...payload }));
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/vendor_credits/${id}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
// patch a single invoice, for update only specific fields
async function patchVendorCredit(vendorCredit_id, vendorCredit_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/vendor_credits/${vendorCredit_id}`, vendorCredit_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err?.response?.data?.error);
			});
	});
}
//vendor credit delete
async function vendorCreditDelete(vc_id, commentPayload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/vendor_credits/${vc_id}`,{data:commentPayload})
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
// apply vendor credit on bills from vendor credit details page top right button "Apply to bill" link
async function applyVendorCreditsOnBills(vendorCredit_payload) {
	const vcid = vendorCredit_payload._id;	
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/vendor_credits/${vcid}/apply_on_bills`, vendorCredit_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
// delete single vendor credit from bill/vendor_credit details page
async function deleteVendorCreditsFromBill(vendorCreditId, payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/vendor_credits/${vendorCreditId}/delete_from_bill`, payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

// fetch credit note template with data
async function getVendorCreditTemplateWithData(invoice_id) {  
	return new Promise((resolve, reject) => {
	  secureAPI()
		.get(`/vendor_credits/${invoice_id}/email`)
		.then(({ data }) => {
		  if (data.success) {
			resolve(data.data); //return array
		  }
		})
		.catch((err) => {
		  checkTokenValidate(err);
		  reject(err.response.data.error);
		});
	});
  }



/**------------ Refund related routes--------------- */
async function createRefundOfVendorCredit(collection_id, refund_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`${basePath}/${collection_id}/refunds`, refund_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function getRefundOfVendorCredit(collection_id,refund_id){
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`${basePath}/${collection_id}/refunds/${refund_id}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function updateRefundOfVendorCredit(collection_id,refund_id,refund_payload){

	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`${basePath}/${collection_id}/refunds/${refund_id}`, refund_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function deleteRefundOfVendorCredit(collection_id,refund_id){
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`${basePath}/${collection_id}/refunds/${refund_id}`,{})
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
/**----------- End of Refund related routes----------- */









export {
	createVendorCredit,
	getAllVendorCredits,
	vendorCreditDelete,
	getAVendorCredit,
	updateVendorcredit,
	patchVendorCredit,
	applyVendorCreditsOnBills,
	deleteVendorCreditsFromBill,
	getVendorCreditTemplateWithData,

	// refund related
	createRefundOfVendorCredit,
	getRefundOfVendorCredit,
	updateRefundOfVendorCredit,
	deleteRefundOfVendorCredit
};
