import React, { useEffect, lazy } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";
const Page404Home = lazy(() => import("../pages/Page404Home"));

const PrivateRoute = (props) => {
	let location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");
	//Make it dynamic
	//const token = localStorage.getItem('token');
	const { isLoggedIn, language } = useSelector((state) => state.auth);
	//if user is not login then store the redirect url with redirect key
	const redirectPath = window.location.pathname.replace(/^\/[\w\d]+\//, "") + window.location.search; //remove the language

	//secure page list for check login or redirect to 404 page
	const securePagePath = [
		"dashboard",
		"items",
		"banking",
		"proforma_invoices",
		"invoices",
		"credit-notes",
		"collections",
		"salesorders",
		"customers",
		"bills",
		"expenses",
		"vendorcredits",
		"payments",
		"purchaseorders",
		"vendors",
		"accounting",
		"reports",
		"documents",
		"support",
		"settings",
		"organizations",
		"organization",
		"zatca",
		"my-account",
	];

	return (
		<Route
			render={
				() =>
					isLoggedIn ? (
						props.children
					) : securePagePath.includes(splitLocation[2]) ? (
						<Redirect to={{ pathname: `/${language}/login`, state: { referrer: redirectPath } }} />
					) : (
						<Route component={Page404Home} />
					)
				// <Redirect to={{ pathname: `/${language}/login`, state: { referrer: redirectPath } }} />
			}
		/>
	);
};

export default PrivateRoute;
