import moment from 'moment';
//ES2017
//pad a number with '0'
function padNumber(number, padValue = 5) {
	return String(number).padStart(padValue, '0');
}
/*

*/
const fixDecimal = (number, fix = 2) => {
	// fix = Number(fix);
	// let scale = Math.pow(10, fix);
	// let returnValue = Number((Math.round((Number(number)) * scale) / scale).toFixed(fix));

	return +(Math.round(number + "e+" + fix) + "e-" + fix);

	// const formatter = new Intl.NumberFormat('en-US', {
	// 	minimumFractionDigits: 2,
	// 	maximumFractionDigits: fix,
	// 	style: "decimal"
	// });
	// let returnValue = formatter.format(number);

	// return Number(returnValue);
};
const dayCalculate = (date, t) => {
	// date = moment(date).format('YYYY-MM-DD');
	let diff = moment(date).diff(moment().format('YYYY-MM-DD'), 'days');
	if (0 === diff) {
		return t('Today')//moment(date).format('h:mm a').toString();
	} else if (-1 === diff) {
		return '1 ' + t('day');
	} else {
		return diff * -1 + ' ' + t('days');
	}
};

/**
 * @desc format number with thousand separator and decimal for basic use, if you need custom format then use CustomNumberFormatForDisplay
 * @param {*} number 
 * @param {*} decimal 
 * @returns 
 */
const formatNumber = (number, decimal = 2) => {
    //fix to decimal digit
    let number_decimal = Number(number)?.toFixed(decimal);
	//if decimal is 0 then return the number without decimal
	if (decimal === 0) {
		let thousandSep = Number(number_decimal)?.toLocaleString();
		return thousandSep;
	}
  
    let number_part = number_decimal?.split('.');
  
    let integer_part = number_part[0];
    let decimal_part = number_part[1];
    //formate the integer part
    let thousandSep = Number(integer_part)?.toLocaleString();
  
    //contact both part with '.'
    return thousandSep + '.' + decimal_part?.toString();
  };

// const formatNumber = (number,decimal = 2, separator_option=',') => {
//     // Remove commas,space from the number string
// 	const regex_value = /[, ]+/g;
// 	number = number?.toString()?.replace(regex_value,'');
	
//     // Check if the number is negative
//     const isNegative = number < 0;
//     // Convert the number to positive if it's negative
//     number = Math.abs(number);
//     // Fix to decimal digit
//     let number_decimal = Number(number)?.toFixed(decimal);
//     // If decimal is 0 then return the number without decimal
//     if (decimal === 0) {
// 		let thousandSep = Number(number_decimal)?.toLocaleString()?.replace(regex_value,separator_option);
//         return isNegative ? `(${thousandSep})` : thousandSep;
//     }

//     let number_part = number_decimal?.split(".");
//     let integer_part = number_part[0];
//     let decimal_part = number_part[1] || 0;

//     // Format the integer part
//     let thousandSep = Number(integer_part)?.toLocaleString()?.replace(regex_value, separator_option);
	
//     // Concat both parts with '.'
//     let formattedNumber = thousandSep + "." + decimal_part?.toString();

//     // If the number was negative, return it in parentheses
//     return isNegative ? `(${formattedNumber})` : formattedNumber;
// };
export { padNumber, fixDecimal, dayCalculate,formatNumber };
