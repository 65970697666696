const gaMeasurementId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

export const initGA = () => {
	window.dataLayer = window.dataLayer || [];
	function gtag() {
		window.dataLayer.push(arguments);
	}
	gtag('js', new Date());
	gtag('config', gaMeasurementId);
};

export const logPageView = () => {
	if (typeof window !== 'undefined' && typeof document !== 'undefined' && window.gtag) {
		window.gtag('config', gaMeasurementId, { page_path: window.location.pathname });
	}
};

export const logEvent = (eventName, eventParams) => {
	if (typeof window !== 'undefined' && typeof document !== 'undefined' && window.gtag) {
		window.gtag('event', eventName, eventParams);
	}
};
