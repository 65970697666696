import { secureUploadAPI, secureAPI } from '../services/API';
import { checkTokenValidate } from './../util/helper';
/*
Account service api caller
*/
async function createAccount(account_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post('/accounts', account_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function getAllAccounts(selectElement = null, searchParam = '') {
  let url = '/accounts?status=A' + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function getAnAccount(account_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/accounts/${account_id}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function deleteAnAccount(account_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .delete(`/accounts/${account_id}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function updateAnAccount(account_id, account_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/accounts/${account_id}`, account_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

// bulk import of accounts
async function accountsImportUpload(file) {
  return new Promise((resolve, reject) => {
    secureUploadAPI()
      // .post(`/accounts/import/upload`, file)
      .post(`/accounts/import/upload/account_type`, file)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

//after mapping, send the mapped keys to the server
//for validation , it return validated data and other information
async function accountsImportPreview(file_id, payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post(`/accounts/import/preview/${file_id}`, payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function accountsImport(file_id, payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post(`/accounts/import/${file_id}`, payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
async function getBankAccounts(selectElement = null, searchParam = '') {
  let url = '/accounts/bankaccounts' + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

// account patch call, account name re-name
async function patchAccount(account_id, account_payload){

  try{
    const http_response =  await secureAPI().patch(`/accounts/${account_id}`, account_payload);
    if(http_response.data){
      const is_success = http_response.data.success;
      if(is_success){
        return http_response.data.data;
      }
    }
  }
  catch(error){
    checkTokenValidate(error);
    throw error?.response?.data?.error ?? error
  }

}

// get a account template information
async function getAccountTemplate(template_id, query_string=""){
  try{
    let q_params = ""
    if(query_string && query_string.length > 0){
      q_params = `?${query_string}`
    }
    const http_response =  await secureAPI().get(`/accounts/account_templates/${template_id}${q_params}`);
    if(http_response.data){
      const is_success = http_response.data.success;
      if(is_success){
        return http_response.data.data;
      }
    }
  }
  catch(error){
    checkTokenValidate(error);
    throw error?.response?.data?.error ?? error
  }
}
// get all templates
async function getListOfAccountTemplate(){
  try{
    const http_response =  await secureAPI().get(`/accounts/account_templates`);
    if(http_response.data){
      const is_success = http_response.data.success;
      if(is_success){
        return http_response.data.data;
      }
    }
  }
  catch(error){
    checkTokenValidate(error);
    throw error?.response?.data?.error ?? error
  }
}

// update a account template information
async function updateAccountTemplate(template_id,payload){
  try{
    const http_response =  await secureAPI().put(`/accounts/account_templates/${template_id}`,payload);
    if(http_response.data){
      const is_success = http_response.data.success;
      if(is_success){
        return http_response.data.data;
      }
    }
  }
  catch(error){
    checkTokenValidate(error);
    throw error?.response?.data?.error ?? error
  }
}

// create/clone a account template. 
async function createAccountTemplate(payload, query_string="") {
  let q_params = ""
  if(query_string && query_string.length > 0){
    q_params = `?${query_string}`
  }
  try{
    const http_response =  await secureAPI().post(`/accounts/account_templates${q_params}`,payload);
    if(http_response.data){
      const is_success = http_response.data.success;
      if(is_success){
        return http_response.data.data;
      }
    }
  }
  catch(error){
    checkTokenValidate(error);
    throw error?.response?.data?.error ?? error
  }
}

// delete a account template information
async function deleteAccountTemplate(template_id){
  try{
    const http_response =  await secureAPI().delete(`/accounts/account_templates/${template_id}`);
    if(http_response.data){
      const is_success = http_response.data.success;
      if(is_success){
        return http_response.data.data;
      }
    }
  }
  catch(error){
    checkTokenValidate(error);
    throw error?.response?.data?.error ?? error
  }
}

export {
  createAccount,
  getAnAccount,
  getAllAccounts,
  updateAnAccount,
  deleteAnAccount,
  accountsImportUpload,
  accountsImportPreview,
  accountsImport,
  getBankAccounts,
  patchAccount,
  // account template
  getAccountTemplate,
  getListOfAccountTemplate,
  updateAccountTemplate,
  createAccountTemplate,
  deleteAccountTemplate
};
