import { secureAPI } from './API';
import { checkTokenValidate } from '../util/helper';

/** 
 * @description createRecurringBill function used
 * to create a new recurring bill
 * call from browser
 * 
*/
async function createRecurringBill(recurring_bill_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post('/recurring_bills', recurring_bill_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

/**
 * @description getRecurringBills function used to
 * fetch all recurring bill 
 * call from browser
 * 
 */
async function getAllRecurringBills(searchParam = '', selectElement = '') {
  let url = '/recurring_bills?status=A' + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }

  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/**
 * @description getARecurringBill function used to 
 * fetch a single recurring bill
 * call from browser
 */
async function getARecurringBill(id,content_type='') {  
     
  content_type = content_type ? `?content_type=${content_type}` : '';
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/recurring_bills/${id}${content_type}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/**
 * @description patchRecurringBill function used to 
 * fetch a single recurring bill
 * call from browser
 */
async function patchRecurringBill(id, bill_payload) {       
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/recurring_bills/${id}`, bill_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data?.error);
      });
  });
}

/**
 * @description updateARecurringBill function used to 
 * update a single recurring bill
 * call from browser
 */
async function updateARecurringBill(id, bill_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/recurring_bills/${id}`, bill_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

/**
 * @description updateARecurringBill function used to 
 * update a single recurring bill
 * call from browser
 */
async function deleteARecurringBill(id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .delete(`/recurring_bills/${id}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

/**
 * @description updateARecurringBill function used to 
 * update a single recurring bill
 * call from browser
 */
async function toggleTransactionStatusOfARecurringBill(id,status='') {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/recurring_bills/${id}/transaction_status?status=${status}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

/**
 * @description changeRecurringBillTemplate function used to
 * update pdf template
 * call from browser
 */
async function changeRecurringBillTemplate({id, template_id}) {
  try {
    const response = await secureAPI().put(`/recurring_bills/${id}/template/${template_id}`);
    if (response.data.success) {
      return response.data.data;
    }
  } catch (err) {
    checkTokenValidate(err);
    throw new Error(err.response.data.error);
  }
}
/**
 * @description getAllChildBills function used to 
 * fetch all child bills of recurring bill according to payment_status
 * by default payment_status :=> all
 * call from browser
 * 
 */

async function getAllChildBills({id,searchParam = '', selectElement = '',payment_status=''}) { 
  
  let url = `/recurring_bills/${id}/bills?status=A&payment_status=${payment_status}` + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }
  try {
    const response = await secureAPI().get(url);
    if (response.data.success) {
      return response.data;
    }
  } catch (err) {
    checkTokenValidate(err);
    throw new Error(err.response.data.error);
  }
}

export {
  createRecurringBill,
  getAllRecurringBills,
  getARecurringBill,
  patchRecurringBill,
  updateARecurringBill,
  deleteARecurringBill,
  toggleTransactionStatusOfARecurringBill,
  changeRecurringBillTemplate,
  getAllChildBills,
}