import { secureAPI } from '../services/API';
import { checkTokenValidate } from './../util/helper';

async function createAVendor(payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post('/vendors', payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function getAllVendors(selectElement = null, searchParam = '') {
	let url = '/vendors?status=A&contact_type=V' + searchParam;
	if (selectElement) {
		url = `${url}&select=${selectElement}`;
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function getAVendor(contact_id, selectElement = null) {
	let url = `/vendors/${contact_id}`;
	if (selectElement) {
		url = `${url}?select=${selectElement}`;
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return a object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function deleteAVendor(contact_id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/vendors/${contact_id}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function updateAVendor(contact_id, payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/vendors/${contact_id}`, payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

// //get partials paid or unpaid bills (of vendor)
// async function getOutstandingBills(contact_id, query = '') {
// 	return new Promise((resolve, reject) => {
// 		secureAPI()
// 			.get(`/vendors/${contact_id}/outstandingbills${query}`)
// 			.then(({ data }) => {
// 				if (data.success) {
// 					resolve(data); //return array
// 				}
// 			})
// 			.catch((err) => {
// 				checkTokenValidate(err);
// 				reject(err.response.data.error);
// 			});
// 	});
// }

async function getUnusedCredits(contact_id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/vendors/${contact_id}/unusedcredits`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//patch a vendor
async function patchVendor(contact_id, payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/vendors/${contact_id}`, payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

export { createAVendor, getAVendor, updateAVendor, deleteAVendor, getAllVendors,  getUnusedCredits, patchVendor };
