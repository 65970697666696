import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//for validation
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as Yup from 'yup';

import { Container, Row, Col } from 'reactstrap';
import { Header, Footer, Loading } from '../../common';
import AddOrganizationForm from './AddOrganizationForm';
import {InvitationsList} from '../shared/InnerHeader';
// import { createNewOrganization } from '../../services/organizatiom.service';
// import { UPDATE_ORGANIZATION } from '../../store/actions/types';
// import { showAlert } from '../../util/alertModal';
import './../../translations/i18n';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../common/PageTitle';
import {getInvitations} from '../../services/users.service';
import { useHistory } from 'react-router-dom';
import { LOGIN_SUCCESS } from '../../store/actions/types';


const AddOrganization = () => {
	const { t } = useTranslation();
	const history =useHistory()
	const dispatch = useDispatch();
	const { userInfo, language } = useSelector((state) => state.auth);
	let userFName = userInfo?.first_name + " " + userInfo?.last_name;
	
	//TODO: SHOW INVITATIONS LIST IF HAVE ANY 
	const [dataLoader, setDataloader] = useState(true);
	const [hasInvitations, setHasInvitations]= useState(false);
	const [isAllRejected, setIsAllRejected]= useState(false);
	//on mount check if user has invitations, if yes then show
	//if user accept at least 1 invitations redirect to dashboard.
	//if reject all then show AddOrganizationForm
	useEffect(()=>{
		async function loadInvitations(){
			try{
				let invts = await getInvitations();
				setDataloader(false)
				if(invts?.length > 0){
					setHasInvitations(true)
				}
			}
			catch(error){
				setDataloader(false)
			}
		}
		loadInvitations();
	},[])


	//on invitation accept redirect to dashboard
	const handleOnInvitationAccept=async(token_and_user_infof)=>{
		if(token_and_user_infof){
			let userDetails = token_and_user_infof.user_info;
			localStorage.setItem('token', token_and_user_infof.token); //after redirected login from invitation accpet page replace this token with old one which dont have any organization id 
			localStorage.setItem('user_info', JSON.stringify(userDetails));
			dispatch({
				type: LOGIN_SUCCESS ,
				payload: { userInfo: userDetails },
			});
			history.push(`/${language}/dashboard`);
		}
	}

	// on all invitation reject show add new organization form
	const handleAllInvitationsReject = useCallback((status)=>{
		if(status){
			setIsAllRejected(true)
			setHasInvitations(false);
		}
	},[])

	
	return (
		<>
			<PageTitle title={`${t("Add new")} | ${t("Organization")}`} description="Aninvoice" url="" />
			<Header />
			<main className="logbody orgz position-relative">
				<span className="colbox1"></span>
				<span className="colbox2"></span>
				<section className="orgzbox">
					<Container>
						<div className={`logregfrmBox ${hasInvitations && !dataLoader && "prms"}`}>
							<section>
								<Container>
									<Row xs="1" sm="2">
										<Col className="lftpnl">
											<div className="d-flex align-items-center justify-content-center h-100">
												<div className="conts text-center">
													<h4 className="mb-0 text-white">
														{t("Welcome")} <span className="d-inline-block">{userFName}</span>
													</h4>
													{/*<p>
													 {t(
														'Here, you can add the organization details, like Name, VAT ID and address'
													)} 
												</p>*/}
												</div>
											</div>
										</Col>
										{dataLoader && <Loading loadClass="onlycont" />}
										{hasInvitations && !dataLoader && (
											<Col className="rgtpnl">
												<h4 className="mt-3" style={{ padding: "0 6px" }}>
													{t("Pending invitations")}
												</h4>
												<InvitationsList onOneAccept={handleOnInvitationAccept} onAllReject={handleAllInvitationsReject} getToken={true} />
											</Col>
										)}
										{(!hasInvitations || isAllRejected) && <AddOrganizationForm redirectUrl="dashboard" />}
									</Row>
								</Container>
							</section>
						</div>
					</Container>
				</section>
			</main>
			<Footer />
		</>
	);
};
export default AddOrganization;
