import { combineReducers } from 'redux';
// import { withReduxStateSync } from 'redux-state-sync';
import auth from './auth';
import message from './message';

const rootReducer = combineReducers({
	auth,
	message,
});

export default rootReducer; //withReduxStateSync

