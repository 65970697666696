import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import '../../translations/i18n';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { showAlert } from '../../util/alertModal';
import { userLanguage } from '../../util/helper';
import { getAllCustomers } from '../../services/customer.service'; //for customer
import { getAllUser } from '../../services/users.service';

export const ContactDropdownForSearch = ({ labelName, search_model, language, selected_contact_id, parentContactCallback }) => {
	const { t } = useTranslation();
	const fieldLanguage = userLanguage(language);
	const [searchedCustomer, setSearchedCustomer] = useState([]); //store searched custome here
	// const [customerName, setCustomerName] = useState({
	// 	label: queryParams['contact_name'],
	// 	value: queryParams['contact_id'], //queryParams['contact_name']
	// });
	const [customerName, setCustomerName] = useState(selected_contact_id);
	const [timer, setTimer] = useState(null); //for auto complete search delay time
	//styling fot react-select.
	const cstyle = {
		control: (base) => ({
			...base,
			margin: 0,
		}),
	};

	let noOptionsMessageCustom = "No customer found of this name";
	if (["vendorcredits", "payments", "bills"].includes(search_model) || ("expenses" === search_model && labelName === "Vendor")) {
		//for vendor
		noOptionsMessageCustom = "No vendor found of this name";
	}

	useEffect(() => {
		fetchContact();
	}, [search_model]);

	//for customer search. onchange of customer name and _id. send data to parent component.
	const onSelectChange = (customer) => {
		let selectedObject = {
			value: customer?.value || "",
			label: customer?.label || "",
		};
		setCustomerName(selectedObject);
		parentContactCallback(selectedObject);
	};

	//get search customer asyncly
	const fetchContact = (searchText = "") => {
		return new Promise(async (resolve, reject) => {
			let searchParam = "";
			let selectElement = "";
			clearTimeout(timer); //clear previous timer
			const newTimer = setTimeout(async () => {
				if (searchText.length >= 2) {
					searchParam = "&limit=10";
					selectElement = "_id,contact_name,customer_type,contact_persons,primary_contact_person,vat_details";
					selectElement += "&search_field=contact_name.en,contact_name.ar&search_value=" + searchText;
				}
				try {
					// let get_all_customer_res;
					let contact_type = "C"; //customer
					if (["vendorcredits", "payments", "bills","recurring-bills","recurring-expenses", "purchaseorders"].includes(search_model)) {
						contact_type = "V";
					} else if (["journal","recurring-journals"].includes(search_model)) {
						contact_type = "B";
					} else if ("expenses" === search_model && labelName === "Customer") {
						contact_type = "C";
					} else if ("expenses" === search_model && labelName === "Vendor") {
						contact_type = "V";
					} else if ("purchaseorders" === search_model) {
						contact_type = "V";
					}					
					//fetch customer or vendor or both
					let get_all_customer_res = await getAllCustomers(selectElement, searchParam, contact_type);

					if (get_all_customer_res) {
						setSearchedCustomer(get_all_customer_res.data);
						let mapped_for_react_select = get_all_customer_res.data?.map((customer) => ({
							value: customer._id,
							label: customer?.contact_name[fieldLanguage],
						}));
						resolve(mapped_for_react_select);
					}
				} catch (err) {
					showAlert(t("Some error happened while loading!") + `\n${err}`, true);
				}
			}, 150);
			setTimer(newTimer); //set new timer
		});
	};

	return (
		<>
			<Row>
				<Form.Label column sm={3}>
					{t(labelName)}
				</Form.Label>
				<Col>
					<AsyncSelect
						styles={cstyle}
						isClearable={true}
						placeholder={t("Select") + " " + t("or type")}
						required={true}
						classNamePrefix="react-select"
						className="align-middle"
						name="customer"
						value={customerName?.label ? customerName : ""}
						onChange={(customer) => {
							// setCustomerName(customer);
							onSelectChange(customer);
						}}
						defaultOptions={searchedCustomer.map((customer) => ({
							label: customer?.contact_name[fieldLanguage],
							value: customer?._id,
						}))}
						loadOptions={fetchContact}
						loadingMessage={() => t("Searching") + " ..."}
						noOptionsMessage={() => t(noOptionsMessageCustom)} //when no result found
					/>
					<div className="invalid-feedback"></div>
				</Col>
			</Row>
		</>
	);
};
// export default ContactDropdownForSearch;

export const UserDropdownForSearch = ({ labelName, search_model, language, selected_user_id, parentUserCallback }) => {
	const { t } = useTranslation();
	const [searchedUser, setSearchedUser] = useState([]); //store searched custome here
	const [userName, setUserName] = useState(selected_user_id);
	const [timer, setTimer] = useState(null); //for auto complete search delay time
	//styling fot react-select.
	const cstyle = {
		control: (base) => ({
			...base,
			margin: 0,
		}),
	};

	useEffect(() => {
		fetchUser();
	}, [search_model]);

	//for user search. onchange of user name and _id. send data to parent component.
	const onSelectChange = (user) => {
		let selectedObject = {
			value: user?.value || "",
			label: user?.label || "",
		};
		setUserName(selectedObject);
		parentUserCallback(selectedObject);
	};

	const fetchUser = (searchText = "") => {
		return new Promise(async (resolve, reject) => {
			// let searchParam = '';
			let selectElement = "";
			clearTimeout(timer); //clear previous timer
			const newTimer = setTimeout(async () => {
				if (searchText.length >= 2) {
					// searchParam = '&limit=10';
					selectElement = "_id,first_name,last_name";
					selectElement += "&search_value=" + searchText;
				}

				try {
					//fetch all active user
					let get_all_user_res = await getAllUser(selectElement, false);
					if (get_all_user_res?.data.length > 0) {
						//filter user by status (active)
						let all_active_user = get_all_user_res.data.filter((each_user) => each_user?.organizations?.[0]?.status === "A");
						setSearchedUser(all_active_user);
						let mapped_for_react_select = all_active_user?.map((user) => ({
							label: user?.first_name + " " + user?.last_name,
							value: user._id,
						}));

						resolve(mapped_for_react_select);
					} else {
						reject("No user found");
					}
				} catch (err) {
					showAlert(t("Some error happened while loading!") + `\n${err}`, true);
				}
			}, 150);
			setTimer(newTimer); //set new timer
		});
	};

	return (
		<>
			<Row>
				<Form.Label column sm={3}>
					{t(labelName)}
				</Form.Label>
				<Col>
					<AsyncSelect
						styles={cstyle}
						isClearable={true}
						placeholder={t("Select") + " " + t("or type")}
						required={true}
						classNamePrefix="react-select"
						className="align-middle"
						name="user_name"
						value={userName?.label ? userName : ""}
						onChange={(customer) => {
							onSelectChange(customer);
						}}
						defaultOptions={searchedUser.map((user) => ({
							label: user?.first_name + " " + user?.last_name,
							value: user?._id,
						}))}
						loadOptions={fetchUser}
						loadingMessage={() => t("Searching") + " ..."}
						noOptionsMessage={() => t("No user found of this name")} //when no result found
					/>
					<div className="invalid-feedback"></div>
				</Col>
			</Row>
		</>
	);
};

