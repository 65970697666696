import { secureAPI, unsecureAPI } from './API';
import { checkTokenValidate } from './../util/helper';

//create proforma invoices
async function createProformaInvoice(proforma_invoice_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post('/proforma_invoices', proforma_invoice_payload)
      .then((data) => {
        if (data.data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

//get all proforma invoices
async function getAllProformaInvoices(searchParam = '', selectElement = '') {
  //fillter
  let url = '/proforma_invoices?status=A' + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }

  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

//get a proforma invoice
async function getAnProformaInvoice(proformainvoice_id, content_type = '') {
  content_type = content_type ? `?content_type=${content_type}` : '';
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/proforma_invoices/${proformainvoice_id}${content_type}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

//delete a proforma invoice
async function deleteAnProformaInvoice(proformainvoice_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .delete(`/proforma_invoices/${proformainvoice_id}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

//update a proforma invoice
async function updateAnProformaInvoice(
  proformainvoice_id,
  proforma_invoice_payload
) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/proforma_invoices/${proformainvoice_id}`, proforma_invoice_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data?.error);
      });
  });
}

//get all the payments based on proformainvoice id
async function getAllPaymentsByProformaInvoiceId(proforma_invoiceId) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/proforma_invoices/${proforma_invoiceId}/payments`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data?.error);
      });
  });
}
//get all the credit notes applied in a particular proformainvoice id
async function getAllCreditNoteAppliedByProformaInvoiceId(proforma_invoiceId) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/proforma_invoices/${proforma_invoiceId}/creditnotesapplied`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data?.error);
      });
  });
}
//get a proforma invoice infomation when using public url (by jwt)
async function getProformaInvoiceByJWT(jwt, organization_id, language = 'ar') {
  return new Promise((resolve, reject) => {
    unsecureAPI()
      .get(`/proforma_invoices/noauth/${organization_id}?token=${jwt}&content_type=html&language=${language}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

// patch a single proforma invoice, for update only specific fields
async function patchProformaInvoice(proformainvoice_id, invoice_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/proforma_invoices/${proformainvoice_id}`, invoice_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data?.error);
      });
  });
}

// fetch invoice template with data
async function getProformaInvoiceTemplateWithData(invoice_id) {  
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/proforma_invoices/${invoice_id}/email`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
export {
  createProformaInvoice,
  getAllProformaInvoices,
  getAnProformaInvoice,
  deleteAnProformaInvoice,
  updateAnProformaInvoice,
  getAllPaymentsByProformaInvoiceId,
  getAllCreditNoteAppliedByProformaInvoiceId,
  getProformaInvoiceByJWT,
  patchProformaInvoice,
  getProformaInvoiceTemplateWithData
};
