import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import '../../translations/i18n';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { showAlert } from '../../util/alertModal';
import { userLanguage } from '../../util/helper';
import { getAllItems } from '../../services/item.service';

const ItemDropdownForSearch = ({
	labelName,
	search_model,
	language,
	queryParams,
	parentItemSearchCallback,
}) => {
	const { t } = useTranslation();
	const fieldLanguage = userLanguage(language);
	const [searchedItems, setSearchedItems] = useState([]); //store searched items here
	const [item, setItem] = useState({
		label: queryParams['item_name'],
		value: queryParams['item_name'],
	});
	const [timer, setTimer] = useState(null); //for auto complete search delay time
	//set whic itel list will fetch sales or purchase
	let itemListType = ['invoices', 'proforma_invoices', 'credit-notes'].includes(search_model)? 'sales': 'purchase';
	// todo : think later for better way
	// itemListType set to be inventory for transfer orders
	if(["transfer-orders" ,"adjustments"].includes(search_model)){
		itemListType = 'inventory';
	}

	//styling fot react-select.
	const cstyle = {
		control: (base) => ({
			...base,
			margin: 0,
		}),
	};

	useEffect(() => {
		fetchAllItems();
	}, [search_model]);

	//for customer search. onchange of customer name.send data to parent component.
	const onSelectChange = (field_name, field_value) => {		
		parentItemSearchCallback(field_name, field_value);
	};

	//get search items asyncly
	const fetchAllItems = (searchText = '') => {
		return new Promise((resolve, reject) => {
			try {
				let searchParam = '';
				if (itemListType) {
					searchParam += '&fetchtype=' + itemListType;
				}
				let selectElement = '';
				clearTimeout(timer); //clear previous timer
				const newTimer = setTimeout(async () => {
					if (searchText.length >= 2) {
						searchParam += '&limit=10';
						selectElement +=
							'&search_field=item_name.en,item_name.ar&search_value=' +
							searchText;
					}
					try {
						
						let get_all_items_res = await getAllItems(
							selectElement,
							searchParam
						);
						if (get_all_items_res) {
							if (searchText) {
								//if search text is not empty then set the search result with new state
								setSearchedItems(get_all_items_res.data);
							} else {
								//if search text is empty then set the all items with default state
								setSearchedItems(get_all_items_res.data);
							}
							let mapped_for_react_select = get_all_items_res.data?.map(
								(item) => ({
									value: item._id,
									// label:'ar' === fieldLanguage ? item.item_name?.ar : item.item_name?.en,
									label: item.item_name[fieldLanguage],
								})
							);
							resolve(mapped_for_react_select);
						}
					} catch (err) {
						reject(err);
						showAlert(t("Something wrong happened while getting item!"), true);
					}
				}, 150);
				setTimer(newTimer); //set new timer
			} catch (err) {}
		});
	};

	return (
		<>
			<Row>
				<Form.Label column sm={3}>
					{t(labelName)}
				</Form.Label>
				<Col>
					<AsyncSelect
						styles={cstyle}
						isClearable={true}
						placeholder={t('Select')+' '+t('or type')}
						required={true}
						classNamePrefix="react-select"
						className="align-middle"
						name={'item_name'}
						value={item?.label ? item : ''}
						onChange={(item) => {
							setItem(item);
							onSelectChange(item?.label, item?.value);
						}}
						defaultOptions={
							searchedItems &&
							searchedItems.map((item) => ({
								label:
									language === 'ar' ? item?.item_name?.ar : item?.item_name?.en,
								value: item?._id,
							}))
						}
						loadOptions={fetchAllItems}
						loadingMessage={() => t('Searching') + ' ...'}
						noOptionsMessage={() => t('No item found of this name')} //when no result found
					/>
					<div className="invalid-feedback"></div>
				</Col>
			</Row>
		</>
	);
};
export default ItemDropdownForSearch;
