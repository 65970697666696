import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import anvprofile from "../assets/images/aninvoiceprofile.jpg";
import {
	breadcrumbListEn,
	faqEn,
	organizationSchemaTagAR,
	organizationSchemaTagEn,
	siteNavigationElement,
	siteNavigationElementAr,
	website,
} from "../util/schemaTag";

const PageTitle = (props) => {
	const language = useSelector((state) => state.auth.language);

	//default value
	let defaultTitle = "ANINVOICE";
	let defaultKeywords = "accounting software";
	let defaultDescription = "Aninvoice";
	let siteName = "en" === language ? "Aninvoice" : "Aninvoice"; //set Arabic name

	// let canonicalUrl = window.location.href ? window.location.href : "aninvoice.com"; //use current url
	// set canonical url
	// if props has canonicalUrl then set it else use current url path name like /en/faq
	const { pathname } = useLocation();
	const canonicalUrl = props.canonicalUrl ? props.canonicalUrl : `${pathname}`;
	const finalCanonicalUrl = `https://aninvoice.com${canonicalUrl}`; //set domain name
	// console.log("finalCanonicalUrl", finalCanonicalUrl);
	return (
		<Helmet>
			<html lang={language} />
			<title>{props.title ? `${props.title} | ${siteName}` : defaultTitle}</title>
			{/* <title>{props.metaTitle ? `${props.metaTitle}` : defaultTitle}</title> */}
			<meta name="DC.title" content={props.metaTitle ? `${props.metaTitle}` : defaultTitle} />
			<meta name="description" content={props.description ? props.description : defaultDescription} />
			<meta name="keywords" content={props.keywords ? props.keywords : defaultKeywords} />
			<link rel="canonical" href={finalCanonicalUrl} />
			{/* Need to change */}
			<meta property="og:title" content={props.title ? `${props.title} | ${siteName}` : defaultTitle} />
			<meta property="og:locale" content="ar_SA" />
			<meta property="og:type" content="website" />
			<meta property="og:url" content="https://aninvoice.com/" />
			<meta property="og:site_name" content="aninvoice" />
			<meta property="og:description" content={props.description ? props.description : defaultDescription} />
			<meta property="og:image" content={"https://cdn.aninvoice.com/public/static/og-aninvoice.jpg"} />
			{/* <meta property="og:locale" content="ar_SA" />
			<meta property="og:locale:alternate" content="en_SA" /> */}
			<meta name="twitter:title" content={props.title ? `${props.title} | ${siteName}` : defaultTitle} />
			<meta name="twitter:description" content={props.description ? props.description : defaultDescription} />
			<meta name="twitter:image" content={anvprofile} />
			<meta name="twitter:site" content="@aninvoice" />
			<meta name="twitter:creator" content="@aninvoice" />
			{/* Schema Tag */}
			<script type="application/ld+json">{JSON.stringify("en" === language ? organizationSchemaTagEn : organizationSchemaTagAR)}</script>
			<script type="application/ld+json">{JSON.stringify("en" === language ? breadcrumbListEn : breadcrumbListEn)}</script>
			<script type="application/ld+json">{JSON.stringify("en" === language ? siteNavigationElement : siteNavigationElementAr)}</script>
			<script type="application/ld+json">{JSON.stringify("en" === language ? website : website)}</script>
			<script type="application/ld+json">{JSON.stringify("en" === language ? faqEn : faqEn)}</script>
			<script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`}></script>
		</Helmet>
	);
};

export default PageTitle;
