import { secureAPI, unsecureAPI } from './API';
import { checkTokenValidate } from './../util/helper';

async function updateOwnUsersInfomation(users_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/users`, users_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function updateUser(user_id, users_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/users/${user_id}`, users_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function createUser(users_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post('/users', users_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function getAllUser(selectElement, include_all) {
	let url = '/users?status=A';
	if (selectElement) {
		url = `${url}&select=${selectElement}`;
	}
	if (include_all) {
		url = `${url}&all=true`;
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function getAnUser(user_id = null) {
	const url = user_id ? `/users/${user_id}` : "/users/get_verify_user";

	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`${url}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function deleteAnUser(user_id, id_under_organizations) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/users/${user_id}/${id_under_organizations}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function UserOrganizationStatusChanged(user_id, id_under_organizations, status) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/users/${user_id}/${id_under_organizations}/${status}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function patchAnUser(user_id, patched_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/users/${user_id}`, patched_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function updateOrganizationInviteStatus(token, status) {
	return new Promise((resolve, reject) => {
		unsecureAPI()
			.patch(`/users/noauth/${token}?status=${status}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function getInviteStatus(token) {
	return new Promise((resolve, reject) => {
		unsecureAPI()
			.get(`/users/noauth/${token}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				//checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//send a validation email to user
async function sendVerificationEmail() {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`/users/email_verification`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//send jwt token to server to verify user's email
async function verifyEmail(token) {
	return new Promise((resolve, reject) => {
		unsecureAPI()
			.patch(`/users/email_verification/${token}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				reject(err.response.data.error);
			});
	});
}

//validate if user given email is available to use
//call this serivce when user change email (on blur)
async function validateUserEmail(email) {
	let payload = {
		email,
	};

	return new Promise((resolve, reject) => {
		secureAPI()
			.post("/users/validateuseremail", payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				console.error(err);
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//get invitations from organizations send to this user
async function getInvitations() {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/users/invitations`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
//sent resent invitations from organizations  to a user
async function sentInvitation(data) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`/users/invitations`, data)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//either accept or reject a single invitation sent by organizations
//action_type either should be "accpet" or "reject"
//get_token if true also retrive an access token
async function patchInvitation(action_type, invitation_id, get_token = false) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/users/invitations/${invitation_id}/${action_type}?get_token=${get_token}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return a string
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function verifyOTP(type, payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`/users/${type}/verify_otp`, payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				reject(err.response.data);
			});
	});
}

async function generateOTP(type, payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`/users/${type}/generate_otp`, payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				reject(err.response.data);
			});
	});
}


/**
 * @description : verifyUserEmail function used to
 * check user organization wise enable / disable option
 * @param {String} email
 * @returns {Promise}
*/
async function verifyUserEmail(email) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/users/find_user_email/${email}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				reject(err.response.data);
			});
	});
}

/**
 * @description : toggleUserStatusEmailPhone function used to
 * change user status A to D vice versa
 * @param {String} user_id
 * @param {String} update_user_status
 * @returns {Promise}
 */

async function toggleUserStatusEmailPhone(user_id, update_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/users/find_user_email/${user_id}`, update_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				reject(err.response.data);
			});
	});
}


/**
 * @description: addFavoriteReportToUserOrganization
 * set report module name true in user's organization
 * @param {String} user_id
 * @param {String} organization_id
 * @param {Object} report_module
 * @returns {Promise}
 */
async function addRemoveFavoriteReportToUserOrganization(report_module, add_or_remove_type) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/users/user_favorite_report/${report_module}/${add_or_remove_type}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				reject(err.response.data);
			});
	});

}

/**
 * @description : updateMaximumAllowedOrganization function used to
 * update user allow_organization_limit
 * @param {String} user_id
 * @param {Number} allowed_number
 * @returns {Promise}
*/
async function updateMaximumAllowedOrganization({ user_id, allowed_number }) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/users/maximum_allowed_organization/${user_id}/?allowed_number=${allowed_number}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				reject(err.response.data);
			});
	});
}

async function getAllSignupRequest(selectElement = null, searchParam = "") {
	let url = "/users/signup_request";
	// Check if status is already in searchParam
	if (!searchParam.includes("status=")) {
		url += "?status=A";
	} else {
		url += "?";
	}

	// Append other search parameters if present
	url += searchParam;

	if (selectElement) {
		// Add select parameter with a separator
		url += (url.includes("=") ? "&" : "") + `select=${selectElement}`;
	}


	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function sentSignUpLink(payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`/users/signup_link_sent`, payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				reject(err.response.data);
			});
	});
}

export {
	updateOwnUsersInfomation,
	updateUser,
	createUser,
	getAnUser,
	deleteAnUser,
	getAllUser,
	patchAnUser,
	updateOrganizationInviteStatus,
	getInviteStatus,
	sendVerificationEmail,
	verifyEmail,
	validateUserEmail,
	getInvitations,
	patchInvitation,
	sentInvitation,
	verifyOTP,
	generateOTP,
	verifyUserEmail,
	toggleUserStatusEmailPhone,
	addRemoveFavoriteReportToUserOrganization,// add  remove favorite report
	updateMaximumAllowedOrganization, // update allowed organization
	getAllSignupRequest,
	sentSignUpLink,
	UserOrganizationStatusChanged

};
