const organizationSchemaTagEn = {
	"@context": "https://schema.org",
	"@type": "Organization",
	name: "Best Online Accounting Software - Aninvoice ",
	"@id": "https://www.aninvoice.com/#Organization",
	url: "https://www.aninvoice.com/ ",
	logo: "https://www.aninvoice.com/static/media/logo.d709653d7b3b27435cb818484e6413e9.svg",
	description:
		"Discover Aninvoice, the leading accounting and invoicing software trusted by businesses worldwide. Streamline your financial processes with our cloud-based accounting software and online invoicing system. Try the best online accounting software today! ",
	address: {
		"@type": "PostalAddress",
		streetAddress: "First floor - office no 9 Crystal center",
		addressLocality: "King Abdullah Road, Exit#10",
		addressRegion: "Riyadh",
		addressCountry: "Saudi Arabia",
	},
	contactPoint: {
		"@type": "ContactPoint",
		telephone: "+966 920 035 129 ",
		email: "info@aninvoice.com",
		areaServed: ["Saudi Arabia"],
		availableLanguage: "en",
	},
	sameAs: [
		"https://www.facebook.com/aninvoice.tm",
		"https://www.instagram.com/aninvoice/",
		"https://www.youtube.com/channel/UCaWgKq_-uvvO0Fjasj1N_Vw",
		"https://twitter.com/aninvoice",
	],
};

const organizationSchemaTagAR = {
	"@context": "https://schema.org",
	"@type": "Organization",
	name: "Best Online Accounting Software - Aninvoice ",
	"@id": "https://www.aninvoice.com/#Organization",
	url: "https://www.aninvoice.com/ ",
	logo: "https://www.aninvoice.com/static/media/logo.d709653d7b3b27435cb818484e6413e9.svg",
	description:
		"Discover Aninvoice, the leading accounting and invoicing software trusted by businesses worldwide. Streamline your financial processes with our cloud-based accounting software and online invoicing system. Try the best online accounting software today! ",
	address: {
		"@type": "PostalAddress",
		streetAddress: "First floor - office no 9 Crystal center",
		addressLocality: "King Abdullah Road, Exit#10",
		addressRegion: "Riyadh",
		addressCountry: "Saudi Arabia",
	},
	contactPoint: {
		"@type": "ContactPoint",
		telephone: "+966 920 035 129 ",
		email: "info@aninvoice.com",
		areaServed: ["Saudi Arabia"],
		availableLanguage: "en",
	},
	sameAs: [
		"https://www.facebook.com/aninvoice.tm",
		"https://www.instagram.com/aninvoice/",
		"https://www.youtube.com/channel/UCaWgKq_-uvvO0Fjasj1N_Vw",
		"https://twitter.com/aninvoice",
	],
};

const breadcrumbListEn = {
	"@context": "https://schema.org",
	"@type": "BreadcrumbList",
	itemListElement: [
		{
			"@type": "ListItem",
			position: 1,
			item: {
				"@id": "https://www.aninvoice.com/ ",
				name: "Home",
			},
		},
	],
};

const siteNavigationElement = [
	{
		"@context": "http://schema.org/",
		"@type": "SiteNavigationElement",
		name: "Home",
		url: "https://www.aninvoice.com/",
	},
	{
		"@context": "http://schema.org/",
		"@type": "SiteNavigationElement",
		name: "Sign Up",
		url: "https://www.aninvoice.com/en/register",
	},
	{
		"@context": "http://schema.org/",
		"@type": "SiteNavigationElement",
		name: "Sign In",
		url: "https://www.aninvoice.com/en/login",
	},
];

const siteNavigationElementAr = [
	{
		"@context": "http://schema.org/",
		"@type": "SiteNavigationElement",
		name: "الواجهة الرئيسية",
		url: "https://www.aninvoice.com/",
	},
	{
		"@context": "http://schema.org/",
		"@type": "SiteNavigationElement",
		name: "تسجيل",
		url: "https://www.aninvoice.com/ar/register",
	},
	{
		"@context": "http://schema.org/",
		"@type": "SiteNavigationElement",
		name: "تسجيل الدخول",
		url: "https://www.aninvoice.com/ar/login",
	},
];

const website = {
	// "@context": "https://schema.org/",
	// "@type": "WebSite",
	// name: "Aninvoice",
	// url: "https://www.aninvoice.com/",
	// potentialAction: {
	// 	"@type": "SearchAction",
	// 	target: { "@type": "EntryPoint", urlTemplate: "https://aninvoice.com/en/help?search_value={search_term_string}" },
	// 	"query-input": "required name=search_term_string",
	// },
	"@context": "https://schema.org/",
	"@type": "Website",
	name: "Aninvoice",
	url: "https://www.aninvoice.com/",
	potentialAction: {
		"@type": "SearchAction",
		target: "https://www.aninvoice.com/?search={search_term_string}",
		"query-input": "required name=search_term_string",
	},
};

const faqEn = {
	"@context": "https://schema.org",
	"@type": "FAQPage",
	mainEntity: [
		{
			"@type": "Question",
			name: "What is Aninvoice system?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "It is a cloud accounting software approved by ZATCA that allows small and intermediate firms to register their daily sales and purchases in accordance with the system and requirements of electronic billing.",
			},
		},
		{
			"@type": "Question",
			name: "Is the system qualified for the second stage, which is the linking stage with ZATCA?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "Yes.",
			},
		},
		{
			"@type": "Question",
			name: "What are the languages used in the system?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "Arabic and English languages.",
			},
		},
		{
			"@type": "Question",
			name: "Is the system free?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "Yes, in the current time.",
			},
		},
		{
			"@type": "Question",
			name: "How much storage space for the system?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "It is unlimited.",
			},
		},
		{
			"@type": "Question",
			name: "How can I communicate with the customer service?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "By creating a ticket or by calling on the following number 920035129.",
			},
		},
		{
			"@type": "Question",
			name: "What is the difference between proforma invoices and invoices?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "Proforma invoices are used to provide a price offer and have no accounting effect. Once the customer approves them, then you can convert them into an invoice.",
			},
		},
		{
			"@type": "Question",
			name: "How to delete invoices?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "Invoices can ‘not be deleted but can be canceled by creating a credit note.",
			},
		},
		{
			"@type": "Question",
			name: "How can I access my account if I forgot my password?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "From the login page click on forgot password. Enter the email address that you used during the registration process in order to send a link to it. Click on the link in the sent message. Set a new password.",
			},
		},
		{
			"@type": "Question",
			name: "Is it possible to transfer from an accounting system to Aninvoice system?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "Yes. By adding the opening balances from the settings option in the system.",
			},
		},
		{
			"@type": "Question",
			name: "Can I have one account for multiple organizations in Aninvoice?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "Yes, and to create another account, you can click on the username option at the top of the page, then select (My Organizations), then add a new organization.",
			},
		},
		{
			"@type": "Question",
			name: "Is Aninvoice system support branches?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "Yes, you can create several branches of the organization and assign transactions to each branch separately.",
			},
		},
		{
			"@type": "Question",
			name: "Is it possible to specify permissions of the users on the system?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "Yes, you can add many users and select permissions for them according to their respective roles as you can create new roles according to your needs.",
			},
		},
		{
			"@type": "Question",
			name: "Is Aninvoice system support currency?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "Yes",
			},
		},
	],
};

export { organizationSchemaTagEn, organizationSchemaTagAR, breadcrumbListEn, siteNavigationElement, siteNavigationElementAr, website, faqEn };
