const basicPath = "/reports";

const SALES_REPORTS_OBJECT = {
  // sales
  SALES_BY_CUSTOMER: {
    _id: "sales_by_customer",
    title: "Sales by customer",
    path: `${basicPath}/sales_by_customer`,
    range : 'month',
  },
  SALES_BY_ITEM: {
    _id: "sales_by_item",
    title: "Sales by item",
    path: `${basicPath}/sales_by_item`,
    range : 'month',

  },
  SALES_BY_SALES_PERSON: {
    _id: "sales_by_sales_person",
    title: "Sales by sales person",
    path: `${basicPath}/sales_by_sales_person`,
    range : 'month',
  },
};
const PURCHASE_REPORTS_OBJECT = {
  // purchase and expenses
  PURCHASE_BY_VENDOR: {
    _id: "purchases_by_vendor",
    title: "Purchases by vendor",
    path: `${basicPath}/purchases_by_vendor`,
    range : 'month',
  },
  PURCHASE_BY_ITEM: {
    _id: "purchases_by_item",
    title: "Purchases by item",
    path: `${basicPath}/purchases_by_item`,
    range : 'month',
  },
  PURCHASE_ORDER: {
    _id: "purchase_order_details",
    title: "Purchases orders",
    path: `${basicPath}/purchase_order_details`,
    range : 'month',
  },
};
const RECEIVABLES_REPORTS_OBJECT = {
  // Receivables
  CUSTOMER_BALANCE: {
    _id: "customer_balances",
    title: "Customer balances",
    path: `${basicPath}/customer_balances`,
    range : 'month',
  },
  INVOICE_DETAILS: {
    _id: "invoice_details",
    title: "Invoice details",
    path: `${basicPath}/invoice_details`,
    range : 'month',
  },
  CUSTOMER_STATEMENT: {
    _id: "customer_statement",
    title: "Customer statement",
    path: `${basicPath}/customer_statement`,
    range : 'month',
  },
  SALES_ORDER_DETAILS: {
    _id: "sales_order_details",
    title: "Sales order details",
    path: `${basicPath}/sales_order_details`,
    range : 'month',
  },
  AR_AGING_SUMMARY: {
    _id: "aging_summary",
    title: "AR aging summary",
    path: `${basicPath}/aging_summary`,
    range : 'day',

  },
};
const PAYABLE_REPORTS_OBJECT = {
  // Payable
  VENDOR_BALANCE: {
    _id: "vendor_balances",
    title: "Vendor balances",
    path: `${basicPath}/vendor_balances`,
    range : 'month',

  },
  BILL_DETAILS: {
    _id: "bills_details",
    title: "Bill details",
    path: `${basicPath}/bill_details`,
    range : 'month',

  },
  VENDOR_STATEMENT: {
    _id: "vendor_statement",
    title: "Vendor statement",
    path: `${basicPath}/vendor_statement`,
    range : 'month',

  },
  AP_AGING_SUMMARY: {
    _id: "ap_aging_summary",
    title: "AP aging summary",
    path: `${basicPath}/ap_aging_summary`,
    range : 'day',

  },
};
const TAX_REPORTS_OBJECT = {
  // Tax
  TAX_SUMMARY: {
    _id: "tax_report",
    title: "Tax report by tax rate",
    path: `${basicPath}/tax_summary`,
    range : 'month',
  },
  TAX_DETAILS: {
    _id: "tax_return",
    title: "Tax returns",
    path: `${basicPath}/tax_return`,
  },
};
const ACCOUNTING_REPORTS_OBJECT = {
  // Accounting
  ACCOUNT_TRANSACTION: {
    _id: "account_transactions",
    title: "Account transactions",
    path: `${basicPath}/account_transactions`,
    range : 'month',
    
  },
  TRIAL_BALANCE: {
    _id: "trial_balance",
    title: "Trial balance",
    path: `${basicPath}/trial_balance`,
    range : 'day',
  },
};
const BUSINESS_REPORTS_OBJECT = {
  // Business
  PROFIT_AND_LOSS: {
    _id: "profit_and_loss",
    title: "Profit and Loss",
    path: `${basicPath}/profit_and_loss`,
    range : 'year',
  },
  BALANCE_SHEET: {
    _id: "balance_sheet",
    title: "Balance sheet",
    path: `${basicPath}/balance_sheet`,
    range : 'year',
  },
};
const INVENTORY_REPORTS_OBJECT = {
  INVENTORY_SUMMARY: {
    _id: "inventory_summary",
    title: "Inventory summary",
    path: `${basicPath}/inventory_summary`,
    range : 'year',
  },
  INVENTORY_VALUATION: {
    _id: "inventory_valuation",
    title: "Inventory valuation summary",
    path: `${basicPath}/inventory_valuation`,
    range : 'year',
  },
  COMMITTED_STOCK_DETAILS: {
    _id: "committed_stock_details",
    title: "Committed stock details",
    path: `${basicPath}/committed_stock_details`,
    range : 'year',
  },
  STOCK_SUMMARY: {
    _id: "stock_summary",
    title: "Stock summary",
    path: `${basicPath}/stock_summary`,
    range : 'year',
  },
};



// All reports
const All_REPORTS_OBJECT = {
  ...SALES_REPORTS_OBJECT,
  ...PURCHASE_REPORTS_OBJECT,
  ...RECEIVABLES_REPORTS_OBJECT,
  ...PAYABLE_REPORTS_OBJECT,
  ...TAX_REPORTS_OBJECT,
  ...ACCOUNTING_REPORTS_OBJECT,
  ...BUSINESS_REPORTS_OBJECT,
  ...INVENTORY_REPORTS_OBJECT,
};

const NON_INVENTORY_ORG_REPORTS_OBJECT = {
  ...SALES_REPORTS_OBJECT,
  ...PURCHASE_REPORTS_OBJECT,
  ...RECEIVABLES_REPORTS_OBJECT,
  ...PAYABLE_REPORTS_OBJECT,
  ...TAX_REPORTS_OBJECT,
  ...ACCOUNTING_REPORTS_OBJECT,
  ...BUSINESS_REPORTS_OBJECT,
};
const INVENTORY_ORG_REPORTS_OBJECT = {
  ...NON_INVENTORY_ORG_REPORTS_OBJECT,
  ...INVENTORY_REPORTS_OBJECT,
};







// array and export
const NON_INVENTORY_ORG_REPORTS = Object.keys(NON_INVENTORY_ORG_REPORTS_OBJECT).map((key) => NON_INVENTORY_ORG_REPORTS_OBJECT[key]);
const INVENTORY_ORG_REPORTS = Object.keys(INVENTORY_ORG_REPORTS_OBJECT).map((key) => INVENTORY_ORG_REPORTS_OBJECT[key]);

export { All_REPORTS_OBJECT, NON_INVENTORY_ORG_REPORTS, INVENTORY_ORG_REPORTS };
