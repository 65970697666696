import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	SET_LANGUAGE,
	UPDATE_USERINFO,
	UPDATE_ORGANIZATION,
	UPDATE_REPORT_SEARCH,
} from '../actions/types';

const token = localStorage.getItem('token'); //jwt token
const userInfo = JSON.parse(localStorage.getItem('user_info')); //user information
// detect langauge
let language = window.location.pathname.split('/')[1]; //en || ar
if ('en' === language || 'ar' === language) {
	localStorage.setItem('language', language);
} else {
	let localLang = localStorage.getItem('language');
	language = localLang === 'en' ? localLang : 'ar';
	localStorage.setItem('language', language);
}

//init value check login fom token
const initialState = token ? { isLoggedIn: true, userInfo, language } : { isLoggedIn: false, userInfo: null, language };

const auth = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case REGISTER_SUCCESS:
			return {
				...state,
				isLoggedIn: true,
				userInfo: payload.userInfo,
			};
		case REGISTER_FAIL:
			return {
				...state,
				isLoggedIn: false,
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				isLoggedIn: true,
				userInfo: payload.userInfo,
			};
		case LOGIN_FAIL:
			return {
				...state,
				isLoggedIn: false,
				userInfo: null,
			};
		case LOGOUT:
			localStorage.removeItem('token');
			localStorage.removeItem('user_info');
			localStorage.removeItem('default_organization');
			localStorage.removeItem('microsoftLogin');
			return {
				...state,
				isLoggedIn: false,
				userInfo: null,
			};
		case SET_LANGUAGE:
			return {
				...state,
				language: payload.language,
			};
		case UPDATE_USERINFO:
			return {
				...state,
				userInfo: {
					...state.userInfo,
					prefix: payload.userInfo.prefix,
					first_name: payload.userInfo.first_name,
					last_name: payload.userInfo.last_name,
					phone_no: payload.userInfo.phone_no,
				},
			};
		//we update all the organization details
		case UPDATE_ORGANIZATION:
			return {
				...state,
				//isLoggedIn: true,
				userInfo: payload.userInfo,
			};
		case UPDATE_REPORT_SEARCH:
			return {
				...state,
				userInfo: {
					...state.userInfo,
					reports_url: payload.userInfo.reports_url,
				},
			};
		default:
			return state;
	}
};

export default auth;
