import React, { useRef, useEffect } from "react";

const Loading = (props) => {
	const myComponentRef = useRef(null);
	// common styles for loading
	let common_styles = {
		textAlign: "center",
		paddingTop: "10px",
	};
	// style pass from parent component then merge with common_styles
	if (props.style) {
		common_styles = { ...common_styles, ...props.style };
	}

	// useEffect(() => {
	// 	if (myComponentRef.current) {
	// 		document.body.classList.add("bdyloading");
	// 	}
	// 	return () => {
	// 		document.body.classList.remove("bdyloading");
	// 	};
	// }, []);
	return (
		<div ref={myComponentRef}>
			{!props?.editPage ? (
				<div className={`fullloading ${props?.loadClass}`} style={common_styles}>
					{/* <img src={load} alt="Loading" /> */}
					<div className="load-circle1"></div>
					<div className="load-circle2"></div>
					<div className="load-circle3"></div>
					<div className="load-circle4"></div>
					<div className="load-circle5"></div>
				</div>
			) : (
				<div className={`fetcheditdata ${props?.loadClass}`}>
					<span>
						<div className="load-circle1"></div>
						<div className="load-circle2"></div>
						<div className="load-circle3"></div>
						<div className="load-circle4"></div>
						<div className="load-circle5"></div>
						{/* <samp>
							<Icon.ArrowRepeat />
						</samp>
						{t('Please wait')} */}
					</span>
				</div>
			)}
		</div>
	);
};
export default Loading;
