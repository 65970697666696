import { secureAPI, unsecureAPI } from './API';
import { checkTokenValidate } from '../util/helper';

//create sales order
async function createSalesOrder(sales_order_payload) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .post('/sales_order', sales_order_payload)
            .then((data) => {
                if (data.data.success) {
                    resolve(data.data); //return array
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err?.response?.data);
            });
    });
}

//get all sales order
async function getAllsalesOrders(searchParam = '', selectElement = '') {
    //fillter
    // console.log("searchParam", searchParam);
    //console.log("selectElement", selectElement);
    let url = '/sales_order?status=A' + searchParam;
    if (selectElement) {
        url = `${url}&select=${selectElement}`;
    }

    return new Promise((resolve, reject) => {
        secureAPI()
            .get(url)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data); //return array
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err.response.data.error);
            });
    });
}

//get a sales order
async function getAsalesOrder(sales_order_id, content_type = '', for_clone = false, create_purchase_order=false) {
    // content_type = content_type ? `?content_type=${content_type}` : '';
    const query_params_array = [];
    if (content_type) {
        query_params_array.push(`content_type=${content_type}`)
    }
    if (for_clone) {
        query_params_array.push(`for_clone=${for_clone}`)
    }
    if (create_purchase_order) {
        query_params_array.push(`create_purchase_order=${create_purchase_order}`)
    }
    let query_string = "";
    if (query_params_array.length > 0) {
        query_string = "?" + query_params_array.join("&");
    }
    return new Promise((resolve, reject) => {
        secureAPI()
            .get(`/sales_order/${sales_order_id}${query_string}`)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data); //return array
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err.response.data.error);
            });
    });
}

//delete a sales order
async function deleteAsalesOrder(sales_order_id) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .delete(`/sales_order/${sales_order_id}`)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data); //return single object
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err.response.data.error);
            });
    });
}

//update a sales order
async function updateAsalesOrder(
    sales_order_id,
    sales_order_payload
) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .put(`/sales_order/${sales_order_id}`, sales_order_payload)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data); //return single object
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err?.response?.data?.error);
            });
    });
}

// patch a single sales order, for update only specific fields
async function patchSalesOrder(sales_order_id, sales_order_payload) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .patch(`/sales_order/${sales_order_id}`, sales_order_payload)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data); //return single object
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err?.response?.data?.error);
            });
    });
}

//get a sales order infomation when using public url (by jwt)
async function getSalesOrderByJWT(jwt, organization_id, language = 'ar') {
    return new Promise((resolve, reject) => {
        unsecureAPI()
            .get(`/sales_order/noauth/${organization_id}?token=${jwt}&content_type=html&language=${language}`)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data); //return single object
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err.response.data.error);
            });
    });
}


// get invoices of this sales order
async function getInvoicesFromSalesOrder(sales_order_id) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .get(`/sales_order/${sales_order_id}/invoices`)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data);
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err.response.data.error);
            });
    });
}


// get items of sales orders that we can cancel
// all items of the sales order will be available there.
async function getItemsToCancelFromSalesOrder(sales_order_id) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .get(`/sales_order/${sales_order_id}/for_cancel_items`)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data);
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err.response.data.error);
            });
    });
}



// cancel items of a sales order
async function cancelItemsOfSalesOrder(sales_order_id, cancel_items_payload) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .put(`/sales_order/${sales_order_id}/cancel_items`, cancel_items_payload)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data);
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err?.response?.data);
            });
    });
}

// cancel items of a sales order
async function reOpenCanceledItemsOfSalesOrder(sales_order_id) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .put(`/sales_order/${sales_order_id}/reopen_cancel_items`)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data);
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err?.response?.data);
            });
    });
}


// fetch sales order template with data
async function 	getSaleOrderTemplateWithData(_id) {  
	return new Promise((resolve, reject) => {
	  secureAPI()
		.get(`/sales_order/${_id}/email`)
		.then(({ data }) => {
		  if (data.success) {
			resolve(data.data); //return array
		  }
		})
		.catch((err) => {
		  checkTokenValidate(err);
		  reject(err.response.data.error);
		});
	});
  }

export {
    createSalesOrder,
    getAllsalesOrders,
    getAsalesOrder,
    deleteAsalesOrder,
    updateAsalesOrder,
    patchSalesOrder,
    getSalesOrderByJWT,
    getInvoicesFromSalesOrder,
    getItemsToCancelFromSalesOrder,
    cancelItemsOfSalesOrder,
    reOpenCanceledItemsOfSalesOrder,
    getSaleOrderTemplateWithData
};
