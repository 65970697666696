import { secureAPI } from './API';
import { checkTokenValidate } from './../util/helper';

/** 
 * @description createRecurringInvoice function used
 * to create a new recurring invoice
 * call from browser
 * 
*/
async function createRecurringInvoice(recurring_invoice_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post('/recurring_invoices', recurring_invoice_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

/**
 * @description getRecurringInvoices function used to
 * fetch all recurring invoice 
 * call from browser
 * 
 */
async function getAllRecurringInvoices(searchParam = '', selectElement = '') {
  let url = '/recurring_invoices?status=A' + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }

  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/**
 * @description getARecurringInvoices function used to 
 * fetch a single recurring invoice
 * call from browser
 */
async function getARecurringInvoices(id,content_type='') {  
     
  content_type = content_type ? `?content_type=${content_type}` : '';
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/recurring_invoices/${id}${content_type}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/**
 * @description patchRecurringInvoice function used to 
 * fetch a single recurring invoice
 * call from browser
 */
async function patchRecurringInvoice(id, invoice_payload) {       
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/recurring_invoices/${id}`, invoice_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data?.error);
      });
  });
}

/**
 * @description updateARecurringInvoice function used to 
 * update a single recurring invoice
 * call from browser
 */
async function updateARecurringInvoice(id, invoice_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/recurring_invoices/${id}`, invoice_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

/**
 * @description updateARecurringInvoice function used to 
 * update a single recurring invoice
 * call from browser
 */
async function deleteARecurringInvoice(id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .delete(`/recurring_invoices/${id}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

/**
 * @description updateARecurringInvoice function used to 
 * update a single recurring invoice
 * call from browser
 */
async function toggleTransactionStatusOfARecurringInvoice(id,status='') {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/recurring_invoices/${id}/transaction_status?status=${status}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

/**
 * @description changeRecurringInvoiceTemplate function used to
 * update pdf template
 * call from browser
 */
async function changeRecurringInvoiceTemplate({id, template_id}) {
  try {
    const response = await secureAPI().put(`/recurring_invoices/${id}/template/${template_id}`);
    if (response.data.success) {
      return response.data.data;
    }
  } catch (err) {
    checkTokenValidate(err);
    throw new Error(err.response.data.error);
  }
}
/**
 * @description getAllChildInvoices function used to 
 * fetch all child invoices of recurring invoice according to payment_status
 * by default payment_status :=> all
 * call from browser
 * 
 */

async function getAllChildInvoices({id,searchParam = '', selectElement = '',payment_status=''}) { 
  
  let url = `/recurring_invoices/${id}/invoices?status=A&payment_status=${payment_status}` + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }
  try {
    const response = await secureAPI().get(url);
    if (response.data.success) {
      return response.data;
    }
  } catch (err) {
    checkTokenValidate(err);
    throw new Error(err.response.data.error);
  }
}

export {
  createRecurringInvoice,
  getAllRecurringInvoices,
  getARecurringInvoices,
  patchRecurringInvoice,
  updateARecurringInvoice,
  deleteARecurringInvoice,
  toggleTransactionStatusOfARecurringInvoice,
  changeRecurringInvoiceTemplate,
  getAllChildInvoices,
}