import { unsecureAPI, secureAPI } from '../services/API';
import { checkTokenValidate } from './../util/helper';
import moment from 'moment';
/*
register / sign  up for new user
return promise
payload : 
{
 name: "111",
 email: "pa11ul@15audit.com",
 password: "123456",
 role: "user",
}
resolve : 
{token,user_full_name: 'Asif ali', oranization_name: 'Parmagiat'}
*/
async function registration(authPayload) {
	return new Promise((resolve, reject) => {
		//unsecure means it has no Bearer token in header
		unsecureAPI()
			.post('/auth/register', authPayload)
			.then(({ data }) => {
				//check if token exist
				if (data.token) {
					saveInLocalStorage('token', data.token);
					saveInLocalStorage('user_info', JSON.stringify(data.user_info));
				}
				resolve(data);
			})
			.catch((err) => {
				reject(err.response.data.error); //get error message
			});
	});
}

/* login user
return promise
payload : 
{
 email: "pa11ul@15audit.com",
 password: "123456",
}
resolve : 
{token,user_full_name: 'Asif ali', oranization_name: 'Parmagiat'} */
async function login(authPayload) {
	return new Promise((resolve, reject) => {
		unsecureAPI()
			.post('/auth/login', authPayload)
			.then(({ data }) => {
				if (data.token) {
					saveInLocalStorage('token', data.token);
					localStorage.setItem('user_info', JSON.stringify(data.user_info));
				}
				resolve(data);
			})
			.catch((err) => {
				reject(err.response.data.error);
			});
	});
}
/*Get inforamtion about currently logged in user */
async function getUserInformation(selectElement = null) {
	
	let url = '/auth/me';
	if (selectElement) {
		url = `${url}?select=${selectElement}&today=${moment().format('YYYY-MM-DD')}`;
	}

	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.data) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

/*Reset a user password
send the user token (a reset token)
get token from params (see)
*/
function resestPasswordOfUser(user_token, passPayload) {
	return new Promise((resolve, reject) => {
		unsecureAPI()
			.put(`/auth/resetpassword/${user_token}`, passPayload)
			.then((data) => {
				if (data?.data) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				reject(err.response.data.error);
			});
	});
}
/*Reset a user password
send the user token (a reset token)
get token from params (see)
*/
function updatePassword(passPayload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/auth/updatepassword/`, passPayload)
			.then((data) => {
				if (data) {
					resolve(data);
					//do i need to store token and user_info
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
}

/*
Send a email to the user
with a reset token
when user click that mail link open a new password form
 */
function sendResestPasswordEmail(emailPayload) {
	return new Promise((resolve, reject) => {
		unsecureAPI()
			.post('/auth/forgotpassword', emailPayload)
			.then((data) => {
				resolve(data);
			})
			.catch((err) => {
				reject(err.response.data.error);
			});
	});
}

//save item to localstorage and return its value
//function to modify the value like CryptoJs
function saveInLocalStorage(key, value, fn) {
	let interMidValue = value;
	if (fn) {
		interMidValue = fn(interMidValue);
	}
	if (interMidValue) localStorage.setItem(key, interMidValue);
	return localStorage.getItem(key);
}

//this function used for change organization
async function changeOrganization(organizationId) {
	let url = '/auth/change_organization';
	if (organizationId) {
		url = `${url}/${organizationId}`;
	}

	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.token) {
					saveInLocalStorage('token', data.token);
					localStorage.setItem('user_info', JSON.stringify(data.user_info));
				}
				resolve(data);
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
//this function used for change organization
async function makeDefaultOrganization(organizationId) {
	let url = '/auth/make_default_organization';
	if (organizationId) {
		url = `${url}/${organizationId}`;
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(url)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
//Google login
async function AIgoogleLogin(gLoginPayload) {
	return new Promise((resolve, reject) => {
		unsecureAPI()
		.post('/auth/googleLogin', gLoginPayload)
		.then(({ data }) => {
			if (data.token) {
				saveInLocalStorage('token', data.token);
				localStorage.setItem('user_info', JSON.stringify(data.user_info));
			}
			resolve(data);
		})
		.catch((err) => {
			reject(err.response.data.error);
		});
	});
}
async function AImicrosoftLogin(microsoftPayload) {
	return new Promise((resolve, reject) => {
		unsecureAPI()
		.post('/auth/microsoftLogin', microsoftPayload)
		.then(({ data }) => {
			if (data.token) {
				saveInLocalStorage('token', data.token);
				localStorage.setItem('user_info', JSON.stringify(data.user_info));
			}
			resolve(data);
		})
		.catch((err) => {
			reject(err.response.data.error);
		});
	});
}
export {
  registration,
  login,
  getUserInformation,
  resestPasswordOfUser,
  sendResestPasswordEmail,
  saveInLocalStorage,
  updatePassword,
  changeOrganization,
  makeDefaultOrganization,
  AIgoogleLogin,
  AImicrosoftLogin
};
