import { secureUploadAPI } from '../services/API';
const uploads = (fileObj)=>{
    return new Promise((resolve, reject) => {
        secureUploadAPI()
          .post(`/uploads/file`, fileObj)
          .then(({ data }) => {
            if (data.success) {
              resolve(data.data); //return single object
            }
          })
          .catch((err) => {
            //checkTokenValidate(err);
            reject(err.response.data.error);
          });
      });
}

export { uploads}