import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';

import { Loading } from './common';
import * as alertify from 'alertifyjs';

import AddOrganization from './components/organization/AddOrganization';
import UnderMaintenance from './pages/UnderMaintenance';
// import ReactGA from 'react-ga';
import { logoutAllTabs, loginAllTabs } from './util/multiTabLogout';

import axios from "axios"; // WARNING:: Do not remove this code
import SignUpContactPage from './pages/SignUpContactPage';
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const AcceptInvite = lazy(() => import("./pages/AcceptInvite"));
const EmailVerification = lazy(() => import("./pages/EmailVerification"));

const Login = lazy(() => import("./pages/Login"));
const Logout = lazy(() => import("./pages/Logout"));
const Registration = lazy(() => import("./pages/Registration"));
const SetPassword = lazy(() => import("./pages/SetPassword"));
const Page404 = lazy(() => import("./pages/Page404"));
const Page404Home = lazy(() => import("./pages/Page404Home"));
// const Home = lazy(() => import('./pages/Home'));
const Home2 = lazy(() => import("./pages/Home2"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Faq = lazy(() => import("./pages/Faq"));
const GeneralQuestion = lazy(() => import("./pages/GeneralQuestion"));

const SecureLayout = lazy(() => import("./components/SecureLayout"));
const PrivateRoute = lazy(() => import("./common/PrivateRoute"));
const Invoice = lazy(() => import("./pages/Invoice"));
const SharedLinkRedirect = lazy(() => import("./pages/SharedLinkRedirect"));
const Help = lazy(() => import("./pages/Help"));
const TicketSearchResult = lazy(() => import("./pages/TicketSearchResult"));
const CkeditorImage = lazy(() => import("./components/help/CkeditorImage"));
//for client portal routing import
const PortalLogin = lazy(() => import("./portal/Login"));
const PortalHome = lazy(() => import("./portal/Home"));
const PortalInvoice = lazy(() => import("./portal/PortalInvoice"));
const PortalPayments = lazy(() => import("./portal/PortalPayments"));

const Support = lazy(() => import("./components/support/Support"));
const SupportFAQ = lazy(() => import("./components/support/SupportFAQ"));
const SupportVideos = lazy(() => import("./components/support/SupportVideos"));
// const CreateTicket = lazy(() => import('./components/support/CreateTicket'));
// const TicketHistory = lazy(() => import('./components/support/TicketHistory'));
// const TicketDetails = lazy(() => import('./components/support/TicketDetails'));

//import blog component
const Blog = lazy(() => import("./pages/Blog"));
const BlogDetails = lazy(() => import("./pages/BlogDetails"));

//import home page
const HomeSA = lazy(() => import("./pages/LandingPage/HomeSA"));
const HomeEG = lazy(() => import("./pages/LandingPage/HomeEG"));
const HomeAE = lazy(() => import("./pages/LandingPage/HomeAE"));
const HomeIN = lazy(() => import("./pages/LandingPage/HomeIN"));
// const Documentation = lazy(() => import('./pages/Doc/Documentation'));
// const ApiDocumentation = lazy(() => import('./pages/Doc/ApiDocumentation'));

const OtpVerification = lazy(() => import("./pages/OtpVerification"));

function App() {
	const { isLoggedIn, language } = useSelector((state) => state.auth);
	const [clientIp, setClientIp] = useState("");
	/** WARNING:: Do not remove this below code */
	// const getClientAddress = async () => {
	// 	axios.get(`https://api.ipify.org?format=json`).then((res) => {
	// 		setClientIp(res?.data?.ip);
	// 	});
	// };
	/* If routing is not found then 404 page */
	const languageCode = window.location.pathname.split("/")[1];
	if (languageCode && !["en", "ar"].includes(languageCode)) {
		window.location.href = `/ar/404`;
	}
	//for logout all the tab
	useEffect(() => {
		loginAllTabs();
		logoutAllTabs();
		// getClientAddress(); // WARNING:: Do not remove this code
	}, []);

	alertify.set("notifier", "position", "top-center");

	//if device is touch device or not
	if ("ontouchstart" in document.documentElement) {
		document.body.classList.add("touch");
	} else {
		document.body.classList.remove("touch");
	}
	const allow_ip_list = process.env.REACT_APP_ALLOW_IP?.split(",");
	return (
		<BrowserRouter>
			{process.env.REACT_APP_UNDER_MAINTENANCE === "true" && !allow_ip_list.includes(clientIp) && <UnderMaintenance />}
			{(process.env.REACT_APP_UNDER_MAINTENANCE === "false" || allow_ip_list.includes(clientIp)) && (
				<Suspense fallback={<Loading />}>
					<Switch>
						{/* For SEO, Two route use for load Home page (with language (aninvoice.com/en) || without language (aninvoice.com) ) */}
						<Route exact path={`/`} name="Home" render={() => <Home2 />} />
						<Route exact path={`/:language/`} name="Home" render={() => <Home2 />} />

						<Route exact path={`/:language/sa`} name="Home" render={() => <HomeSA />} />
						<Route exact path={`/:language/eg`} name="Home" render={() => <HomeEG />} />
						<Route exact path={`/:language/ae`} name="Home" render={() => <HomeAE />} />
						<Route exact path={`/:language/in`} name="Home" render={() => <HomeIN />} />

						{/* <Route exact path={`/:language/home2`} name="Home2" render={() => <Home2 />} /> */}
						<Route exact path={`/:language/invoice/:organization_id`} name="Organization invoice" render={() => <Invoice />} />
						<Route exact path={`/:language/proforma_invoice/:organization_id`} name="Organization proforma invoice" render={() => <Invoice />} />
						<Route exact path={`/:language/sales_order/:organization_id`} name="Organization sales order" render={() => <Invoice />} />
						<Route exact path={`/:language/s`} name="Redirecting" render={() => <SharedLinkRedirect />} />
						<Route exact path={`/:language/logout`} name="Login" render={() => <Logout />} />
						<Route exact path={`/:language/login`} name="Login" render={() => <Login />} />
						<Route exact path={`/:language/register`} name="Registration" render={() => <Registration />} />
						<Route exact path={`/:language/signup`} name="Sign up" render={() => <SignUpContactPage />} />
						<Route exact path={`/:language/forgot-password`} name="Forgot Password" render={() => <ForgotPassword />} />
						<Route exact path={`/:language/invitation/:token`} name="Accept Invitation" render={() => <AcceptInvite />} />
						<Route exact path={`/:language/verify-email/:token`} name="Email verification" render={() => <EmailVerification />} />
						<Route exact path={`/:language/setpassword/:token`} name="Set New Password" render={() => <SetPassword />} />
						<Route exact path={`/:language/terms-and-conditions`} name="Terms And Conditions" render={() => <TermsAndConditions />} />
						<Route exact path={`/:language/cookie-policy`} name="Cookie Policy" render={() => <CookiePolicy />} />
						<Route exact path={`/:language/privacy-policy`} name="Privacy Policy" render={() => <PrivacyPolicy />} />
						<Route exact path={`/:language/faq`} name="FAQ" render={() => <Faq />} />
						<Route exact path={`/:language/general-question`} name="General Question" render={() => <GeneralQuestion />} />
						<Route exact path={`/:language/blog`} name="Blog" render={() => <Blog />} />
						<Route exact path={`/:language/blog/*/:id`} name="Blog" render={() => <BlogDetails />} />

						{/*This block for client portal routing*/}
						<Route exact path={`/:language/portal/:organizationId/`} name="Portal login page" render={() => <PortalLogin />} />
						<Route exact path={`/:language/portal/:organizationId/home`} name="Portal home page" render={() => <PortalHome />} />
						<Route exact path={`/:language/portal/:organizationId/invoice`} name="Portal invoice page" render={() => <PortalInvoice />} />
						<Route exact path={`/:language/portal/:organizationId/payment`} name="Portal invoice page" render={() => <PortalPayments />} />
						<Route exact path={`/:language/help`} name="Help" render={() => <Help />} />
						<Route exact path={`/:language/help/*`} name="Help" render={() => <Help />} />
						<Route exact path={`/:language/browse`} name="Browse" render={() => <CkeditorImage />} />
						<Route exact path={`/:language/support`} name="Support" render={() => <Support />} />
						<Route exact path={`/:language/support/faq`} name="Support FAQ" render={() => <SupportFAQ />} />
						<Route exact path={`/:language/support/videos`} name="Support Videos" render={() => <SupportVideos />} />
						<Route exact path={`/:language/ticket-search`} name="Ticket search" render={() => <TicketSearchResult />} />
						{/* <Route exact path={`/:language/api-docs`} name="" render={() => <Documentation />} /> */}
						{/* <Route exact path={`/:language/api-docs/details`} name="" render={() => <ApiDocumentation />} /> */}

						{/* <Route exact path={`/:language/support/create-ticket`} name="Create Ticket" render={() =><CreateTicket/>} />
						<Route exact path={`/:language/support/ticket-history`} name="Ticket History" render={() =><TicketHistory/>} />
						<Route exact path={`/:language/support/ticket-details`} name="Ticket Details" render={() =><TicketDetails/>} /> 
						<Route exact path={`/:language/404`} name="404" render={() => <Page404/>} />*/}
						<Route exact path={`/:language/404`} name="404" render={() => <Page404Home />} />
						<PrivateRoute path={`/:language/otp-verification`}>
							<OtpVerification />
						</PrivateRoute>
						<PrivateRoute path={`/:language/create-organization`}>
							<AddOrganization />
						</PrivateRoute>
						<PrivateRoute path={`/:language/`}>{isLoggedIn && <SecureLayout />}</PrivateRoute>
						{/* <Route
						exact
						path={`/:language/a/logout`}
						name='Logout'
						render={() => <Logout />}
					/> */}
						{/* <Route path={`/:language/404`}>
							<Page404 />
						</Route> */}

						{/* <Redirect from="" path={`/:language`} /> */}
						<Redirect from="*" to={`/${language}`} />
					</Switch>
				</Suspense>
			)}
		</BrowserRouter>
	);
}

export default App;
