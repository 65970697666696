import * as Icon from "react-bootstrap-icons";
import { getTimeZone } from "../services/API";
import ReactDOMServer from "react-dom/server";
import React from "react";
import * as alertify from "alertifyjs";
import { generateParentAccountOption, makeParentChildAccount } from "../components/accounting/chartOfAccounts/chart_of_account_tree";
// import "../../node_modules/alertifyjs/build/css/alertify.css";
// import { Button } from 'reactstrap';
const moment = require("moment");

const checkTokenValidate = (err) => {
	if (401 === err?.response?.status) {
		localStorage.setItem("token", "");
		window.location.reload();
	}
};

const OrganizationsLogoType = ["image/gif", "image/jpeg", "image/jpg", "image/png", "image/JPEG", "image/JPG"];
const OrganizationsLogoSize = 1050000; //1050000;
const CURRENCYLIST = [
	{ value: "SAR", en: "Saudi Riyal", ar: "الريال السعودي" },
	{ value: "EGP", en: "Egyptian Pound", ar: "جنيه مصرى" },
	{ value: "AED", en: "Emirati Dirham", ar: "درهم إماراتي" },
	{ value: "JOD", en: "Jordanian Dinar", ar: "دينار أردني" },
	{ value: "INR", en: "Indian Rupee", ar: "روبية هندية" },
	{ value: "BHD", en: "Bahraini Dinar", ar: "دينار بحريني" },
	{ value: "EUR", en: "Euro", ar: "يورو" },
	{ value: "GBP", en: "Pound Sterling", ar: "جنيه استرليني" },
	{ value: "USD", en: "United States Dollar", ar: "دولار امريكي" },
];

const COUNTRYLIST = [
	{ iso2: "SA", en: "Saudi Arabia", ar: "المملكة العربية السعودية" },
	{ iso2: "EG", en: "Egypt", ar: "مصر" },
	{ iso2: "AE", en: "United Arab Emirates", ar: "الإمارات العربية المتحدة" },
	{ iso2: "JO", en: "Jordan", ar: "الأردن" },
	{ iso2: "IN", en: "India", ar: "الهند" },

	{ iso2: "AU", en: "Australia", ar: "أستراليا" },
	{ iso2: "AT", en: "Austria", ar: "النمسا" },
	{ iso2: "BH", en: "Bahrain", ar: "البحرين" },
	{ iso2: "CA", en: "Canada", ar: "كندا" },
	{ iso2: "CY", en: "Cyprus", ar: "قبرص" },
	{ iso2: "FR", en: "France", ar: "فرنسا" },
	{ iso2: "DE", en: "Germany", ar: "ألمانيا" },
	{ iso2: "GR", en: "Greece", ar: "اليونان" },
	{ iso2: "HK", en: "Hong Kong", ar: "هونغ كونغ الصينية" },
	{ iso2: "HU", en: "Hungary", ar: "هنغاريا" },
	{ iso2: "IQ", en: "Iraq", ar: "العراق" },
	{ iso2: "IE", en: "Ireland", ar: "أيرلندا" },
	{ iso2: "IT", en: "Italy", ar: "إيطاليا" },
	{ iso2: "LB", en: "Lebanon", ar: "لبنان" },
	{ iso2: "NL", en: "Netherlands", ar: "هولندا" },
	{ iso2: "OM", en: "Oman", ar: "عُمان" },
	{ iso2: "PK", en: "Pakistan", ar: "باكستان" },
	{ iso2: "PH", en: "Philippines", ar: "الفلبين" },
	{ iso2: "PL", en: "Poland", ar: "بولندا" },
	{ iso2: "SG", en: "Singapore", ar: "سنغافورة" },
	{ iso2: "KR", en: "South Korea", ar: "كوريا الجنوبية" },
	{ iso2: "ES", en: "Spain", ar: "إسبانيا" },
	{ iso2: "SY", en: "Syria", ar: "سوريا" },
	{ iso2: "TR", en: "Turkey", ar: "تركيا" },
	{ iso2: "GB", en: "United Kingdom", ar: "المملكة المتحدة" },
	{ iso2: "US", en: "United States", ar: "الولايات المتحدة" },
];

/* const PAYMENTMODE = [
  {
	en: 'Check',
	ar: 'Check Ar',
  },
  {
	en: 'Cash',
	ar: 'Cash Ar',
  },
  {
	en: 'Bank Trasfar',
	ar: 'Bank Trasfar Ar',
  },
  {
	en: 'Credit Card',
	ar: 'Credit Card Ar',
  },
  {
	en: 'Bank Remittance',
	ar: 'Bank Remittance Ar',
  },
];
const DEPOSITETO = [
  {
	en: 'Cash',
	ar: 'Check Ar',
  },
  {
	en: 'Undeposite Fund',
	ar: 'Undeposite Fund Ar',
  },
  {
	en: 'Opening Balance',
	ar: 'Opening Balance Ar',
  },
]; */

const PERSONTILELIST = [
	{
		value: "Mr",
		en: "Mr",
		ar: "السيد",
	},
	{
		value: "Ms",
		en: "Ms",
		ar: "السيدة",
	},
	//   {
	//     value: 'Ms',
	//     en: 'Ms',
	//     ar: 'تصلب متعدد',
	//   },
];
const VATREGISTERLIST = [
	{
		value: "N",
		en: "Non VAT registered",
		ar: "غير مسجل في ضريبة القيمة المضافة",
	},
	{
		value: "Y",
		en: "VAT registered",
		ar: "مسجل في ضريبة القيمة المضافة",
	},
];

const ITEMTYPE = [
	{
		value: "G",
		label: "Goods",
	},
	{
		value: "S",
		label: "Services",
	},
];

const TAXAPPLY = [
	{ value: false, en: "Tax exclusive", ar: "لا يشمل الضرائب" },
	{ value: true, en: "Tax inclusive", ar: "شامل الضريبة" },
];

const isEmpty = (val) => {
	return val === undefined || val == null || val.length <= 0 || (typeof val == "object" && Object.keys(val).length === 0);
};

const isEmptyObject = (object) => {
	return Object.keys(object).length === 0;
};

//check if any of given keys have value at least one
const hasAnyValueInKeys = (object, keys) => {
	for (let key of keys) {
		if (!isEmpty(object[key])) {
			return true;
		}
	}
	return false;
};
//all given keys must have value
const hasAllValueInKeys = (object, keys) => {
	for (let key of keys) {
		if (isEmpty(object[key])) {
			return false;
		}
	}
	return true;
};

const chartMonthRangeDisplay = (startDate, endDate) => {
	try {
		let fromDate = moment(startDate, "YYYY-MM-DD");
		let toDate = moment(endDate, "YYYY-MM-DD");
		let resultMonth = [];
		while (toDate > fromDate || fromDate.format("M") === toDate.format("M")) {
			// resultMonth.push(fromDate.format('MMM YY'));
			resultMonth.push({
				id: fromDate.format("M"), //for start from 1 because db data id start from 1
				name: fromDate.format("MMM YY"),
				total_amount: 0,
				due_balance: 0,
			});
			fromDate.add(1, "month");
		}
		return resultMonth;
	} catch (err) {
		console.error("Error", err);
	}
};

// const last12Months = () => {
// 	var monthName = new Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');
// 	var today = new Date();
// 	today.setDate(1);
// 	var i,
// 		resultMonth = [];
// 	for (i = 0; i <= 11; i++) {
// 		resultMonth.push({
// 			id: today.getMonth() + 1, //for start from 1 because db data id start from 1
// 			name: monthName[today.getMonth()],
// 			total_amount: 0,
// 			due_balance: 0,
// 		});
// 		today.setMonth(today.getMonth() - 1);
// 	}
// 	return resultMonth.reverse();
// };

//range type key value paid
const Range = {
	WEEK: "week",
	LAST_WEEK: "last_week",
	MONTH: "month",
	LAST_MONTH: "last_month",
	YEAR: "year",
	LAST_YEAR: "last_year",
	TODAY: "today",
	YESTERDAY: "yesterday",
	QUARTER: "quarter",
	LAST_QUARTER: "last_quarter",
	CUSTOM: "custom",
};

//for react select

const reportDateRange = (t) => {
	return [
		{ value: "today", label: t("Today") },
		{ value: "yesterday", label: t("Yesterday") },
		{ value: "week", label: t("This Week") },
		{ value: "last_week", label: t("Last Week") },
		{ value: "month", label: t("This Month") },
		{ value: "last_month", label: t("Last Month") },
		{ value: "quarter", label: t("This Quarter") },
		{ value: "last_quarter", label: t("Last Quarter ") },
		{ value: "year", label: t("This Year") },
		{ value: "last_year", label: t("Last Year") },
		{ value: "custom", label: t("Custom") },
	];
};

//for Status Select
const StatusType = [
	{
		value: "A",
		label: "Active",
	},
	{
		value: "DR",
		label: "Draft",
	},
];

const closeQuickCreateMenu = () => {
	if (document.getElementById("hddrop").parentElement.classList.contains("show")) {
		document.getElementById("hddrop").click();
	}
};

//convert file size
const bytesToSize = (bytes) => {
	var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
	if (bytes === 0) return "";
	var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

//file allowed to upload
const UPLOADINGFILETYPE = [
	"image/gif",
	"image/GIF",
	"image/jpeg",
	"image/JPEG",
	"image/JPG",
	"image/jpg",
	"image/PNG",
	"image/png",
	"image/bmp",
	"image/BMP",
	"image/tif",
	"image/tiff",
	"application/pdf", //for pdf
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document", //for docx
	"application/msword", //for doc
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", //for xlsx
	"application/vnd.ms-excel", //for xls
	"text/csv",
	"application/msg",

	//for dotx
	"application/vnd.openxmlformats-officedocument.presentationml.presentation",
	"application/vnd.ms-powerpoint",
	//for xlsm, xlam
	"application/vnd.ms-excel.sheet.macroEnabled.12",
	"application/vnd.ms-excel.template.macroEnabled.12",
	//for rar, zip
	"application/vnd.rar",
	"application/zip",
	"application/vnd.ms-outlook",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.template",
];

const UPLOADINGFILESIZE = 15500000; //bytes-15 mb

const THEMELIST = [
	{
		themeId: 1,
		themeClass: "dflt",
		themeName: "Default",
		themeSlug: "thmdflt",
	},
	{
		themeId: 2,
		themeClass: "cbltblu",
		themeName: "Cobalt blue",
		themeSlug: "thmcbltblu",
	},
	{
		themeId: 3,
		themeClass: "sfrnred",
		themeName: "Saffron red",
		themeSlug: "thmsfrnred",
	},
	{
		themeId: 4,
		themeClass: "frengrn",
		themeName: "Fern green",
		themeSlug: "thmfrngren",
	},
	{
		themeId: 5,
		themeClass: "pcfcblu",
		themeName: "Pacific blue",
		themeSlug: "thmpcfcblu",
	},
];

//need to work on this. Not done yet
const INVOICE_TEMPLATE_LIST = [
	{
		templateId: 1,
		templateImage: "./../assets/images/pdftemp1.png",
		templateName: "General",
	},
	{
		templateId: 2,
		templateImage: "../assets/images/pdftemp2.jpg",
		templateName: "Standard",
	},
];

//preferred language for Organization
const PREFERRED_LANGUAGE = [
	{
		langValue: "en",
		label: "English",
		langSlug: "onlyEN",
	},
	{
		langValue: "ar",
		label: "Arabic",
		langSlug: "onlyAR",
	},
	{
		langValue: "b",
		label: "Both",
		langSlug: "both",
	},
];
//preferred language for User
const PREFLANG_USER = [
	{
		langValue: "ar",
		label: "Arabic",
		langSlug: "onlyAR",
	},
	{
		langValue: "en",
		label: "English",
		langSlug: "onlyEN",
	},
];

//help type
const HELP_TYPE = [
	{
		helpValue: "1A",
		label: "1Audit",
	},
	{
		helpValue: "AI",
		label: "Aninvoice",
	},
	{
		helpValue: "MS",
		label: "Mosyar",
	},
];

// const HELP_CATEGORY = [
//   {
//     categoryId: '1',
//     categoryName: 'General',
//   },
//   {
//     categoryId: '2',
//     categoryName: 'Dashboard',
//   },
//   {
//     categoryId: '3',
//     categoryName: 'Login',
//   },
//   {
//     categoryId: '4',
//     categoryName: 'Registration',
//   },
//   {
//     categoryId: '5',
//     categoryName: 'Password',
//   },
// ];

const HELP_STATUS = [
	{
		value: "A",
		label: "Active",
	},
	{
		value: "I",
		label: "Inactive",
	},
];

const BLOG_STATUS = [
	{
		value: "D",
		label: "Draft",
	},
	{
		value: "P",
		label: "Publish",
	},
];

//choose between interface language and preferred language to show language dependent content
// language prority
// pull org prefer language from store
// if it is both, select interface language
// else if it is any of ('en', 'ar'), select that from store

const userLanguage = (interface_language = "ar") => {
	// let df = localStorage.getItem('user_info');
	// if(df){
	//   df = JSON.parse(df);
	//   const prefLan = df.default_organization_id?.preferred_language;
	//   if (prefLan !== 'b') {
	//     return prefLan;
	//   }else{
	//     return interface_language;
	//   }
	// }else{
	//   return interface_language;
	// }

	//
	let df = localStorage.getItem("user_info");
	df = JSON.parse(df);
	const prefLan = df.default_organization_id?.preferred_language || "ar";
	return prefLan !== "b" ? prefLan : interface_language;
};

//function for sort array of objects by key
//param 1. object, 2. key to sort by
const sortByKey = (array, key) => {
	return array.sort(function (a, b) {
		var x = a[key];
		var y = b[key];
		return x < y ? -1 : x > y ? 1 : 0;
	});
};

// system accounts, which are using in reports and chart of accounts
// const systemAccounts = [
// 	'624c1660734cf417f61ab85a', //[12201] Trade receivables
// 	'624c1665734cf417f61ab941', //[32101] Trade payables
// 	'62839548398e42936aee9a6b', //[12307] VAT input
// 	'628395af398e42936aee9ada', //[32606] VAT output
// 	'6283a45a0afe6b15faec5416', //[412] Sales Discount
// 	'63a96dcd9529b193cceea347', //[512] Purchase discount
// 	'624c165e734cf417f61ab80d', //[21301] Retained earnings ( accumulated losses)
// 	'6319b8f8764e1cf1efce2dae', //[53124] Bad debt expense
// 	'63fdf78510acbebd95dee81f', //[54103] Exchange gain or loss,
// 	'6481d7980e4b83bd4719eb4a', // [32608] Unearned revenue
// 	'6481da370e4b83bd4719ef80', // [32609] inter branch account
// 	'64881f7f95e2454c8a0b4e0f' // [1250401] prepaid expense
// ];

// const mainAccountGroups = [
// 	'624c1657734cf417f61ab695', //Assets
// 	'624c1657734cf417f61ab699', //Equity
// 	'624c1657734cf417f61ab693', //Liabilities
// 	'624c1657734cf417f61ab691', //Income
// 	'624c1657734cf417f61ab697', //Expenses
// 	'624c1657734cf417f61ab69b', //Gain or Losses
// 	'624c1657734cf417f61ab69d', //Zakat and income tax
// ];

// for checkbox functionality ( e for event ) (checkIndex for CheckedArray) (datas for array of objects)
const checkBoxhelper = (e, checkedIndex, datas) => {
	const { name, checked } = e.target;
	if (name === "allselect") {
		let tempItems = datas.map((item) => {
			return { ...item, isChecked: checked };
		});
		return tempItems;
	} else {
		if (e.nativeEvent.shiftKey) {
			let lastSelectedIndex = checkedIndex.slice(-2); // get lass two selected index
			/** update between index data isChecked true or false */
			if (lastSelectedIndex[0] < lastSelectedIndex[1]) {
				let tempItems = datas.map((item, ind, array) =>
					ind > lastSelectedIndex[0] && ind <= lastSelectedIndex[1] ? { ...item, isChecked: !array[lastSelectedIndex[1]].isChecked } : item
				);
				return tempItems;
			} else {
				let tempItems = datas.map((item, ind, array) =>
					ind <= lastSelectedIndex[0] && ind >= lastSelectedIndex[1] ? { ...item, isChecked: !array[lastSelectedIndex[1]].isChecked } : item
				);
				return tempItems;
			}
		} else {
			let tempItems = datas.map((item) => (item._id === name ? { ...item, isChecked: checked } : item));
			return tempItems;
		}
	}
};

/**Array for account icon and collaps */
// const IconAndCollapseArry = {
// 	'624c1657734cf417f61ab695': <Icon.Gem className="mt-n4x" style={{ color: '#db3a3a' }} />, //Assets
// 	'624c1657734cf417f61ab699': <Icon.PiggyBank className="mt-n4x" style={{ color: '#3a73db' }} />, //Equity
// 	'624c1657734cf417f61ab693': <Icon.Tags className="mt-n4x" style={{ color: '#e7be27' }} />, //Liabilities
// 	'624c1657734cf417f61ab691': <Icon.Wallet className="mt-n4x" style={{ color: '#b33adb' }} />, //Income
// 	'624c1657734cf417f61ab697': <Icon.Receipt className="mt-n4x" style={{ color: '#256ad1' }} />, //Expenses
// 	'624c1657734cf417f61ab69b': <Icon.GraphUp className="mt-n4x" style={{ color: '#2aa784' }} />, //Gain or Losses
// 	'624c1657734cf417f61ab69d': <Icon.Percent className="mt-n4x" style={{ color: '#ff7717' }} />, //Zakat and income tax
// };

const ICON_ARRAY = {
	assets: <Icon.Gem className="mt-n4x" style={{ color: "#db3a3a" }} />, //Assets
	equity: <Icon.PiggyBank className="mt-n4x" style={{ color: "#3a73db" }} />, //Equity
	liability: <Icon.Tags className="mt-n4x" style={{ color: "#e7be27" }} />, //Liabilities
	income: <Icon.Wallet className="mt-n4x" style={{ color: "#b33adb" }} />, //Income
	expenses: <Icon.Receipt className="mt-n4x" style={{ color: "#256ad1" }} />, //Expenses
	gain_or_loss: <Icon.GraphUp className="mt-n4x" style={{ color: "#2aa784" }} />, //Gain or Losses
	tax: <Icon.Percent className="mt-n4x" style={{ color: "#ff7717" }} />, //Zakat and income tax
};

// const systemAccountsForContactBalance = [
// 	"624c1660734cf417f61ab85a", //[12201] Trade receivables
// 	"624c1665734cf417f61ab941", //[32101] Trade payables
// ];

// const systemAccountsNamed = {
// 	sale_account: '624c1662734cf417f61ab8b2', // Revenue
// 	purchase_account: '624c1665734cf417f61ab945', //Materials and purchases
// 	trade_receivables: '624c1660734cf417f61ab85a',
// 	trade_payables: '624c1665734cf417f61ab941',
// 	vat_input: '62839548398e42936aee9a6b',
// 	vat_output: '628395af398e42936aee9ada',
// 	discount: '6283a45a0afe6b15faec5416',
// 	purchase_discount: '63a96dcd9529b193cceea347',
// 	retained_earnings: '624c165e734cf417f61ab80d',
// 	net_profit: '624c165e734cf417f61ab805',
// 	bad_debit: '6319b8f8764e1cf1efce2dae',
// 	exchange_gain_or_loss: '63fdf78510acbebd95dee81f',
// 	"unearned_revenue": "6481d7980e4b83bd4719eb4a", // warning: not finalized, after final remove this comment
// 	"inter_branch_account": "6481da370e4b83bd4719ef80", // warning: not finalized, after final remove this comment
// 	"prepaid_expenses": "64881f7f95e2454c8a0b4e0f" // 1250401 // warning: not finalized, after final remove this comment
// };

//these account will be hidden in most of the reports and drop down
//! Do not find any use of this accounts
// const HIDDEN_ACCOUNTS = [
// 	'624c165e734cf417f61ab805', //net profit
// ];

const base64Encode = (plain_text) => window.btoa(plain_text);
//buyer id label
const BUYER_ID_LABEL_ARRAY = ["NAT", "TIN", "IQA", "PAS", "CRN", "MOM", "MLS", "SAG", "GCC", "700", "OTH"];
const SELLER_ID_LABEL_ARRAY = ["CRN", "MOM", "MLS", "SAG", "OTH"];

const TAX_CATEGORY = {
	//default category can hold only certain values
	//some category have tax_treatment_code as sub category
	//some of this sub category can only be applied on certain buyerId/TIN number of customer
	default_categories: [
		{
			en: "Exempt from Tax",
			ar: "توريدات معفاه",

			is_system_default: true,
			value: 0,
			tax_category: "E",
			have_reason: true, //must give reason if this tax is selected
			reasons: [
				{
					en: "Financial services", //this will only be shown in drop down
					ar: "خدمات مالية",
					tax_treatment_code: "VATEX-SA-29",
					value: "Financial services mentioned in Article 29 of the VAT Regulations", //this will be stored in db
				},
				{
					en: "Life insurance services",
					ar: "تأمين على الحياة",
					tax_treatment_code: "VATEX-SA-29-7",
					value: "Life insurance services mentioned in Article 29 of the VAT Regulations",
				},
				{
					en: "Real estate transactions",
					ar: "توريدات عقارية",
					tax_treatment_code: "VATEX-SA-30",
					value: "Real estate transactions mentioned in Article 30 of the VAT Regulations",
				},
			],
		},
		{
			en: "Out of scope",
			ar: "خارج نطاق الضريبة",

			value: 0,
			is_system_default: true,
			tax_category: "O",
		},
		{
			en: "Zero rate",
			ar: "خاضع لنسبة الصفر",

			value: 0,
			is_system_default: true,
			tax_category: "Z",
			have_reason: true,
			reasons: [
				{
					en: "Export of goods",
					ar: "Export of goods",
					tax_treatment_code: "VATEX-SA-32",
					value: "Export of goods",
				},
				{
					en: "Export of services",
					ar: "Export of services",
					tax_treatment_code: "VATEX-SA-33",
					value: "Export of services",
				},
				{
					en: "International transport of Goods",
					ar: "International transport of Goods",
					tax_treatment_code: "VATEX-SA-34-1",
					value: "The international transport of Goods",
				},
				{
					en: "International transport of passengers",
					ar: "International transport of passengers",
					tax_treatment_code: "VATEX-SA-34-2",
					value: "international transport of passengers",
				},
				{
					en: "Services directly connected and incidental to a Supply of international passenger transport",
					ar: "Services directly connected and incidental to a Supply of international passenger transport",
					tax_treatment_code: "VATEX-SA-34-3",
					value: "services directly connected and incidental to a Supply of international passenger transport",
				},
				{
					en: "Supply of a qualifying means of transport",
					ar: "Supply of a qualifying means of transport",
					tax_treatment_code: "VATEX-SA-34-4",
					value: "Supply of a qualifying means of transport",
				},
				{
					en: "Any services relating to Goods or passenger transportation",
					ar: "Any services relating to Goods or passenger transportation",
					tax_treatment_code: "VATEX-SA-34-5",
					value: "Any services relating to Goods or passenger transportation, as defined in article twenty five of these Regulations",
				},
				{
					en: "Medicines and medical equipment",
					ar: "أدوية ومعدات طبية",
					tax_treatment_code: "VATEX-SA-35",
					value: "Medicines and medical equipment",
				},
				{
					en: "Qualifying metals",
					ar: "توريدات معادن استثماريه",
					tax_treatment_code: "VATEX-SA-36",
					value: "Qualifying metals",
				},
				{
					en: "Private education to citizen",
					ar: "تعليم خاص للمواطنين",
					tax_treatment_code: "VATEX-SA-EDU",
					value: "Private education to citizen",
				},
				{
					en: "Private healthcare to citizen",
					ar: "رعاية صحية للمواطنين",
					tax_treatment_code: "VATEX-SA-HEA",
					value: "Private healthcare to citizen",
				},
			],
		},
		// {
		//
		// 		en : "Reversed",
		// 		ar : "Reversed",
		//
		// 	value : 0,
		// 	is_system_default : true,
		// 	code: "R",
		// },
	],
};
function buildTree(data, parentId = null, language) {
	console.log("data", data);
	return data
		.filter(item => item.parent_id == parentId)
		.map(item => ({
			id: item?._id,
			label: "ar" === language ? item?.title?.ar : item?.title?.en,
			...item,
			options: buildTree(data, item._id)
		}));
}

const generateRecursiveHelpOption = (data, parent_id = null, language = 'ar', disabledAccountType = false, i = 0) => {
	const he = require('he');
	let newArray = [];
	i++;
	data?.forEach((e) => {
		let rept = '&ensp;&nbsp;&nbsp;'.repeat(i);
		let bullPoint = i > 1 ? '&bull;&nbsp;&nbsp;' : '';
		disabledAccountType = e.id === parent_id ? true : disabledAccountType;
		newArray.push({ label: he.decode(rept + bullPoint + e?.label), value: e?.id, isDisabled: disabledAccountType, });
		if (e.options?.length) {
			if (e.id === parent_id) {
				disabledAccountType = true;
			}
			newArray.push(...generateRecursiveHelpOption(e?.options, parent_id, language, disabledAccountType, i));
		}
	});
	return newArray;
}
//Added by santanu
const createCategoryOptionsRecursive = (data, language) => {
	const tree = buildTree(data, null, language);
	return tree;
};
//Added by santanu
const createCategoryOptions = (data, language) => {
	// const tree = buildTree(data, null, language);
	// console.log(">>>>", JSON.stringify(data, null, 2));
	// return tree;
	let tree = createTree(data, undefined);
	let options = [];
	tree?.forEach((obj) => {
		let level = {
			label: "ar" === language ? obj?.category_name?.ar : obj?.category_name?.en,
			id: obj?._id,
			options: [],
			view_order: obj?.view_order,
		};
		obj?.children?.forEach((e) => {
			level.options.push({
				label: "ar" === language ? e?.category_name?.ar : e?.category_name?.en,
				value: e._id,
				view_order: e?.view_order,
			});
		});
		// sort options by view_order
		level.options = level.options.sort(dynamicSort("view_order"));
		options.push(level);
	});
	return options;
};
const createTree = (data, pid = undefined) => {
	return data?.reduce((r, e) => {
		if (e?.parent_id === pid) {
			const obj = { ...e };
			const children = createTree(data, e?._id);
			if (children.length) obj.children = children;
			r.push(obj);
		}
		return r;
	}, []);
};
//migration date format array declare
const dateFormatArray = [
	{ label: "DD-MM-YYYY", value: "DD-MM-YYYY" },
	{ label: "MM-DD-YYYY", value: "MM-DD-YYYY" },
	{ label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
	{ label: "YYYY-DD-MM", value: "YYYY-DD-MM" },
	{ label: "DD/MM/YYYY", value: "DD/MM/YYYY" },
	{ label: "MM/DD/YYYY", value: "MM/DD/YYYY" },
	{ label: "YYYY/MM/DD", value: "YYYY/MM/DD" },
	{ label: "YYYY/DD/MM", value: "YYYY/DD/MM" },
	{ label: "DD.MM.YYYY", value: "DD.MM.YYYY" },
	{ label: "MM.DD.YYYY", value: "MM.DD.YYYY" },
	{ label: "YYYY.MM.DD", value: "YYYY.MM.DD" },
	{ label: "YYYY.DD.MM", value: "YYYY.DD.MM" },
];
//import file types
const IMPORTFILETYPES = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"];
// const defaultChartOfAccounts = {
// 	sale_account: "624c1662734cf417f61ab8b2", // Revenue
// 	purchase_account: "624c1665734cf417f61ab945", //Materials and purchases
// };

const showDateOrTimeFormat = (date, date_time_format = "", isTimezone = true) => {
	// const moment = require('moment');
	date_time_format = !isEmpty(date_time_format) ? date_time_format : "DD MMM YYYY";
	// date_time_format = date_time_format?date_time_format:'Do MMM YYYY hh:mm A';
	return isTimezone === true ? moment(date).utcOffset(getTimeZone).format(date_time_format) : moment(date).format(date_time_format);
};

// if (type == 'date') {
// 	return moment(date).format('Do MMMM YYYY');
// 	//return moment(date).format('DD MMM YYYY');
// }
// if (type == 'time') {
// 	return moment(date).format('hh:mm A');
// }
// return moment(date).format('Do MMM YYYY hh:mm A');
// };
//display reporting tag . return tag id with name and option id with name
const displayReportingTag = (all_tag, selected_tag) => {
	let new_array = [];
	selected_tag?.forEach((tag) => {
		let tag_detals = all_tag?.find((tg) => tg?._id === tag?.tag_id);
		let opt_details = tag_detals?.options?.find((op) => op?._id === tag?.option_id);
		let new_obj = {
			...tag,
			tag_name: tag_detals?.tag_name,
			option_name: opt_details,
		};
		new_array.push(new_obj);
	});
	return new_array;
};

const replaceMultipleString = (str, matchObj) => {
	let keys = Object.keys(matchObj).join("|");
	let regExp = new RegExp("(?<!\\w)(" + keys + ")(?!\\w)", "gi");
	return str.replace(regExp, function (matched) {
		return matchObj[matched];
	});
};

//TODO: needt to replace original email template and remove this
const emailTemplateOption = [
	{
		_id: "6142e960318fc17949ced983",
		templte_name: "Default",
	},
];

//sort any array of object by a specific key=>value
//if you want descending order palase add (-) sign beforkey
//=>for ascending property.
//=>for descending -property.
const dynamicSort = (property) => {
	let sortOrder = 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}

	return function (a, b) {
		/* next line works with strings and numbers,
		 * and you may want to customize it to your needs
		 */
		let result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
		return result * sortOrder;
	};
};
// const BANKING_ACCOUNTS = [
// 	"624c165b734cf417f61ab74b",
// 	"624c1664734cf417f61ab92a",
// 	"624c1664734cf417f61ab920",
// 	"624c1664734cf417f61ab932",
// 	"624c1664734cf417f61ab924",
// ];

//object email template placeholder
const emailTemplatePlaceHolder = {
	organization: {
		organization_name: "Name",
		organization_logo: "Logo",
		organization_email: "Email",
	},
	customer: {
		customer_name: "Full Name",
		customer_email: "Email",
		customer_phone: "Phone",
		customer_currency: "Currency",
		customer_address: "Address",
	},
	proforma: {
		proforma_invoice_branch: "Proforma Branch",
		proforma_invoice_number: "Proforma Invoice Number",
		proforma_invoice_date: "Proforma Invoice Date",
		proforma_invoice_expiry_date: "Proforma Invoice Expiry Date",
		proforma_invoice_sales_person: "Proforma Invoice Sales Person",
		proforma_invoice_amount: "Proforma Invoice Amount",
	},
	invoice: {
		invoice_branch: "Branch",
		invoice_number: "Number",
		invoice_reference_number: "Refernece Number",
		invoice_date: "Date",
		invoice_terms: "Terms",
		invoice_due_date: "Due Date",
		invoice_sales_person: "Person",
		invoice_amount: "Amount",
	},
	creditnote: {
		creditnote_number: "Creditnote Number",
		creditnote_amount: "Creditnote Amount",
	},
	collection: {
		collection_number: "Number",
		collection_amount: "Amount",
		collection_date: "Date",
	},
	payment: {
		payment_number: "Number",
		payment_amount: "Amount",
		payment_date: "Date",
	},
	user: {
		user_name: "Name",
		user_email: "Email",
		url_link: "Url Link",
	},
	registration: {
		full_name: "Full Name",
		user_email: "Email Address",
	},
	forgot: {
		full_name: "Full Name",
		url_link: "Reset Link",
	},
	email: {
		full_name: "Full Name",
		url_link: "Verify Email",
	},
};

const TAX_RETURN_REPORTING_PERIODS = [
	{
		label: {
			en: "Annual",
			ar: "سنوي",
		},
		value: "A",
	},
	{
		label: {
			en: "Monthly",
			ar: "شهريا",
		},
		value: "M", // default
	},
	{
		label: {
			en: "Quarterly",
			ar: "ربعي",
		},
		value: "Q",
	},
	{
		label: {
			en: "Custom",
			ar: "مخصص",
		},
		value: "C",
	},
];
const checkOverDue = (due_date) => {
	let currentDate = moment().format("YYYY-MM-DD"); //current date
	return moment(currentDate).isAfter(moment(due_date).format("YYYY-MM-DD"), "day");
};
const INDUSTRY_SECTOR = [
	{
		label: {
			en: "Agriculture",
			ar: "زراعة",
		},
		value: "agriculture",
	},
	{
		label: {
			en: "Agency or Sales House",
			ar: "وكالة أو دار مبيعات",
		},
		value: "agency or Sales House",
	},
	{
		label: {
			en: "Art and Design",
			ar: "فن و تصميم",
		},
		value: "art and design",
	},
	{
		label: {
			en: "Automotive",
			ar: "مركبات",
		},
		value: "automotive",
	},
	{
		label: {
			en: "Construction",
			ar: "بناء",
		},
		value: "construction",
	},
	{
		label: {
			en: "Consulting",
			ar: "استشارات",
		},
		value: "consulting",
	},
	{
		label: {
			en: "Consumer Packaged Goods",
			ar: "سلع استهلاكية معبأة",
		},
		value: "consumer packaged goods",
	},
	{
		label: {
			en: "Education",
			ar: "تعليم",
		},
		value: "education",
	},
	{
		label: {
			en: "Engineering",
			ar: "هندسة",
		},
		value: "engineering",
	},
	{
		label: {
			en: "Entertainment",
			ar: "ترفيه",
		},
		value: "entertainment",
	},
	{
		label: {
			en: "Financial Services",
			ar: "خدمات مالية",
		},
		value: "financial services",
	},
	{
		label: {
			en: "Food Services (Restaurants/Fast Food)",
			ar: "خدمات الطعام (مطاعم / وجبات سريعة)",
		},
		value: "food services (restaurants/fast food)",
	},
	{
		label: {
			en: "Gaming",
			ar: "ألعاب",
		},
		value: "gaming",
	},
	{
		label: {
			en: "Government",
			ar: "قطاع حكومي",
		},
		value: "government",
	},
	{
		label: {
			en: "Health Care",
			ar: "رعاىة صحية",
		},
		value: "health care",
	},
	{
		label: {
			en: "Interior Design",
			ar: "تصميم داخلي",
		},
		value: "interior design",
	},
	{
		label: {
			en: "Internal Trade",
			ar: "تجارة داخلية",
		},
		value: "internal",
	},
	{
		label: {
			en: "Legal",
			ar: "قانون",
		},
		value: "legal",
	},
	{
		label: {
			en: "Manufacturing",
			ar: "صناعة",
		},
		value: "Manufacturing",
	},
	{
		label: {
			en: "Marketing",
			ar: "تسويق",
		},
		value: "Marketing",
	},
	{
		label: {
			en: "Mining and Logistics",
			ar: "تعدين وخدمات لوجستية",
		},
		value: "mining and logistics",
	},
	{
		label: {
			en: "Non-Profit",
			ar: "غير ربحية",
		},
		value: "non-profit",
	},
	{
		label: {
			en: "Publishing and Web Media",
			ar: "النشر ووسائط الويب",
		},
		value: "publishing and web media",
	},
	{
		label: {
			en: "Real Estate",
			ar: "العقارات",
		},
		value: "real estate",
	},
	{
		label: {
			en: "Retail (E-Commerce and Offline)",
			ar: "البيع بالتجزئة (التجارة الإلكترونية وغير المتصلة بالإنترنت)",
		},
		value: "retail (e-commerce and offline)",
	},
	{
		label: {
			en: "Services",
			ar: "خدمات",
		},
		value: "Services",
	},
	{
		label: {
			en: "Technology",
			ar: "تكنولوجيا",
		},
		value: "technology",
	},
	{
		label: {
			en: "Telecommunications",
			ar: "الإتصالات",
		},
		value: "telecommunications",
	},
	{
		label: {
			en: "Travel/Hospitality",
			ar: "السفر / الضيافة",
		},
		value: "Travel/Hospitality",
	},
	{
		label: {
			en: "Web Designing",
			ar: "تصميم المواقع",
		},
		value: "web designing",
	},
	{
		label: {
			en: "Web Development",
			ar: "تطوير الويب",
		},
		value: "web development",
	},
	{
		label: {
			en: "Writers",
			ar: "الكتَّاب",
		},
		value: "writers",
	},
];

/**
 * @name ShowAlertNotificationOptionProps
 * @typedef ShowAlertNotificationOptionProps
 * @property {boolean=} onTouchDismiss
 * @property {boolean=} dismissOthersBeforeNew
 * @property {("top-center"|"bottom-right")=} position
 * @property {number=} timer
 */
/**
 * Show alert on some action result or in progress
 * @param {(string | HTMLElement| Error)} message
 * @param {('success'|'warning'|'fail'|'progress'| 'info' )} type in which state the current action hold , 'info' is default
 * @param {ShowAlertNotificationOptionProps=} options
 */
const showAlertNotification = (message, type, options) => {
	let alert_message = message;

	// message can be an Error object
	// we only need the message in it.
	if (message instanceof Error) {
		alert_message = message.message;
		// console.log(message)
	}

	const default_options = {
		onTouchDismiss: false,
		dismissOthersBeforeNew: true,
		position: "top-center",
		timer: 5,
	};
	const alert_options = {
		...default_options,
		...options,
	};

	alertify.set("notifier", "position", alert_options.position); // first a direct must be set if using custom direct

	if (alert_options.dismissOthersBeforeNew) {
		alertify.dismissAll(); // before new alert dismish any other alert if visiable
	}
	const al = alertify.notify("KI", "cus-suc"); // initiate a instance

	al.delay(alert_options.timer < 0 ? 0 : alert_options.timer); // update the timer

	const dismissNotification = () => {
		al.dismiss();
	};

	// set html content to the alert message
	const AlertContent = () => {
		let AlertIcon;
		let class_name_of_type;
		switch (type) {
			case "success": {
				AlertIcon = Icon.CheckCircleFill;
				class_name_of_type = "success";
				break;
			}
			case "info": {
				AlertIcon = Icon.InfoCircleFill;
				class_name_of_type = "infos";
				break;
			}
			case "fail": {
				AlertIcon = Icon.ExclamationTriangleFill;
				class_name_of_type = "fails";
				break;
			}
			case "warning": {
				AlertIcon = Icon.BellFill;
				class_name_of_type = "warng";
				break;
			}
			case "progress": {
				AlertIcon = Icon.HourglassSplit;
				class_name_of_type = "infos";
				break;
			}
			default: {
				// type "info" is default
				AlertIcon = Icon.InfoCircleFill;
				class_name_of_type = "infos";
				break;
			}
		}
		return (
			<div id="alertswt2" className={`alertswt2 ${class_name_of_type}`}>
				<article className="d-flex">
					<div className="icns">
						<span>
							<AlertIcon />
						</span>
					</div>
					<div className="txts flex-grow-1">{alert_message}</div>
					<div className="crss">
						<button id="alert_close_btn">
							<Icon.XLg />
						</button>
					</div>
				</article>
			</div>
		);
	};
	const alertify_content = ReactDOMServer.renderToStaticMarkup(<AlertContent />);
	const dom_content = document.createElement("div");
	dom_content.innerHTML = alertify_content;
	al.setContent(dom_content); // set dom element and other properties

	// alert close handler must be register manually
	document.getElementById("alert_close_btn").onclick = () => {
		al.ondismiss = () => true;
		dismissNotification();
	};

	// prevent auto close after click on the alert except the close button.
	if (alert_options.onTouchDismiss === false) {
		document.getElementById("alertswt2").onclick = () => {
			al.ondismiss = () => false;
			dismissNotification();
		};
	}

	al.dismissCurrentNotification = () => {
		dismissNotification();
	};

	return { al }; // exposing the instance so can be controlled from caller
};

const LANDING_PAGE_COUNTRY_LIST = [
	{
		label: "India",
		value: "in",
	},
	{
		label: "United Arab Emirates",
		value: "ae",
	},
	{
		label: "Saudi Arabia",
		value: "sa",
	},
	{
		label: "Egypt",
		value: "eg",
	},
];
const listOfSystemAccountsFromConfig = (accountSettings) => {
	if (isEmpty(accountSettings)) return [];
	const accounts = [];
	// including system_defaults, all modules also contribute to system accounts
	const system_default_accounts = Object.values(accountSettings.system_defaults) ?? [];
	const sales_default_item_account = accountSettings?.sales_accounts?.default_item_account;
	const purchase_default_item_account = accountSettings?.purchase_accounts?.default_item_account;
	const collection_default_deposit_to_account = accountSettings?.collection_accounts?.default_deposit_to_account;
	const bank_parent_in_accounts = accountSettings?.bank_parent_accounts?.in_accounts ?? [];
	const bank_parent_default_account = accountSettings?.bank_parent_accounts?.default_account;
	const expense_in_accounts = accountSettings?.expense_accounts?.in_accounts ?? [];
	const expense_default_account = accountSettings?.expense_accounts?.default_expense_account;
	const expense_paid_through_in_accounts = accountSettings?.expense_paid_through_accounts?.in_accounts ?? [];
	const expense_paid_through_default_account = accountSettings?.expense_paid_through_accounts?.default_paid_through_account;
	const manual_journal_in_accounts = accountSettings?.manual_journal_accounts?.in_accounts ?? [];
	const bank_transfer_in_accounts = accountSettings?.bank_transfer_accounts?.in_accounts ?? [];

	const inventory_in_account = accountSettings?.inventory_accounts?.in_accounts ?? [];
	const inventory_default_inventory_account = accountSettings?.inventory_accounts?.default_inventory_account ?? null;
	const inventory_opening_balance_adjustments_account = accountSettings?.inventory_accounts?.opening_balance_adjustments_account ?? null;
	const inventory_transit_account = accountSettings?.inventory_accounts?.inventory_transit_account ?? null;

	accounts.push(...system_default_accounts);
	if (sales_default_item_account) accounts.push(sales_default_item_account);
	if (purchase_default_item_account) accounts.push(purchase_default_item_account);
	if (collection_default_deposit_to_account) accounts.push(collection_default_deposit_to_account);
	if (bank_parent_default_account) accounts.push(bank_parent_default_account);
	if (expense_default_account) accounts.push(expense_default_account);
	if (expense_paid_through_default_account) accounts.push(expense_paid_through_default_account);
	if (inventory_opening_balance_adjustments_account) accounts.push(inventory_opening_balance_adjustments_account);
	if (inventory_default_inventory_account) accounts.push(inventory_default_inventory_account);

	accounts.push(...bank_parent_in_accounts);
	accounts.push(...expense_in_accounts);
	accounts.push(...expense_paid_through_in_accounts);
	accounts.push(...manual_journal_in_accounts);
	accounts.push(...bank_transfer_in_accounts);
	accounts.push(...inventory_in_account);
	accounts.push(inventory_opening_balance_adjustments_account);
	accounts.push(inventory_transit_account);
	return accounts;
};

/**
 * Uppercase the first lette
 * @returns hello world => Hello world
 */
String.prototype.firstUpperCase = function () {
	return this.charAt(0).toUpperCase() + this.slice(1);
};

// check if small array exists in big array
const isSubset = (smallArray, bigArray) => {
	return smallArray.every((val) => bigArray.includes(val));
};
// remove a subset from big array
const removeSubset = (smallArray, bigArray) => {
	return bigArray.filter((val) => !smallArray.includes(val));
};

// check if given value is a number
const isNumber = (value) => {
	return typeof value === "number" && isFinite(value);
};
// check if, given value is a valid ObjectId
const isObjectId = (value) => {
	return value ? value.match(/^[0-9a-fA-F]{24}$/) : false;
};
//whatsapp message template
const MESSAGE_TEMPLATE = {
	en: "Please click the below link to view, print or download the *{title}*:\n {url}",
	ar: "الرجاء الضغط على الرابط أدناه لعرض أو طباعة أو تنزيل *{title}*:\n {url}",
};

/**
 * @description pass an array of values and a function to check the value return a truthy value if
 * found in order otherwise return default value
 * @param {Array.<any>} array_of_values values to check, must be in order
 * @param {(value)=>boolean} check_function function to check the value if it returns a truthy value.
 * @param default_value value to return if no value found
 */
const OptionalChain = (array_of_values, check_function, default_value) => {
	for (let i = 0; i < array_of_values.length; i++) {
		if (check_function(array_of_values[i])) {
			// console.log("found", array_of_values[i]);
			return array_of_values[i];
		}
	}
	return default_value;
};

const isNotEmpty = (value) => {
	return !isEmpty(value);
};

// inventory item code
const PRODUCT_CODE_TYPE = [
	{
		value: "UPC",
		en: "UPC",
		ar: "UPC",
	},
	{
		value: "EAN",
		en: "EAN",
		ar: "EAN",
	},
	{
		value: "OTH",
		en: "Other",
		ar: "أخرى",
	},
];

// dimension_unit
const DIMENSION_UNIT = [
	{
		value: "cm",
		en: "cm",
		ar: "cm",
	},
	{
		value: "in",
		en: "in",
		ar: "in",
	},
];
// weight unit
const WEIGHT_UNIT = [
	{
		value: "kg",
		en: "kg",
		ar: "kg",
	},
	{
		value: "lb",
		en: "lb",
		ar: "lb",
	},
	{
		value: "oz",
		en: "oz",
		ar: "oz",
	},
	{
		value: "g",
		en: "g",
		ar: "g",
	},
];

const filterOutChartOfAccounts = ({ all_accounts, in_account_ids, exclude_account_ids, language }) => {
	// filter out accounts whose _id matches with exclude_account_ids
	let filtered_account_ids = all_accounts?.filter(function (value, index) {
		return exclude_account_ids.indexOf(value?._id) === -1;
	});

	const expensesAccountOptions = [];
	in_account_ids?.forEach((account_id, i) => {
		let accountObj = makeParentChildAccount(filtered_account_ids, account_id);
		const optionData = generateParentAccountOption(accountObj, language, true);
		expensesAccountOptions.push(...optionData);
	});
	return expensesAccountOptions;
};

/**
 * @description allowOnlyDecimalNumber() function validate
 * the given number is a valid two decimal number or not
 * don't allow any char, special chars, symbol etc
 * allow only 2 decimal number e.g. 12.34, 123.45,
 * @param event : <Object> required parameter
 * @param decimal_point : <integer>  optional parameter to allow decimal point , default is 2
 * @return true if condition match
 * @return false on if condition doesn't match
 */
function allowOnlyDecimalNumber(event, decimal_point = 2) {
	const allowedKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowRight", "ArrowLeft"];
	const key = event.key;

	// Allowing backspace
	if (key === "Backspace" || key === "ArrowRight" || key === "ArrowLeft") {
		return true;
	}

	// Allow only two digits after the decimal point
	const splitInput = event.target.value.split(".");
	if (splitInput[1] && splitInput[1].length >= decimal_point) {
		event.preventDefault();
		return false;
	}

	// todo : allow how many digits before decimal point

	// Allowing decimal point and numbers
	if (allowedKeys.includes(key)) {
		// Allowing only one decimal point
		if (key === "." && event.target.value.includes(".")) {
			event.preventDefault();
			return false;
		}
		return true;
	}

	// Preventing any other keys
	event.preventDefault();
	return false;
}

// array of invoice repeat option
const INVOICE_REPEAT_OPTION = [
	{
		value: "1_week",
		label: { ar: "Week", en: "Week" },
		frequency: "weeks",
		repeat_every: 1,
	},
	{
		value: "2_week",
		label: { ar: "2 Weeks", en: "2 Weeks" },
		frequency: "weeks",
		repeat_every: 2,
	},
	{
		value: "1_month",
		label: { ar: "Month", en: "Month" },
		frequency: "months",
		repeat_every: 1,
	},
	{
		value: "2_month",
		label: { ar: "2 Months", en: "2 Months" },
		frequency: "months",
		repeat_every: 2,
	},
	{
		value: "3_month",
		label: { ar: "3 Months", en: "3 Months" },
		frequency: "months",
		repeat_every: 3,
	},
	{
		value: "6_month",
		label: { ar: "6 Months", en: "6 Months" },
		frequency: "months",
		repeat_every: 6,
	},
	{
		value: "1_year",
		label: { ar: "Year", en: "Year" },
		frequency: "years",
		repeat_every: 1,
	},
	{
		value: "2_year",
		label: { ar: "2 Years", en: "2 Years" },
		frequency: "years",
		repeat_every: 2,
	},
	{
		value: "3_year",
		label: { ar: "3 Years", en: "3 Years" },
		frequency: "years",
		repeat_every: 3,
	},
	{
		value: "custom",
		label: { ar: "Custom", en: "Custom" },
		// repeat_frequency is not needed , as we take frequency from text box for custom
		option: [
			{ value: "1_day", label: { ar: "Day(s)", en: "Days(s)" }, frequency: "days" },
			{ value: "1_week", label: { ar: "Week(s)", en: "Week(s)" }, frequency: "weeks" },
			{ value: "1_month", label: { ar: "Month(s)", en: "Month(s)" }, frequency: "months" },
			{ value: "1_year", label: { ar: "Year(s)", en: "Year(s)" }, frequency: "years" },
		],
	},
];
const formatAddress = (address) => {
	let formattedAddress = "";

	for (let key in address) {
		if (address[key] !== "") {
			formattedAddress += `${key}: ${address[key]}, `;
		}
	}
	// Remove the trailing comma and space
	formattedAddress = formattedAddress.slice(0, -2);
	return formattedAddress;
};

/**
 * @CalculateNextRecurringModuleIssueDate function used to calculate
 * issue_date of next invoice / bills/ expenses based
 * upon the selected start date & recurring period & frequency
 * ------------------------------------------------------------------------------------------------
 * CASE - 1 : start_date < current date => next_expenses_date will calculated from current date
 * CASE - 2 : start_date = current date => next_expenses_date will calculated from current date
 * CASE - 3 : start_date > current date => next_expenses_date will calculated from start_date decrease repeat_frequency by 1
 * -------------------------------------------------------------------------------------------------
 */
const CalculateNextRecurringModuleIssueDate = (frequency, repeat_frequency, start_date) => {
	let next_date;
	const current_date = moment().format("YYYY-MM-DD");
	if (start_date <= current_date) {
		start_date = current_date;
	}

	// for days
	if ("days" === frequency) {
		next_date = moment(start_date)
			.add(start_date > current_date ? repeat_frequency - 1 : repeat_frequency, "days")
			.format("YYYY-MM-DD");
	}
	// for weeks
	if ("weeks" === frequency) {
		next_date = moment(start_date)
			.add(start_date > current_date ? repeat_frequency - 1 : repeat_frequency, "weeks")
			.format("YYYY-MM-DD");
	}
	// for months
	if ("months" === frequency) {
		next_date = moment(start_date)
			.add(start_date > current_date ? repeat_frequency - 1 : repeat_frequency, "months")
			.format("YYYY-MM-DD");
	}
	// for years
	if ("years" === frequency) {
		next_date = moment(start_date)
			.add(start_date > current_date ? repeat_frequency - 1 : repeat_frequency, "years")
			.format("YYYY-MM-DD");
	}
	return next_date;
};

/**
 * @calculateNextRecurringModuleDueDate function used to calculate
 * due_date based upon the selected payment terms
 * from issue date
 */
const calculateNextRecurringModuleDueDate = (issue_data, paymentDueTerms) => {
	let due_date;

	if (paymentDueTerms?.value) {
		if ("end_of_month" === paymentDueTerms?.value) {
			due_date = moment(issue_data).endOf("month").format("YYYY-MM-DD");
		} else if ("end_of_next_month" === paymentDueTerms?.value) {
			due_date = moment(issue_data).add(1, "M").endOf("month").format("YYYY-MM-DD");
		} else if ("same_day" === paymentDueTerms?.value) {
			due_date = moment(issue_data).format("YYYY-MM-DD");
		} else {
			due_date = moment(issue_data).add(Number(paymentDueTerms?.value), "days").format("YYYY-MM-DD");
		}

		return due_date;
	} else {
		return {
			error: true,
			message: "Unable to generate next due date",
		};
	}
};

const deepClone = (obj) => {
	return JSON.parse(JSON.stringify(obj));
};

const getSearchParams = (search_string) => {
	const searchParams = new URLSearchParams(search_string);
	return Object.fromEntries(searchParams.entries());
};

/**
 * given the condition array check if the condition is true then
 * check recursively the next condition
 * @param{JSX.Element} default_element
 * @param {Array.<{is_true: boolean, element:JSXIf | JSX.Element }>} conditions
 * @constructor
 */
const JSXIf = ({ default_element, conditions }) => {
	// Iterate over each condition
	for (let i = 0; i < conditions.length; i++) {
		const condition = conditions[i];

		// If the condition is true, return the corresponding element
		if (condition.is_true) {
			return condition.element;
		}
	}

	// If none of the conditions are true, return the default element
	return default_element;
};
// create transaction status drop down
const TRANSACTION_STATUS_OPTION = [
	{ label: "Draft", value: "DR" },
	{ label: "Void", value: "V" },
	{ label: "In transit", value: "IN_TRANSIT" },
	{ label: "Transferred", value: "TRANSFERRED" },
];

// create transaction status option for inventory adjustment
const TRANSACTION_STATUS_OPTION_FOR_INV_ADJUSTMENT = [
	{ label: "Draft", value: "DR" },
	{ label: "Adjusted", value: "AD" },
];
// create inventory adjustment type
const INVENTORY_ADJUSTMENT_TYPE = [
	{ label: "Quantity", value: "quantity" },
	{ label: "Value", value: "value" },
];

// create frequency drop down used for recurring invoice/bills/expenses etc. module
const INVOICE_REPEAT_CUSTOM_OPTION = [
	{ label: "Daily", value: "days" },
	{ label: "Weekly", value: "weeks" },
	{ label: "Monthly", value: "months" },
	{ label: "Yearly", value: "years" },
];

// validate email address
const checkValidEmailAddress = (email) => {
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	return emailRegex.test(email);
};

const isStrongPassword = (password) => {
	// Define your criteria for a strong password
	const minLength = 8;
	const hasUppercase = /[A-Z]/.test(password);
	const hasLowercase = /[a-z]/.test(password);
	const hasNumber = /[0-9]/.test(password);
	const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);

	return password.length >= minLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
};

/**
 * Filters branches based on the provided settings permission and allowed branch IDs.
 * If the settings permission allows access to all branches, the original branches array is returned.
 * Otherwise, branches are filtered based on the allowed branch IDs and a default branch ID is determined.
 *
 * @param {Object} settingsPermission - The settings permission object.
 * @param {Array} branches - The array of branches to filter.
 * @param {Array} allow_branch_ids - The array of allowed branch IDs.
 * @param {string} default_branch_id - The default branch ID.
 * @returns {Object} - An object containing the filtered branches and the default branch ID.
 */
const filterBranchesByPermission = ({ settingsPermission, branches, allow_branch_ids, default_branch_id }) => {
	// THis is correct if (false === settingsPermission?.settings_access_all_branches) {
	if (false === settingsPermission?.settings_access_all_branches) {
		const filteredBranches = branches?.filter((branch) => allow_branch_ids?.includes(branch._id));
		const defaultBranchId = !filteredBranches?.find((branch) => branch._id === default_branch_id) ? filteredBranches[0]?._id : default_branch_id;
		return { filteredBranches, defaultBranchId };
	} else {
		// If the settings permission allows access to all branches, return the original branches array.
		return { filteredBranches: branches, defaultBranchId: default_branch_id };
	}
};

export {
	checkTokenValidate,
	OrganizationsLogoType,
	OrganizationsLogoSize,
	CURRENCYLIST,
	COUNTRYLIST,
	PERSONTILELIST,
	VATREGISTERLIST,
	ITEMTYPE,
	TAXAPPLY,
	isEmpty,
	chartMonthRangeDisplay,
	// last12Months,
	Range,
	reportDateRange,
	StatusType,
	closeQuickCreateMenu,
	bytesToSize,
	UPLOADINGFILETYPE,
	UPLOADINGFILESIZE,
	THEMELIST,
	INVOICE_TEMPLATE_LIST,
	PREFERRED_LANGUAGE,
	PREFLANG_USER,
	HELP_TYPE,
	HELP_STATUS,
	userLanguage,
	sortByKey,
	// systemAccounts,
	checkBoxhelper,
	// IconAndCollapseArry,
	ICON_ARRAY,
	// systemAccountsForContactBalance,
	// HIDDEN_ACCOUNTS,
	base64Encode,
	BUYER_ID_LABEL_ARRAY,
	TAX_CATEGORY,
	createCategoryOptions,
	isEmptyObject,
	hasAnyValueInKeys,
	hasAllValueInKeys,
	dateFormatArray,
	IMPORTFILETYPES,
	// defaultChartOfAccounts,
	showDateOrTimeFormat,
	SELLER_ID_LABEL_ARRAY,
	displayReportingTag,
	replaceMultipleString,
	emailTemplateOption,
	dynamicSort,
	// mainAccountGroups,
	// systemAccountsNamed,
	// BANKING_ACCOUNTS,
	emailTemplatePlaceHolder,
	TAX_RETURN_REPORTING_PERIODS,
	checkOverDue,
	INDUSTRY_SECTOR,
	showAlertNotification,
	BLOG_STATUS,
	LANDING_PAGE_COUNTRY_LIST,
	listOfSystemAccountsFromConfig,
	isSubset,
	removeSubset,
	isNumber,
	isObjectId,
	MESSAGE_TEMPLATE,
	OptionalChain,
	isNotEmpty,
	PRODUCT_CODE_TYPE,
	DIMENSION_UNIT,
	WEIGHT_UNIT,
	filterOutChartOfAccounts,
	allowOnlyDecimalNumber,
	INVOICE_REPEAT_OPTION,
	formatAddress,
	CalculateNextRecurringModuleIssueDate,
	calculateNextRecurringModuleDueDate,
	deepClone,
	getSearchParams,
	JSXIf,
	TRANSACTION_STATUS_OPTION,
	INVENTORY_ADJUSTMENT_TYPE,
	INVOICE_REPEAT_CUSTOM_OPTION,
	TRANSACTION_STATUS_OPTION_FOR_INV_ADJUSTMENT,
	checkValidEmailAddress,
	isStrongPassword,
	filterBranchesByPermission,
	generateRecursiveHelpOption,
	createCategoryOptionsRecursive
};
