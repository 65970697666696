import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_AR } from './languages/ar';
import { TRANSLATIONS_EN } from './languages/en';

let lang = window.location.pathname.split('/')[1]; //fetch language from url en or ar
let language = lang? lang: localStorage.getItem('language'); //from store

i18n
	// .use(LanguageDetector)
	.use(initReactI18next)
	.init({
		lng: language ? language : 'ar',
		resources: {
			en: {
				translation: TRANSLATIONS_EN,
			},
			ar: {
				translation: TRANSLATIONS_AR,
			},
		},
	});

export default i18n;
