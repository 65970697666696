import React, { lazy, useRef, useEffect, useState, useCallback } from "react";
import { Loading } from "./../../common";
import OrganizationDetails from "./../organization/OrganizationDetails";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as Icon from "react-bootstrap-icons";

import { Dropdown, Row, Col, Button, Modal } from "react-bootstrap";
import { useIsMountedRef } from "./../../util/unmountedComponents";
import { showAlert } from "../../util/alertModal";
import { SET_LANGUAGE } from "../../store/actions/types";
import { getUserInformation, changeOrganization } from "../../services/auth.service";
import { LOGIN_SUCCESS } from "../../store/actions/types";
import Enflag from "../../assets/images/eng-lang.svg";
import Arflag from "../../assets/images/sa-lang.svg";
import "./../../translations/i18n";
import { useTranslation } from "react-i18next";
import alertify from "alertifyjs";
import CommonSearch from "./CommonSearch";
import SidebarSettingsMenu from "./SidebarSettingsMenu";
import { UPDATE_ORGANIZATION, LOGOUT } from "../../store/actions/types";
import { sendVerificationEmail, getInvitations, patchInvitation } from "../../services/users.service";
import { isEmpty, isNotEmpty, showAlertNotification } from "../../util/helper";
import AlertMessage from "./AlertMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge, faShoppingBasket, faShoppingBag, faCog, faCogs, faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { Scrollbars } from "react-custom-scrollbars-2";
import { getAccountTemplate, getAllAccounts } from "../../services/account.service";
import { loginAll } from "../../util/multiTabLogout";

const RightSideProfile = lazy(() => import("../settings/my-account/RightSideProfile"));

const InnerHeader = () => {
	const wrapperRef = useRef(null);
	const userRef = useRef(null);
	const orgnameRef = useRef(null);
	useOutsideOrgList(wrapperRef);

	const settingIconRef = useRef(null);
	const settingRef = useRef(null);
	useOutsideSettingList(settingRef);

	const role_and_permission = useSelector((state) => state?.auth?.userInfo?.role_and_permission?.permission_group);
	const itemPermission = role_and_permission?.items;
	// const contactPermission = role_and_permission?.contacts;
	const salePermission = role_and_permission?.sales;
	const purchasePermission = role_and_permission?.purchases;

	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const { search, pathname } = useLocation();
	const [show, setShow] = useState();
	const [stingshow, setStingShow] = useState();
	const [notificshow, setnotificShow] = useState();
	const [isOnline, setIsOnline] = useState(navigator.onLine); //for online offline
	const [hideOnlineAlert, setHideOnlineAlert] = useState(false); //for online offline

	const { language, userInfo } = useSelector((state) => state.auth);
	let userFullName = userInfo?.first_name + " " + userInfo?.last_name;
	const isMountedRef = useIsMountedRef();
	const [organizations, setOrganizations] = useState([]);
	const [loadingItems, setLoadingItems] = useState(false);
	let second_path = pathname.split("/")[2];
	const settingPermission = useSelector((state) => state?.auth?.userInfo?.role_and_permission?.permission_group?.settings);
	const prime_organization_id = process.env.REACT_APP_PRIME_ORG_ID;
	let SHOW_MAINTENANCE_MESSAGE = null;

	//for language change
	const changeLanguage = (lang) => {
		document.body.classList.add("langChange");
		let url = window.location.pathname;
		let searchParam = search;
		let finalUrl = url.concat(searchParam);
		url = finalUrl.replace("/" + language, "/" + lang);
		i18n.changeLanguage(lang);
		localStorage.setItem("language", lang);
		dispatch({
			type: SET_LANGUAGE,
			payload: { language: lang },
		});
		history.push(url);
		window.location.reload();
	};

	//for change organization.....
	const switchOrganization = async (organizationId, organizationName) => {
		//if (userInfo?.default_organization_id?._id != organizationId) {
		let orgName = `<b>${organizationName}</b>`;
		alertify
			.confirm(
				`${t("Change organization")}`,
				`${t("Do you want to change the organization")} ${orgName}` + `${t("?")}`,
				async function () {
					try {
						setLoadingItems(true);
						let isLogin = await changeOrganization(organizationId);
						setLoadingItems(false);
						if (isLogin.token) {
							dispatch({
								//dispatch for reducer store
								type: LOGIN_SUCCESS,
								payload: { userInfo: isLogin.user_info },
							});
							showAlert(t("Organization changed successfully"));
							loginAll(); //we use this function to reload all the open tab and send to dashboard
							history.push(`/${language}/dashboard`);
							// window.location.reload();
						}
					} catch (err) {
						setLoadingItems(false);
						showAlertNotification(t("Something wrong happened!"), "fail");
					}
				},
				function () {
					//do nothing on cancel
				}
			)
			.set("labels", { ok: t("Ok"), cancel: t("Cancel") })
			.set({ transitionOff: true });
		//}
	};
	function useOutsideOrgList(ref) {
		useEffect(() => {
			/**
			 * if clicked on outside of element settingRef
			 */
			function handleClickOutsideOrgList(event) {
				if (
					ref.current &&
					!ref.current.contains(event.target) &&
					!orgnameRef.current.contains(event.target) &&
					!userRef.current.contains(event.target)
				) {
					setShow(false);
				}
			}
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutsideOrgList);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutsideOrgList);
			};
		}, [ref]);
	}
	function useOutsideSettingList(ref) {
		useEffect(() => {
			/**
			 * if clicked on outside of element settingRef
			 */
			function handleClickOutsideSettgList(event) {
				if (ref.current && !ref.current.contains(event.target) && !settingIconRef.current.contains(event.target)) {
					setStingShow(false);
				}
			}
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutsideSettgList);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutsideSettgList);
			};
		}, [ref]);
	}
	useEffect(() => {
		async function loadInit() {
			try {
				let selectElements = "_id,organizations";
				let userDetails = await getUserInformation(selectElements);
				if (isMountedRef.current && userDetails?.organizations) {
					//list organizations in which current is active(A) and not Suspeded or Removed
					setOrganizations(
						userDetails?.organizations.filter(
							(organization) => organization?.organization_id?.status !== "D" && organization.status === "A" //list is not show then comment @asif
						)
					);
					//added by @surojit
					//update role and permission when page is reloaded
					const user_info = JSON.parse(localStorage.getItem("user_info"));
					user_info["role_and_permission"] = userDetails?.role_and_permission;
					user_info["chart_of_account_type"] = userDetails?.chart_of_account_type;
					user_info["email_verified"] = userDetails?.email_verified;
					user_info["phone_verified"] = userDetails?.phone_verified;
					user_info["allow_organization_limit"] = userDetails?.allow_organization_limit;
					user_info["phone_no"] = userDetails?.phone_no;
					user_info["user_id"] = userDetails?._id;
					user_info["default_organization_id"] = userDetails?.current_organization_details;
					user_info["exchange_rates"] = userDetails?.exchange_rates;
					user_info["country_list"] = userDetails?.country_list;
					localStorage.setItem("user_info", JSON.stringify(user_info));
					dispatch({
						type: UPDATE_ORGANIZATION,
						payload: { userInfo: user_info },
					});

					const organization_id = userDetails?.current_organization_details?._id;
					// we set coa and account settings only for normal organization as it has coa
					// but prime does not have any particular coa and account settings, so we will not set
					// those values for prime.
					if (organization_id !== prime_organization_id) {
						const account_template_id = userDetails?.account_template_id;
						user_info["account_template_id"] = account_template_id;
						//get all chart of accounts of users current organizations(default_organization)
						//also update store and localStorage
						let chart_off_accounts_res = await getAllAccounts("", "&mode=all&fox_redux=true");
						let chart_off_accounts = chart_off_accounts_res?.data ?? [];
						user_info["chart_of_account"] = chart_off_accounts;
						localStorage.setItem("user_info", JSON.stringify(user_info));
						dispatch({
							type: UPDATE_ORGANIZATION,
							payload: { userInfo: user_info },
						});

						// get and set the account settings
						if (account_template_id) {
							let res_data = await getAccountTemplate(account_template_id);
							let account_template = res_data?.account_template;
							user_info["account_settings"] = account_template?.account_config ?? {};
							localStorage.setItem("user_info", JSON.stringify(user_info));
							dispatch({
								type: UPDATE_ORGANIZATION,
								payload: { userInfo: user_info },
							});
						}
					}

					//if 'email_verified' field is not present or false unhide this alert
					if (userDetails?.email_verified === false || isEmpty(userDetails?.email_verified)) {
						setShowEmailVerificationAlert(true);
					}
				}
			} catch (err) {
				showAlert(t("Something wrong happened!"), true);
				setTimeout(() => {
					dispatch({
						type: LOGOUT,
					});
					history.push(`/${language}/login`);
				}, 2000);
			}
		}
		loadInit();
	}, [isMountedRef]);

	const userDetailsDropdownShow = () => {
		//if(!show)
		setShow(!show);
		setStingShow(false);
		setnotificShow(false);
	};
	const settingDropdownShow = () => {
		setStingShow(!stingshow);
		setShow(false);
		setnotificShow(false);
	};
	const notificDropdownShow = () => {
		setnotificShow(!notificshow);
		setShow(false);
		setStingShow(false);
	};

	//email verification related code
	//added by @surojit, 19/4/2022
	//edited by :
	const [showEmailVerificationAlert, setShowEmailVerificationAlert] = useState(false); //default it should be hidden
	const [isVerificationButtonLoading, setIsVerificationButtonLoading] = useState(false);
	const handleEmailVerificationAlertClose = () => {
		setShowEmailVerificationAlert(false);
	};
	//send a verification email to the user
	//and hide this alert, if page is reoloaded and email is not verified, it will be visiable again
	//if success, show a regular alert
	const handleVerificationEmailSent = async () => {
		setIsVerificationButtonLoading(true);
		try {
			const emailSendRes = await sendVerificationEmail();
			if (emailSendRes) {
				setIsVerificationButtonLoading(false);
				setShowEmailVerificationAlert(false); //hide this alert

				//show a success alert
				setIsAlertshow("show");
				setErrorDisplay(t("Verification email has been send to your email address. please check your email."));
			}
		} catch (err) {
			setIsVerificationButtonLoading(false);
		}
	};

	//error alert pop up related code
	//added by @surojit, 19/4/2022
	const [errorDisplay, setErrorDisplay] = useState("");
	const [isAlertshow, setIsAlertshow] = useState("show");
	const setIsAlertshowHandler = (val) => {
		setIsAlertshow(val);
	};

	useEffect(() => {
		setStingShow(false);
		setShow(false);
	}, [pathname]);

	//new code for internet connection check
	const setOnline = () => {
		setIsOnline(true);
		setHideOnlineAlert(true);
		setTimeout(() => {
			setHideOnlineAlert(false);
		}, 2000);
	};
	const setOffline = () => {
		setIsOnline(false);
	};

	// Register the event listeners
	useEffect(() => {
		window.addEventListener("offline", setOffline);
		window.addEventListener("online", setOnline);
		// cleanup if we unmount
		return () => {
			window.removeEventListener("offline", setOffline);
			window.removeEventListener("online", setOnline);
		};
	}, []);

	return (
		<>
			{loadingItems && <Loading editPage="true" />}
			{errorDisplay && (
				<AlertMessage
					parentCallback={() => {
						setIsAlertshowHandler(" ");
					}}
					type="success"
					message={errorDisplay}
					show={isAlertshow}
				/>
			)}
			{showEmailVerificationAlert && second_path !== "my-account" && (
				<div className="emailnotvrfy">
					<article className="msgbx">
						{" "}
						{t("Please verify your email")}{" "}
						<Button
							onClick={handleVerificationEmailSent}
							variant={`primary lh-1 ml-5 ${true === isVerificationButtonLoading ? "loading" : ""}`}
							size="sm"
						>
							{" "}
							{t("Verify")}{" "}
						</Button>{" "}
						<Button variant="link p-0 lh-1 close" onClick={handleEmailVerificationAlertClose}>
							{" "}
							<Icon.XLg />{" "}
						</Button>{" "}
					</article>
				</div>
			)}

			{!isOnline && (
				<div className="netconnect">
					<article className="msg red">
						<div className="d-flex aIc">
							<div>
								<Icon.WifiOff />
							</div>
							<article className="flex-grow-1">{t("Your device lost its internet connection.")}</article>
							<div>
								<Icon.ArrowClockwise className="round" style={{ color: "#32323285" }} />
							</div>
						</div>
					</article>
				</div>
			)}
			{isOnline && hideOnlineAlert && (
				<div className="netconnect">
					<article className="msg grn">
						<div className="d-flex aIc">
							<div>
								<Icon.Wifi />
							</div>
							<article className="flex-grow-1">{t("Your device is connected to the internet.")}</article>
							<div>
								<Icon.Check style={{ color: "#32323285" }} />
							</div>
						</div>
					</article>
				</div>
			)}
			<header className="adminhead d-flex justify-content-between align-items-center noprint">
				<section className="lftpnl">
					<div className="d-inline-block hldrop">
						<Dropdown>
							<Dropdown.Toggle variant="link" id="hddrop" title={t("Quick create")}>
								<Icon.PlusCircleFill />
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<div style={{ padding: "0 12px" }}>
									<Row xs={1} sm={2} lg={4}>
										{(true === salePermission?.settings_users ||
											true === itemPermission?.items_create ||
											settingPermission?.settings_update_organization_profile ||
											settingPermission?.settings_taxes) && (
											<Col>
												<h6>
													<FontAwesomeIcon icon={faThLarge} /> {t("General")}
												</h6>
												<ul>
													{true === settingPermission?.settings_users && (
														<li>
															<Link to={`/${language}/settings/user_role/add`}>
																<Icon.Plus /> {t("User")}
															</Link>
														</li>
													)}
													{true === itemPermission?.items_create && (
														<li>
															<Link to={`/${language}/items/add`}>
																<Icon.Plus /> {t("Item")}
															</Link>
														</li>
													)}
													{true === itemPermission?.items_create && (
														<li>
															<Link to={`/${language}/settings/item-categories/add`}>
																<Icon.Plus /> {t("Item category")}
															</Link>
														</li>
													)}
													{true === itemPermission?.items_create && (
														<li>
															<Link to={`/${language}/organizations/units/add`}>
																<Icon.Plus /> {t("Unit")}
															</Link>
														</li>
													)}
													{true === settingPermission?.settings_update_organization_profile && (
														<li>
															<Link to={`/${language}/organizations/payment_modes/add`}>
																<Icon.Plus /> {t("Payment mode")}
															</Link>
														</li>
													)}
													{true === settingPermission?.settings_taxes && (
														<li>
															<Link to={`/${language}/organizations/taxes/add`}>
																<Icon.Plus /> {t("Tax")}
															</Link>
														</li>
													)}
												</ul>
											</Col>
										)}
										{(true === salePermission?.customers_create ||
											true === salePermission?.invoices_create ||
											true === salePermission?.proforma_invoices_create ||
											true === salePermission?.credits_notes_create ||
											true === salePermission?.collections_create ||
											true === salePermission?.sales_orders_create) && (
											<Col>
												<h6>
													<FontAwesomeIcon icon={faShoppingBasket} /> {t("Sales")}
												</h6>

												<ul>
													{true === salePermission?.customers_create && (
														<li>
															<Link to={`/${language}/customers/add`}>
																<Icon.Plus /> {t("Customer")}
															</Link>
														</li>
													)}

													{true === salePermission?.proforma_invoices_create && (
														<li>
															<Link to={`/${language}/proforma_invoices/add`}>
																<Icon.Plus /> {t("Proforma invoice")}
															</Link>
														</li>
													)}
													{true === salePermission?.invoices_create && (
														<li>
															<Link to={`/${language}/invoices/add`}>
																<Icon.Plus /> {t("Invoice")}
															</Link>
														</li>
													)}
													{true === salePermission?.credit_notes_create && (
														<li>
															<Link to={`/${language}/credit-notes/add`}>
																<Icon.Plus /> {t("Credit note")}
															</Link>
														</li>
													)}
													{true === salePermission?.collections_create && (
														<li>
															<Link to={`/${language}/collections/add`}>
																<Icon.Plus /> {t("Receipt")}
															</Link>
														</li>
													)}
													{true === salePermission?.sales_orders_create && (
														<li>
															<Link to={`/${language}/salesorders/add`}>
																<Icon.Plus /> {t("Sales orders")}
															</Link>
														</li>
													)}
													{true === salePermission?.invoices_create && (
														<li>
															<Link to={`/${language}/recurring-invoices/add`}>
																<Icon.Plus /> {t("Recurring invoices")}
															</Link>
														</li>
													)}
												</ul>
											</Col>
										)}
										{(true === purchasePermission?.vendors_create ||
											true === purchasePermission?.bills_create ||
											true === purchasePermission?.vendor_credits_create ||
											true === purchasePermission?.payments_create ||
											true === purchasePermission?.expenses_create ||
											true === purchasePermission?.purchase_orders_create) && (
											<Col>
												<h6>
													<FontAwesomeIcon icon={faShoppingBag} /> {t("Purchases")}
												</h6>

												<ul>
													{true === purchasePermission?.vendors_create && (
														<li>
															<Link to={`/${language}/vendors/add`}>
																<Icon.Plus /> {t("Vendor")}
															</Link>
														</li>
													)}
													{true === purchasePermission?.bills_create && (
														<li>
															<Link to={`/${language}/bills/add`}>
																<Icon.Plus /> {t("Bill")}
															</Link>
														</li>
													)}
													{true === purchasePermission?.bills_create && (
														<li>
															<Link to={`/${language}/recurring-bills/add`}>
																<Icon.Plus /> {t("Recurring bills")}
															</Link>
														</li>
													)}
													{true === purchasePermission?.vendor_credits_create && (
														<li>
															<Link to={`/${language}/vendorcredits/add`}>
																<Icon.Plus /> {t("Vendor credit")}
															</Link>
														</li>
													)}
													{true === purchasePermission?.payments_create && (
														<li>
															<Link to={`/${language}/payments/add`}>
																<Icon.Plus /> {t("Payment")}
															</Link>
														</li>
													)}
													{true === purchasePermission?.expenses_create && (
														<li>
															<Link to={`/${language}/expenses/add`}>
																<Icon.Plus /> {t("Expenses")}
															</Link>
														</li>
													)}
													{true === purchasePermission?.purchase_orders_create && (
														<li>
															<Link to={`/${language}/purchaseorders/add`}>
																<Icon.Plus /> {t("Purchase orders")}
															</Link>
														</li>
													)}
													{/* {true === purchasePermission?.expenses_create && (
														<li>
															<Link to={`/${language}/recurring-expenses/add`}>
																<Icon.Plus /> {t("Recurring expenses")}
															</Link>
														</li>
													)} */}
													
												</ul>
											</Col>
										)}
										{(true === settingPermission?.settings_access_all_branches || true === settingPermission?.settings_payment_terms) && (
											<Col>
												<h6>
													<FontAwesomeIcon icon={faCog} /> {t("Settings")}
												</h6>
												<ul>
													{true === settingPermission?.settings_access_all_branches && (
														<li>
															<Link to={`/${language}/settings/branches/add`}>
																<Icon.Plus /> {t("Branch")}
															</Link>
														</li>
													)}
													{true === settingPermission?.settings_access_all_branches && (
														<li>
															<Link to={`/${language}/organizations/transaction_series/add`}>
																<Icon.Plus /> {t("Transaction series")}
															</Link>
														</li>
													)}
													{true === settingPermission?.settings_payment_terms && (
														<li>
															<Link to={`/${language}/organizations/payment_due_terms/add`}>
																<Icon.Plus /> {t("Payment terms")}
															</Link>
														</li>
													)}
													{true === salePermission?.invoices_create && (
														<li>
															<Link to={`/${language}/settings/salesperson/add`}>
																<Icon.Plus /> {t("Sales person")}
															</Link>
														</li>
													)}
													<li>
														<Link to={`/${language}/organizations/banks/add`}>
															<Icon.Plus /> {t("Bank")}
														</Link>
													</li>
												</ul>
											</Col>
										)}
									</Row>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</div>
					{/* add by @asif */}
					{userInfo?.default_organization_id?._id && <CommonSearch t={t} />}
					{/* <CommonSearch t={t} /> */}
				</section>
				{/* {isNotEmpty(SHOW_MAINTENANCE_MESSAGE) && (
					<section className="undrmntn2">
						<div>{SHOW_MAINTENANCE_MESSAGE}</div>
					</section>
				)} */}
				<section className="rgtpnl">
					<div className="langswitch position-relative d-inline-block">
						<a>
							<img src={language === "en" ? Enflag : Arflag} alt={language === "en" ? "English" : "Arabic"} />{" "}
							<span> {language === "en" ? "EN" : "AR"}</span>
						</a>
						<div className="drop">
							<button onClick={(e) => changeLanguage(language === "en" ? "ar" : "en")}>
								<span>
									<img src={language === "en" ? Arflag : Enflag} alt={language === "en" ? "Arabic" : "English"} />
								</span>{" "}
								{language === "en" ? t("Arabic") : t("English")}
							</button>
						</div>
					</div>
					{/* <div className="d-inline-block mn">
						<button
							className="p-0 lh-1 btnhvr2"
							style={{
								border: 'none',
								backgroundColor: 'transparent',
								fontSize: '1.15rem',
							}}
							onClick={notificDropdownShow}
							title={t('Notification')}
						>
							<Icon.Bell />
						</button>
						<div className={`notificdrop${notificshow ? ' show' : ''}`}>
							<button className="close" onClick={() => setnotificShow(false)}>
								<Icon.XLg />
							</button>
							<div className="d-flex flex-column h-100">
								<header className="head">
									<Row xs="auto" className="g-2">
										<Col className="active">
											<Button variant="link">{t('Notifications')}</Button>
										</Col>
										<Col>
											<Button variant="link">{t('Announcements')}</Button>
										</Col>
									</Row>
								</header>
								<main className="cont">
									<section className="notilst">
										<table>
											<tbody>
												<tr>
													<td className="icn">
														<Icon.Megaphone />
													</td>
													<td className="txt pl-0">
														<p>The timer started for the task first stage for Project test has reached the maximum limit for today (24:00).</p>
														<div className="date">17 Feb 2022 04:05 PM</div>
													</td>
												</tr>
											</tbody>
										</table>
									</section>
									<section className="notilst">
										<table>
											<tbody>
												<tr>
													<td className="icn">
														<Icon.Eye />
													</td>
													<td className="txt pl-0">
														<p>The timer started for the task first stage for Project test has reached the maximum limit for today (24:00).</p>
														<div className="date">17 Feb 2022 04:05 PM</div>
													</td>
												</tr>
											</tbody>
										</table>
									</section>
									<section className="notilst">
										<table>
											<tbody>
												<tr>
													<td className="icn">
														<Icon.Megaphone />
													</td>
													<td className="txt pl-0">
														<p>The timer started for the task first stage for Project test has reached the maximum limit for today (24:00).</p>
														<div className="date">17 Feb 2022 04:05 PM</div>
													</td>
												</tr>
											</tbody>
										</table>
									</section>
									<section className="notilst">
										<table>
											<tbody>
												<tr>
													<td className="icn">
														<Icon.StarFill />
													</td>
													<td className="txt pl-0">
														<p>The timer started for the task first stage for Project test has reached the maximum limit for today (24:00).</p>
														<div className="date">17 Feb 2022 04:05 PM</div>
													</td>
												</tr>
											</tbody>
										</table>
									</section>
									<section className="notilst">
										<table>
											<tbody>
												<tr>
													<td className="icn">
														<Icon.Chat />
													</td>
													<td className="txt pl-0">
														<p>The timer started for the task first stage for Project test has reached the maximum limit for today (24:00).</p>
														<div className="date">17 Feb 2022 04:05 PM</div>
													</td>
												</tr>
											</tbody>
										</table>
									</section>
								</main>
							</div>
						</div>
					</div> */}
					<div className="d-inline-block mn sting">
						<Link to={`/${language}/help`} title={t("Help")} className="txt-drk btnhvr2 d-block" style={{ fontSize: "1.15rem" }}>
							<Icon.QuestionCircle />
						</Link>
					</div>
					<div className="d-inline-block mn sting" ref={settingIconRef}>
						<button
							className="p-0 lh-1 btnhvr2"
							style={{ border: "none", backgroundColor: "transparent", fontSize: "1.15rem" }}
							onClick={settingDropdownShow}
							id="stngtooltip"
							title={t("Settings")}
						>
							<Icon.Gear />
						</button>
						<div ref={settingRef} className={`settingdrop${stingshow ? " show" : ""}`}>
							<div className="d-flex h-100 flex-column">
								<button className="close" onClick={() => setStingShow(false)}>
									<Icon.XLg />
								</button>
								<header className="txt-thm2">
									<h5 className="p-3 mb-0 fw-7">{t("Settings")}</h5>
								</header>
								<div className="menuarea d-flex thinscrl">
									<section className="pt-3">
										<SidebarSettingsMenu />
									</section>
								</div>
							</div>
						</div>
					</div>
					<div className="d-inline-block mn orgname" ref={orgnameRef}>
						<button className="cmpbtn pr-0" onClick={userDetailsDropdownShow} style={{ border: "none", backgroundColor: "transparent" }}>
							<span style={{ lineHeight: "1.2" }}>{userInfo?.default_organization_id?.organization_name?.[language]}</span>
						</button>
					</div>
					<div className="d-inline-block mn">
						{/*  https://stackoverflow.com/questions/63465362/reactjs-onclick-add-or-remove-class-to-another-element
						remove class click from another component https://www.youtube.com/watch?v=Hl7o4tJQzPs&t=415s&ab_channel=CodingDeft
						<button className="add" onClick={() => setShow(true)}>Add</button>
						<button className="remove" onClick={() => setShow(false)}>Remove</button>
						<button className="toggle" onClick={() => setShow(!show)}>Toggle</button>
					 */}
						<div className={`complst${show ? " show" : ""}`} ref={userRef}>
							<button className="cmpbtn pr-0" onClick={userDetailsDropdownShow}>
								<span>{userFullName}</span> <Icon.ChevronDown />
							</button>
							<div ref={wrapperRef} className="clntprfdrp">
								<div className="d-flex">
									<section className="prof d-flex p-3 pb-0">
										<button className="close" onClick={() => setShow(false)}>
											<Icon.XLg />
										</button>
										{/* RightSideProfile from ./Setting/my-account/RightSideProfile */}
										<RightSideProfile onClose={() => setShow(false)} />
									</section>

									<div className="headn d-flex p-3">
										<h6 className="text-uppercase font-weight-normal mb-0 txt-thm1">{t("My organizations")}</h6>
										{true === settingPermission?.settings_update_organization_profile && (
											<Link to={`/${language}/organizations`} onClick={() => setShow(!show)}>
												<Icon.Gear />
											</Link>
										)}
									</div>

									{/* OrganizationList from ../organization */}
									<section className="listarea d-flex contsbox pt-0">
										<Scrollbars autoHeight autoHeightMin={100} autoHeightMax={600} autoHide className="cstscrlbar">
											{organizations &&
												organizations.map((organization, index) => (
													<div
														key={index}
														className={userInfo?.default_organization_id?._id === organization?.organization_id?._id ? "lst active" : "lst"}
													>
														<Button
															variant="link w-100"
															onClick={() => {
																!(userInfo?.default_organization_id?._id === organization?.organization_id?._id) &&
																	switchOrganization(
																		organization?.organization_id?._id,
																		organization?.organization_id?.organization_name?.[language]
																	);
															}}
														>
															<OrganizationDetails organization={organization} />
														</Button>
													</div>
												))}
										</Scrollbars>
									</section>
								</div>
							</div>
						</div>
					</div>
					{/* <div className='d-inline-block mn'>
						<Dropdown>
							<Dropdown.Toggle id='dropdown-basic'>
								<Icon.PersonFill />
							</Dropdown.Toggle>
							<Dropdown.Menu align={'end'}>
								<Dropdown.Item href='#/action-1' to='/'>
									<Icon.PersonBadge /> Profile
								</Dropdown.Item>
								<Dropdown.Item href='#/admin/settings/accounts'>
									<Icon.Gear /> Setting
								</Dropdown.Item>
								<Dropdown.Item href='#/action-3' to='/'>
									<Icon.QuestionCircle /> Help
								</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item href='#/logout'>
									<Icon.BoxArrowLeft /> Logout
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				 */}
				</section>
			</header>

			<InvitationsListModal />
		</>
	);
};
export default InnerHeader;

//list invitations send by organizations, accept or reject from here
const InvitationsListModal = () => {
	const [orgprmsshow, setOrgprmsShow] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		const loadInvitations = async () => {
			try {
				let invitations = await getInvitations();
				if (Array.isArray(invitations) && invitations.length > 0) {
					setOrgprmsShow(invitations);
				}
			} catch (error) {}
		};
		loadInvitations();
	}, []);

	const handleOnInvitationFound = (status) => {
		setOrgprmsShow(status);
	};

	return (
		<>
			{
				<Modal show={orgprmsshow} backdrop="static" onHide={() => setOrgprmsShow(false)} size="lg">
					<Modal.Header closeButton>
						<Modal.Title>{t("Pending invitations")}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<InvitationsList onInvitationsLoad={handleOnInvitationFound} />
					</Modal.Body>
				</Modal>
			}
		</>
	);
};

const InvitationsList = ({ onInvitationsLoad, onOneAccept, onAllReject, getToken }) => {
	const { language } = useSelector((state) => state.auth);
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [invitationIndex, setInvtIndexs] = useState([]); // whenever user accpet or reject only show loading for that rows' accpet and reject button
	const { t } = useTranslation();
	const [invitations, setInvitations] = useState([]);

	//show the modal if any invitations are found
	useEffect(() => {
		const loadInvitations = async () => {
			try {
				let invitations = await getInvitations();
				if (Array.isArray(invitations) && invitations.length > 0) {
					setInvitations(invitations);
					setInvtIndexs(Array.from({ length: invitations.length }, (_, i) => false));
					onInvitationsLoad?.(true);
				}
				if (invitations.length === 0) {
					onInvitationsLoad?.(false);
				}
			} catch (error) {}
		};
		loadInvitations();
	}, []);

	//accept or reject am invitation, if accpet or rjected then show an information implace of buttions
	const handleAcceptOrRejectInvitation = async (action_type, invitation_id, index) => {
		try {
			let temp_invt_index = [...invitationIndex];
			temp_invt_index[index] = true;
			setInvtIndexs([...temp_invt_index]);
			setIsButtonLoading(true);

			let invitaion_status_message = await patchInvitation(action_type, invitation_id, getToken);

			if (invitaion_status_message.invitation_status === "accept" && getToken === true) {
				onOneAccept?.({
					token: invitaion_status_message.token,
					user_info: invitaion_status_message.user_info,
				});
			}

			if (invitaion_status_message.invitation_status === "reject") {
				onAllInvitationReject();
			}

			const temp_invitations = [...invitations];
			let patched_invitation_index = [...invitations].findIndex((invt) => invt.invitation_id === invitation_id);
			temp_invitations[patched_invitation_index].message = invitaion_status_message.message;
			temp_invitations[patched_invitation_index].invitation_status = invitaion_status_message.invitation_status;
			temp_invitations[patched_invitation_index].pending = false;
			setInvitations(temp_invitations);

			setIsButtonLoading(false);
		} catch (error) {
			setIsButtonLoading(false);
		}
	};

	//on all invitation reject send a call back to parent to notify
	const onAllInvitationReject = useCallback(() => {
		let all_rejected = invitations.filter((invt) => invt.invitation_status === "reject").length + 1 === invitations.length;
		if (all_rejected) {
			onAllReject?.(true);
		}
	}, [invitations, onAllReject]);

	return (
		<>
			{invitations.map((invt, index) => (
				<div className="orgprmslst">
					<div key={`invt${index}`} className="d-flex text-left">
						<div className="flex-grow-1">
							<p>
								<strong>{invt?.organization_details?.organization_name?.[language]}</strong> {t("sent you an invitation to join their organization")}
							</p>
							<small>
								{invt?.role_details?.role_name?.[language]}, {invt?.role_details?.description}
							</small>
						</div>
						{invt?.pending === false ? (
							<aside>{invt.message}</aside>
						) : (
							<aside>
								<Button
									variant={`primary thm2 ${isButtonLoading && invitationIndex[index] && "loading"}`}
									size="sm"
									onClick={() => handleAcceptOrRejectInvitation("accept", invt?.invitation_id, index)}
								>
									{t("Accept")}
								</Button>{" "}
								<Button
									variant={`secondary ${isButtonLoading && invitationIndex[index] && "loading"}`}
									size="sm"
									onClick={() => handleAcceptOrRejectInvitation("reject", invt?.invitation_id, index)}
								>
									{t("Reject")}
								</Button>
							</aside>
						)}
					</div>
				</div>
			))}
			{invitations.length === 0 && (
				<div className="noinv mx-auto" dir="ltr">
					<span className="icn">
						<FontAwesomeIcon icon={faEnvelopeOpenText} />
					</span>{" "}
					<p className="mb-1 mt-3">{t("No invitations found")}</p>
				</div>
			)}
		</>
	);
};

export { InvitationsList };
