import { BroadcastChannel } from 'broadcast-channel';

const logoutChannel = new BroadcastChannel('logout');
const loginChannel = new BroadcastChannel('login');

//login all the tab when user login one tab
export const loginAll = () => {
	loginChannel.postMessage('Login');
	let language = localStorage.getItem('language');
	//for redirect url if user not login
	// const redirect_location = window.location?.state?.referrer;
	// const finalUrl = redirect_location ? `/${language}/${redirect_location}` : `/${language}/dashboard`;
	window.location.href = window.location.origin + '/' + language + '/dashboard';
};

export const loginAllTabs = () => {
	loginChannel.onmessage = () => {
		loginAll();
		loginChannel.close();
	};
};
//logout all the tab when user logout one tab
export const logout = () => {
	logoutChannel.postMessage('Logout');
	localStorage.removeItem('token');
	let language = localStorage.getItem('language');
	window.location.href = window.location.origin + '/' + language + '/logout';
};

export const logoutAllTabs = () => {
	logoutChannel.onmessage = () => {
		logout();
		logoutChannel.close();
	};
};
