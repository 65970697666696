import { secureAPI } from './API';
import { checkTokenValidate } from '../util/helper';
/*
Collection service api caller
*/
async function createCollection(payment_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post('/collections', payment_payload)
			.then((data) => {
				if (data.data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function getAllCollections(fillter = '', selectElement = '') {
	let url = '/collections?status=A' + fillter;
	if (selectElement) {
		url = `${url}&select=${selectElement}`;
	}

	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function getACollection(payment_id, content_type = '') {
	content_type = content_type ? `&content_type=${content_type}` : '';
	let language = localStorage.getItem('language');

	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/collections/${payment_id}?language=${language}${content_type}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function deleteACollection(payment_id, payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/collections/${payment_id}`, { data: payload })
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function updateACollection(payment_id, payment_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/collections/${payment_id}`, payment_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
//Patch   used n popup   apply payment in invoices
async function creditApply(payment_id, payment_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/collections/${payment_id}/apply_on_invoices`, payment_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function deleteACollectionAtInvoice(payment_id, payment_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/collections/${payment_id}/delete_from_invoice`, payment_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

// get all summary amount for invoice
async function patchCollection(payment_id, payment_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/collections/${payment_id}/attachment`, payment_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
//patchCollectionTemplate used to update the pdf_template
async function patchCollectionTemplate(payment_id, payment_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/collections/${payment_id}/template`, payment_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}


// fetch collection template with data
async function 	getCollectionTemplateWithData(invoice_id) {  
	return new Promise((resolve, reject) => {
	  secureAPI()
		.get(`/collections/${invoice_id}/email`)
		.then(({ data }) => {
		  if (data.success) {
			resolve(data.data); //return array
		  }
		})
		.catch((err) => {
		  checkTokenValidate(err);
		  reject(err.response.data.error);
		});
	});
  }

//create collection refund
async function createRefundACollection(collection_id, refund_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`/collections/${collection_id}/refunds`, refund_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//get single collection refund
async function getARefundFromCollection(collection_id,refund_id){
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/collections/${collection_id}/refunds/${refund_id}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
} 

//edit single collection refund

async function updateRefundsToCollection(collection_id,refund_id,refund_payload){

	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/collections/${collection_id}/refunds/${refund_id}`, refund_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//delete collection refund
async function deleteRefundsFromCollection(collection_id,refund_id){
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/collections/${collection_id}/refunds/${refund_id}`,{})
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
} 
export {
	createCollection,
	getACollection,
	getAllCollections,
	updateACollection,
	deleteACollectionAtInvoice,
	deleteACollection,
	creditApply,
	patchCollection,
	patchCollectionTemplate,
	getCollectionTemplateWithData,
	createRefundACollection,
	deleteRefundsFromCollection,
	getARefundFromCollection,
	updateRefundsToCollection,
};
