import { secureAPI } from './API';
import { checkTokenValidate } from '../util/helper';

async function validatetransactionnumber(transaction_number, contact_id, transaction_id) {
	let query_strings=[]
	if(transaction_number){
		query_strings.push(`transaction_number=${transaction_number}`)
	}
	if(contact_id){
		query_strings.push(`contact_id=${contact_id}`)
	}
	if(transaction_id){
		query_strings.push(`transaction_id=${transaction_id}`)
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/bills/validatetransactionnumber?${query_strings.join("&")}`)
			.then((data) => {
				if (data.data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err?.response?.data);
			});
	});
}

async function createBill(transaction_payload) {

	const formData = new FormData();
	// add transaction_payload to form data
	if (transaction_payload && Object.keys(transaction_payload).length > 0) {
		formData.append('json_string', JSON.stringify({ ...transaction_payload }));
	}

	return new Promise((resolve, reject) => {
		secureAPI()
			.post('/bills', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((data) => {
				if (data.data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err?.response?.data);
			});
	});
}

async function getAllBills(searchParam = '', selectElement = '') {
	//fillter
	let url = '/bills?status=A' + searchParam;
	if (selectElement) {
		url = `${url}&select=${selectElement}`;
	}

	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function getABill(transaction_id, content_type = '') {
	content_type = content_type ? `?content_type=${content_type}` : '';
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/bills/${transaction_id}${content_type}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function deleteABill(transaction_id, contact_id, commentPayload={}) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/bills/${transaction_id}?contact_id=${contact_id}`,{data:commentPayload})
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function updateABill(transaction_id, transaction_payload) {
	const formData = new FormData();
	// add transaction_payload to form data
	if (transaction_payload && Object.keys(transaction_payload).length > 0) {
		formData.append('json_string', JSON.stringify({ ...transaction_payload }));
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/bills/${transaction_id}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err?.response?.data?.error);
			});
	});
}
async function getAppliedTransactisonOnBill(transaction_id, contact_id, transaction_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/bills/${transaction_id}/getappliedtransactions?contact_id=${contact_id}`, transaction_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err?.response?.data?.error);
			});
	});
}
async function applyCreditsOnBill(transaction_id, contact_id, transaction_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/bills/${transaction_id}/apply_credits_on_single_bill?contact_id=${contact_id}`, transaction_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err?.response?.data?.error);
			});
	});
}

// patch a single bill, for update only specific fields
async function patchBill(transaction_id, transaction_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/bills/${transaction_id}`, transaction_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err?.response?.data?.error);
			});
	});
}

// get all summary amount for bill
async function getBillSummaryAmount() {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/bills/get_bill_summary`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}


//update bill balance from bills details page
async function updateBillBalance(bill_id) {
	return new Promise((resolve, reject) => {
	  secureAPI()
		.patch(`/bills/${bill_id}/updatebillbalance`,)
		.then((data) => {
		  if (data.data.success) {
			resolve(data.data);
		  }
		})
		.catch((err) => {
		  checkTokenValidate(err);
		  reject(err?.response?.data);
		});
	});
  }


// get details of all purchase order that are associated with this bill.
async function getAssociatedPurchaseOrdersOfBill(bill_id) {
	return new Promise((resolve, reject) => {
	  secureAPI()
		.get(`/bills/${bill_id}/purchase_orders`)
		.then(({ data }) => {
		  if (data.success) {
			resolve(data);
		  }
		})
		.catch((err) => {
		  checkTokenValidate(err);
		  reject(err?.response?.data?.error);
		});
	});
  }

  async function linkPurchaseOrderWithBill(bill_id) {
	return new Promise((resolve, reject) => {
	  secureAPI()
		.put(`/bills/${bill_id}/purchase_orders/link_purchase_order`,)
		.then(({data}) => {
		  if (data.success) {
			resolve(data);
		  }
		})
		.catch((err) => {
		  checkTokenValidate(err);
		  reject(err?.response?.data.error);
		});
	});
  }
  

export {
	createBill,
	updateABill,
	getAllBills,
	getABill,
	deleteABill,
	patchBill,
	validatetransactionnumber,
	getAppliedTransactisonOnBill,
	applyCreditsOnBill,
	getBillSummaryAmount,
	updateBillBalance,
	getAssociatedPurchaseOrdersOfBill,
	linkPurchaseOrderWithBill
};
