import React from "react";
import { PhoneInput, defaultCountries, parseCountry } from "react-international-phone";
import "react-international-phone/style.css";

const CommonPhoneInput = ({ value, onChange, register, fieldName, disabled = false }) => {
	const countries = defaultCountries.filter((country) => {
		const { iso2 } = parseCountry(country);
		return ["sa"].includes(iso2); //, "in"
	});
	return (
		<PhoneInput
			defaultCountry="sa"
			value={value}
			countries={countries}
			{...(register ? register(fieldName) : {})}
			onChange={onChange}
			className="intrphn"
			forceDialCodedisabled={disabled}
		/>
	);
};

export default CommonPhoneInput;
