import { Badge } from "reactstrap";
import React from "react";
import { dayCalculate } from '../../../util/numberPad';
import moment from "moment";

const RecurringTransactionStatusBadge = ({ transactionStatus, t }) => {
	const transaction_status = transactionStatus;
	if (transaction_status === "active") {
		return <Badge color="success">{t("Active")}</Badge>;
	} else if (transaction_status === "stopped") {
		return <Badge color="danger">{t("Stopped")}</Badge>;
	} else {
		return <Badge color="warning">{t("Pending")}</Badge>;
	}
};

const RecurringFrequencyFormatted = ({ t, frequency,repeat_frequency }) => {
	  
	let returned_text = 'Yearly';
	// for days
	if(frequency === 'days' && repeat_frequency === 1 ){
		returned_text = t('Daily');
	}else if(frequency === 'days' && repeat_frequency > 1){
		returned_text = t(`Every ${repeat_frequency} Days`);
	}

	// for weeks
	if(frequency === 'weeks' && repeat_frequency === 1 ){
		returned_text = t('Weekly');
	}else if(frequency === 'weeks' && repeat_frequency > 1){
		returned_text = t(`Every ${repeat_frequency} Weeks`);
	}

	// for months
	if(frequency === 'months' && repeat_frequency === 1 ){
		returned_text = t('Monthly');
	}else if(frequency === 'months' && repeat_frequency > 1 ){
		returned_text = t(`Every ${repeat_frequency} Months`);
	}

	// for years
	if(frequency === 'years' && repeat_frequency === 1 ){
		returned_text = t('Yearly');
	}else if(frequency === 'years' && repeat_frequency > 1){
		returned_text = t(`Every ${repeat_frequency} Years`);
	}

	return returned_text;
};

const ChildInvoiceStatusFormatted = ({transactionStatus, paymentStatus,t})=>{
  
  const transaction_status = transactionStatus;
  const payment_status = paymentStatus;
	if (transaction_status === "DR"  && payment_status==='NP') {
		return <Badge color="primary">{t("Draft")}</Badge>;

	} else if (transaction_status === "ST" && payment_status==='NP') {		
		return <Badge color="warning">{t("Sent")}</Badge>;

	}else if(transaction_status === "ST" && payment_status==='FP'){
		return <Badge color="success">{t("Paid")}</Badge>;

	}else if(transaction_status === "ST" && payment_status==='PP'){
		return <Badge color="info">{t("Partially paid")}</Badge>;

	} else {
		// todo : calculate overdue period(over due date)
		return <Badge color="danger">{t("Overdue")}</Badge>;
	}
}

const ChildBillStatusFormatted = ({transactionStatus, paymentStatus,dueDate,t})=>{
	const transaction_status = transactionStatus;
  	const payment_status = paymentStatus;
	const due_date = dueDate;
	const currentDate = moment().format('YYYY-MM-DD'); //current date

	if(transaction_status === 'DR'){
		return <Badge color="warning">{t('Draft')}</Badge>
	}else if(transaction_status === 'V'){
		return <Badge color="secondary">{t('Void')}</Badge>;
	}else if(payment_status === 'FP'){
		return  <Badge color="success">{t('Paid')}</Badge>
		
	}else if(payment_status !== 'FP' && transaction_status === 'OP' && moment(currentDate) > moment(due_date)){
		return <Badge color="danger">{t('Overdue by')} {dayCalculate(due_date, t)}</Badge>
	}else {
		return <Badge color="primary">{t('Open')}</Badge>
	}
}

const ChildJournalStatusBadge =({transactionStatus,t})=>{
	
	const transaction_status = transactionStatus;
	if (transaction_status === "DR") {
		return <Badge color="warning">{t("Draft")}</Badge>;
	}else {
		return <Badge color="success">{t("Published")}</Badge>;
	}

}

export { RecurringTransactionStatusBadge, RecurringFrequencyFormatted,ChildInvoiceStatusFormatted,ChildBillStatusFormatted,ChildJournalStatusBadge };
