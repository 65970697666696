//Search various collection based on keywords
//on some predifined fields
//items -  item name in english, item nam in arabic

import { secureAPI } from './API';
import { checkTokenValidate } from '../util/helper';

/**
 * get result when doing fuzzy (not exact) search
 * TODO: MAKE THIS FUNCTION COMMON TO TAKE FUZZY AND EXACT SERACH
 * @param {String} search_model search this collection - (item, invoice, collection)
 * @param {String} search_value value to be searched
 * @param {String} select_fields optional fields that will be returned, if null/empty then all fields
 */
async function getFuzzySearchResult(search_model, search_value, select_fields) {
  let search_query = `model=${search_model}&search_value=${search_value}`;
  if (select_fields) {
    search_query = search_query + `&select=${select_fields}`;
  }
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/search?${search_query}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

export { getFuzzySearchResult };
