import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeParentChildAccount, generateParentAccountOption } from '../../components/accounting/chartOfAccounts/chart_of_account_tree';
import { userLanguage } from '../../util/helper';
import '../../translations/i18n';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const AccountsDropdown = ({
	search_model,
	language,
	field_name, // this is the name of the field in the form
	parentAccountCallback,
	selected_account_id = '', //account id which must be selected after search
	parentAccountIdForList = '', //Pass the list of account's parent id
	// defaultSelectedAccount = '', //this is the default selected account for first time
	exceptAccountIds = '', //this is the list of account ids which must be excluded from search
}) => {

	const { t } = useTranslation();
	const fieldLanguage = userLanguage(language);
	const [accountOptions, setAccountOptions] = useState('');
	const chart_of_account_all = useSelector((state) => state.auth.userInfo.chart_of_account);
	const { level1_account_groups: MAIN_ACCOUNT_GROUP } = useSelector((state) => state.auth.userInfo.account_settings ?? {});
	const [accountName, setAccountName] = useState(selected_account_id);

	const onSelectChange = (account) => {
		let selectedObject = {
			value: account?.value || "",
			label: account?.label ? account?.label?.replace(/[•\t.+]/g, " ").trim() : account?.label,
		};
		setAccountName(selectedObject);
		parentAccountCallback(selectedObject); //label value object
	};

	useEffect(() => {
		getAllAccountType();
	}, [search_model]);

	const getAllAccountType = async () => {
		try {
			let rootParentAccountId = [];
			let chartOfFilteredAccounts = chart_of_account_all; //default set all accounts
			//if remove account ids found then filter the accounts and reassign the chartOfFilteredAccounts
			if (exceptAccountIds) {
				//filter the object with except account ids from all account
				chartOfFilteredAccounts = chart_of_account_all?.filter(function (value, index) {
					return exceptAccountIds.indexOf(value?._id) == -1;
				});
			}

			if (parentAccountIdForList) {
				//if parentAccountIdForList is not empty
				rootParentAccountId = parentAccountIdForList;
			} else {
				//if parentAccountIdForList is empty then list all the account/
				rootParentAccountId = MAIN_ACCOUNT_GROUP && Object.values(MAIN_ACCOUNT_GROUP); //level1 group object from redux
				// rootParentAccountId = [
				// 	'624c1657734cf417f61ab695',
				// 	'624c1657734cf417f61ab699',
				// 	'624c1657734cf417f61ab693',
				// 	'624c1657734cf417f61ab691',
				// 	'624c1657734cf417f61ab697',
				// 	'624c1657734cf417f61ab69b',
				// 	'624c1657734cf417f61ab69d',
				// ];
			}
			let accountOptions = []; //'<option value="">' + t('Select account') + '</option>';
			rootParentAccountId?.forEach(async (parentId, i) => {
				//make a tree structure ??
				let accountsTree = await makeParentChildAccount(chartOfFilteredAccounts, parentId, language);
				//built out data for drop down ??
				let optionsPurchaseData = generateParentAccountOption(accountsTree, language, true);
				accountOptions = [...accountOptions, ...optionsPurchaseData];
				if (rootParentAccountId?.length === i + 1) {
					setAccountOptions(accountOptions);
				}
			});
		} catch (err) {
			//setLoadingAccount(false);//loading false
			console.error(err);
		}
	};

	const cstyle = {
		control: (base) => ({
			// none of react-select's styles are passed to <Control />
			...base,
			margin: 0,
		}),
	};
	return (
		<>
			<Select
				styles={cstyle}
				isClearable={true}
				className="align-middle"
				placeholder={t('Select account')}
				name={field_name}
				value={accountName?.label ? accountName : ''}
				onChange={(account) => {
					onSelectChange(account);
				}}
				options={accountOptions}
				classNamePrefix="account-select"
				noOptionsMessage={() => t('No account found of this name')} //when no result found
			/>
		</>
	);
};

export default AccountsDropdown;
