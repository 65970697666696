import React, { useState, useEffect } from 'react';
import { Col, Form, Button } from 'react-bootstrap';
// import { useParams } from 'react-router-dom';
import '../../translations/i18n';
import { useTranslation } from 'react-i18next';
import { getAllSalesPersons } from '../../services/salesPerson.service';
import AsyncSelect from 'react-select/async';
import * as Icon from 'react-bootstrap-icons';
import { showAlert } from '../../util/alertModal';
import CommonModal from '../../modal/CommonModal';
import AddSalesPerson from '../../components/settings/sales_person/AddSalesPerson';

const SalesPersonsDropdown = ({ showSalesPersonLabel = true, selectSalesperson, searchPopup = false, parentSalesPersonChooseCallback }) => {
	const { t } = useTranslation();
	// const { id } = useParams();
	const [salesPerson, setSalesPerson] = useState();
	const [salesPersons, setSalesPersons] = useState([]);
	const [showsalp, setsalpShow] = useState(false);
	const salpClose = () => setsalpShow(false);
	const salpShow = () => setsalpShow(true);
	const [timer, setTimer] = useState(null);

	//this function call only edit mode
	useEffect(() => {
		if (selectSalesperson?.value) {
			//if selectSalesperson value is not empty
			setSalesPerson(selectSalesperson);
		} else {
			setSalesPerson(''); //if empty then reset the state
		}
	}, [selectSalesperson]);

	const addNewButtonOnSelectBox = ({ innerRef, innerProps, isDisabled, children }) =>
		!isDisabled ? (
			<div ref={innerRef} {...innerProps} className="customReactSelectMenu">
				{children}
				<Button className="btn btn-block thm2" onClick={salpShow}>
					<Icon.PlusLg /> {t('New sales person')}
				</Button>
			</div>
		) : null;

	//get all sales persons
	const fetchAllSalesPersons = (searchText = '') => {
		return new Promise(async (resolve, reject) => {
			let searchParam = '';
			let selectElement = '_id,name,name_ar,email,phone_no,status';
			clearTimeout(timer); //clear previous timer
			const newTimer = setTimeout(async () => {
				if (searchText.length >= 2) {
					searchParam = '&limit=10';
					selectElement += '&search_field=name,name_ar&search_value=' + searchText;
				}
				try {
					let get_all_salesPerson_res = await getAllSalesPersons(selectElement, searchParam);
					if (get_all_salesPerson_res) {
						setSalesPersons(get_all_salesPerson_res.data);
						let mapped_for_react_select = get_all_salesPerson_res.data?.map((sperson) => ({
							value: sperson?._id,
							label: sperson?.name,
						}));
						resolve(mapped_for_react_select);
					}
				} catch (err) {
					showAlert(t("Some error happened while loading!") + `\n${err}`, true);
				}
			}, 600);
			setTimer(newTimer); //set new timer
		});
	};

	const cstyle = {
		control: (base) => ({
			// none of react-select's styles are passed to <Control />
			...base,
			margin: 0,
		}),
	};
	useEffect(() => {
		fetchAllSalesPersons(); //fetch all sales persons onload
	}, []);

	//add new sales person success event
	const onSalesPersonAddFromModal = (sales_person_data) => {
		setSalesPersons([...salesPersons, sales_person_data]);
		let newAddSalesPerson = {
			label: sales_person_data?.name,
			value: sales_person_data?._id,
		};
		setSalesPerson(newAddSalesPerson);
	};

	//onchange set selected sales person value
	const onSalesPersonSelect = (val) => {
		setSalesPerson(val);
		if (searchPopup) {
			parentSalesPersonChooseCallback(val);
		}
	};

	return (
		<>
			<Col>
				<Form.Group className="frmfld pb-1">
					{showSalesPersonLabel && <Form.Label>{t('Sales person')}</Form.Label>}
					<section>
						<AsyncSelect
							styles={cstyle}
							isClearable={true}
							placeholder={t('Choose sales person') + ' ' + t('or type')}
							components={!searchPopup && { MenuList: addNewButtonOnSelectBox }}
							classNamePrefix="react-select"
							className="align-middle"
							tabIndex="9"
							name="sales_person"
							value={salesPerson || ' '}
							onChange={(val) => {
								onSalesPersonSelect(val);
							}}
							defaultOptions={
								salesPersons?.length > 0 &&
								salesPersons?.map((salePerson) => ({
									label: salePerson?.name,
									value: salePerson?._id,
								}))
							}
							loadOptions={fetchAllSalesPersons}
							loadingMessage={() => t('Searching') + ' ...'}
							noOptionsMessage={() => t('No sales person found of this name')} //when no result found
						/>
						<div className="invalid-feedback"></div>
					</section>
				</Form.Group>
			</Col>
			{!searchPopup && showsalp && (
				<CommonModal
					children={AddSalesPerson}
					title={'New sales person'}
					t={t}
					modalClose={salpClose}
					showModal={showsalp}
					className={'nwsls'}
					onSaveData={onSalesPersonAddFromModal}
				/>
			)}
		</>
	);
};
export default SalesPersonsDropdown;
