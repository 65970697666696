import { unsecureAPI } from './API';

/*
Account service api caller
*/
async function sentContact(contact_payload) {
  return new Promise((resolve, reject) => {
    unsecureAPI()
      .post('/public/contact', contact_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
				reject(err.response.data.error);
			});
  });
}
// News letter submit
async function newsLetter(payload) {
  return new Promise((resolve, reject) => {
      unsecureAPI()
      .post('/public/newsletter', payload)
      .then(({ data }) => {
          if (data.success) {
              resolve(data.data); //return array
          }
      })
      .catch((err) => {
    reject(err?.response?.data?.error);
  });
  });
}
export { sentContact, newsLetter };
