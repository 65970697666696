import React, { useEffect } from "react";
import { isEmpty, showAlertNotification } from "../../util/helper";
import { useSelector } from "react-redux";

/**
 * @typedef AlertMessageOptions
 * @property {boolean} isAutoHide,
 * @property  {( 'success'|'danger'|'warning'|'info')} type
 * @property  {string} message
 * @property  {('show'|'')} show show or hide this alert, pass empty string ('') to hide alert
 * @property  {boolean} isModal
 * @property {("true"|"false")}  isDownload
 */
/**
 *
 * @param {AlertMessageOptions} param0
 * @returns
 */
const AlertMessage = ({ isAutoHide, type, message, show, isModal, isDownload, parentCallback }) => {
	const language = useSelector((state) => state.auth.language);
	isModal = isModal ?? false; // banking modal add payment, collection display error message.
	message = !isEmpty(message[language]) ? message[language] : message;
	useEffect(() => {
		let alert;
		let alert_type;

		switch (type) {
			case "success": {
				alert_type = "success";
				break;
			}
			case "danger": {
				alert_type = "fail";
				break;
			}
			case "warning": {
				alert_type = "warning";
				break;
			}
			case "info": {
				alert_type = "info";
				break;
			}
			default: {
				alert_type = "info";
				break;
			}
		}

		// special case for "isDownload" we ignore the "type" props and set it here manually.
		if (isDownload === "true") {
			alert_type = "progress";
		}

		if (show === "show") {
			const { al } = showAlertNotification(message, alert_type, { timer: 5 }); //isAutoHide ? 4 : 0
			al.callback = () => {
				parentCallback?.(true);
			};
			alert = al;
		}
		return () => {
			if (alert) {
				alert.dismissCurrentNotification();
			}
		};
	}, [show]);

	return <></>;
};
export default AlertMessage;
