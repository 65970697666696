import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import './translations/i18n';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { PREFERRED_LANGUAGE, THEMELIST } from '../src/util/helper';
import { initGA, logPageView } from '../src/util/analytics';
//for English Arabic bootstrap css load
const EnCSS = lazy(() => import('./assets/changeCSS/bootstrapEnCSS'));
const ArCSS = lazy(() => import('./assets/changeCSS/bootstrapArCSS'));

//load css English and Arabic based on language
const CssSelector = ({ children }) => {
	//add class
	// let lang = window.location.pathname.split('/')[1];
	let language = useSelector((state) => state?.auth?.language);
	// let language = lang ? lang : language;
	let classname = language === 'en' ? 'eng' : 'arb';
	document.body.classList.remove('eng', 'arb');
	document.body.classList.add(classname);
	document.documentElement.setAttribute('lang', language);
	//for theme change
	const selectedThemeId = useSelector((state) => state?.auth?.userInfo?.default_organization_id?.theme);
	// get theme from redux OR set default theme
	if (selectedThemeId) {
		let selectedTheme = THEMELIST?.find((theme) => theme?.themeId === selectedThemeId);
		document.body.classList.add(selectedTheme?.themeSlug); //add theme class
	}
	//for preferred language
	const selectedLanguageValue = useSelector((state) => state?.auth?.userInfo?.default_organization_id?.preferred_language);

	//get language from redux OR set default language
	if (selectedLanguageValue) {
		let selectedLanguage = PREFERRED_LANGUAGE?.find((lang) => lang?.langValue === selectedLanguageValue);
		document.body.classList.add(selectedLanguage?.langSlug); //add language class
	}

	return (
		<>
			<Suspense fallback={<></>}>
				{language === 'en' && <EnCSS />}
				{language === 'ar' && <ArCSS />}
			</Suspense>
			{children}
		</>
	);
};
initGA(); //for google analytics initialize
ReactDOM.render(
	<React.Fragment>
		<Provider store={store}>
			<CssSelector>
				<App />
			</CssSelector>
		</Provider>
	</React.Fragment>,
	document.getElementById('root')
);
logPageView(); //for google analytics page view
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
