import { secureAPI } from "../API";
import { checkTokenValidate } from "../../util/helper";

/**
 * @typedef AdjustedItem
 * @property {ObjectId} _id
 * @property {ObjectId} inventory_account_id
 * @property {ObjectId} item_id
 * @property {{en:string, ar:string}} item_name
 * @property {string} description
 * @property {ObjectId} unit_id
 * @property {number} quantity_adjusted
 * @property {number} value_adjusted
 * @property {number} cost_price
 * @property {Array<{tag_id:ObjectId, option_id:ObjectId}>} reporting_tags
 */

/**
 * @typedef AdjustedItemPopulated
 * @extends AdjustedItem
 * @property {*} inventory_account
 * @property {*} item_details
 * @property {*} unit_details
 */

/**
 * @typedef InventoryAdjustment
 * @property {ObjectId} _id
 * @property {ObjectId} organization_id
 * @property {ObjectId} [user_id]
 * @property {ObjectId} [client_app_id]
 * @property {("value"|"quantity")} adjustment_type
 * @property {Date} issue_date
 * @property {ObjectId} adjustment_account_id
 * @property {ObjectId} reason_id
 * @property {{en:string, ar:string}} reason
 * @property {ObjectId} branch_id
 * @property {ObjectId} store_id
 * @property {string} reference_number
 * @property {string} description
 * @property {ObjectId[]} attachments
 * @property {("DR"|"AD")} transaction_status - DR = Draft, AD = Adjusted
 * @property {("A"|"D")} status - A = Active, D = Deleted
 * @property {ObjectId} [created_by_id]
 * @property {ObjectId} [updated_by_id]
 * @property {*} adjustment_account
 * @property {*} reason_details
 * @property {Store} store_details
 * @property {(AdjustedItemPopulated[]|AdjustedItem[])} transaction_items
 * @property {string} renderHtml
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {{first_name:string, last_name:string}} created_by
 * @property {{first_name:string, last_name:string}} updated_by
 */

const BASE_URL = () => `/inventory_adjustments`;

async function createInventoryAdjustment({ adjustment_payload }) {
  const post_url = BASE_URL();
  try {
    const http_response = await secureAPI().post(post_url, adjustment_payload);
    const response_json = http_response.data;
    if (response_json.success) {
      return response_json.data;
    }
  } catch (error) {
    checkTokenValidate(error);
    throw error.response.data.error;
  }
}
async function updateInventoryAdjustment({
  adjustment_id,
  adjustment_payload,
}) {
  const post_url = `${BASE_URL()}/${adjustment_id}`;
  try {
    const http_response = await secureAPI().put(post_url, adjustment_payload);
    const response_json = http_response.data;
    if (response_json.success) {
      return response_json.data;
    }
  } catch (error) {
    checkTokenValidate(error);
    throw error.response.data.error;
  }
}

/**
 * @desc get a single inventory adjustment
 * @param {string} adjustment_id
 * @param {Record<string,(string|number)>} queries
 * @return {Promise<InventoryAdjustment>}
 */
async function getInventoryAdjustment({ adjustment_id }, { queries = {} }) {
  const base_url = `${BASE_URL()}/${adjustment_id}`;
  const params = new URLSearchParams(queries);
  const get_url = `${base_url}?${params.toString()}`;
  try {
    const http_response = await secureAPI().get(get_url);
    const response_json = http_response.data;
    if (response_json.success) {
      return response_json.data;
    }
  } catch (error) {
    checkTokenValidate(error);
    throw error.response.data.error;
  }
}

async function getAllInventoryAdjustments({ queries = {} }) {
  const base_url = BASE_URL();
  const params = new URLSearchParams({...queries,model:"adjustments"});
  const get_url = `${base_url}?${params.toString()}`;
  try {
    const http_response = await secureAPI().get(get_url);
    const response_json = http_response.data;
    if (response_json.success) {
      return response_json;
    }
  } catch (error) {
    checkTokenValidate(error);
    throw error.response.data.error;
  }
}

async function deleteInventoryAdjustment({ adjustment_id }) {
  const post_url = `${BASE_URL()}/${adjustment_id}`;
  try {
    const http_response = await secureAPI().delete(post_url);
    const response_json = http_response.data;
    if (response_json.success) {
      return response_json.data;
    }
  } catch (error) {
    checkTokenValidate(error);
    throw error.response.data.error;
  }
}

export {
  createInventoryAdjustment,
  updateInventoryAdjustment,
  getInventoryAdjustment,
  getAllInventoryAdjustments,
  deleteInventoryAdjustment,
};
