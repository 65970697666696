/* this is make a parent child relation for accounts from flat array */
function toTree(data, pid = undefined) {	
    return data?.reduce((r, e) => {
			if (e?.parent_account?._id == pid) {
				const obj = { ...e };
				const children = toTree(data, e?._id);
				if (children.length) obj.children = children;
				r.push(obj);
			}
			return r;
		}, []);
}

const makeParentChildAccount = (data, parentId = undefined) => {
	let tree = toTree(data, parentId);
	return tree;
}

// create Account type dropdown 
const ChartOfAccountsTypeTree =(accountType, parentId, language)=>{
	let tree = toTree(accountType, parentId);
	let chartOfAccountTypeTree = [];
	tree?.map((obj) => {
		let level0 = {
			label: 'ar' === language ? obj?.title?.ar : obj?.title?.en,
			options: [],
			account_group:obj?.parent_account?._id
		};
		obj?.children?.map((e) => {				
			level0.options.push({
				label: 'ar' === language ?e?.title?.ar : e?.title?.en,
				value: e._id,
				account_group:e?.parent_account?._id
			});
		});
		chartOfAccountTypeTree.push(level0);                    
	});
	return chartOfAccountTypeTree;
}
// genrate account dropdown with tree structure. Only for normal select box. Payment collection and reccord collection
const  generateParentAccountOptionNormal=(data,language='ar',disabledAccountType=false, i = 0)=> {		
	let html = '';
	i++;
	data?.map((e) => {
		let rept = '&nbsp; &nbsp;'.repeat(i);
		let bullPoint = i > 1 ? '<strong>&bull;</strong> &nbsp;' : '';
		let disabled = e?.account_category=='AT' ? 'disabled' : '';
		let showCode = e?.account_code?.length==2 ? '' : e?.account_code+' - ';
		html +=
			'<option value="' +
			e?._id +
			'" '+disabled+'>' +
			rept +
			bullPoint +
			showCode+
			e?.title?.[language] +
			'</option>';
		if (e.children?.length) {
			html += generateParentAccountOptionNormal(e?.children,language, disabledAccountType, i);
		}
	});
	return html;
}

//this function is used to generate account dropdown with tree structure with label and value for react select
const  generateParentAccountOption=(data,language='ar',disabledAccountType=true, i = 0)=> {	
	var he = require('he');
	let newArray = [];
	i++;
	data?.forEach((e) => {
		let rept = '&ensp;&nbsp;'.repeat(i);
		let bullPoint = i > 1 ? '&bull;&nbsp;' : '';
		let disabled = e?.account_category==='AT' ? (disabledAccountType && true) : false;
		let showCode = e?.account_code?.length===2 ? '' : e?.account_code+' - ';
		//push to array
		newArray.push({label: he.decode(rept + bullPoint + showCode + e?.title?.[language]), value: e?._id, isDisabled: disabled, title: e.title?.[language], account_code:  e?.account_code ?? "", account_title: e?.title?.[language] });
		if (e.children?.length) {
			newArray.push(...generateParentAccountOption(e?.children,language, disabledAccountType, i));
		}
	});
	return newArray;
}


export {
	makeParentChildAccount,
	ChartOfAccountsTypeTree,
	generateParentAccountOption,
	generateParentAccountOptionNormal,
};
