import { secureAPI } from './API';
import { checkTokenValidate } from '../util/helper';
/*
Collection service api caller
*/
async function createTransfer(transfer_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post('/transfer', transfer_payload)
			.then((data) => {
				if (data.data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function getATransfer(transfer_id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/transfer/${transfer_id}?status=A`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function updateTransfer(payload, id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/transfer/${id}`, payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function deleteTransfer(id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/transfer/${id}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function patchTransfer(id, transfer_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/transfer/${id}`, transfer_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err?.response?.data?.error);
			});
	});
}

async function getAllTransferOrders(selectElement, searchParam) {
	let url = '/transfer_orders?status=A' + searchParam;
	if (selectElement) {
		url = `${url}&select=${selectElement}`;
	}		
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

export {
	createTransfer,
	getATransfer,
	updateTransfer,
	deleteTransfer,
	patchTransfer,
	getAllTransferOrders,
};
