import { secureAPI} from './API';
import { checkTokenValidate } from '../util/helper';

//get all journal
async function getAllJournal(selectElement = null, searchParam = ''){
  let url = '/journal?status=A' + searchParam;
	if (selectElement) {
		url = `${url}&select=${selectElement}`;
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
//Journal create
async function createJournal(payload) {
    return new Promise((resolve, reject) => {
      secureAPI()
      .post('/journal', payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
    });
}
//Journal delete
async function journalDelete(journalId){
	return new Promise((resolve, reject) => {
		secureAPI()
	  	.delete(`/journal/${journalId}`)
	  	.then(({ data }) => {
		  	if (data.success) {
			 	resolve(data.data); //return single object
		 	}
	  	})
	  	.catch((err) => {
		 	checkTokenValidate(err);
		  	reject(err.response.data.error);
	  	});
	});
}
// get one journal based on id
async function getAJournal(id, content_type=''){
  content_type = content_type ? `?content_type=${content_type}` : '';
	return new Promise((resolve, reject) => {
	  	secureAPI()
	  	.get(`/journal/${id}${content_type}`)
	  	.then(({ data }) => {
			if (data.success) {
		 		resolve(data.data); //return single object
			}
	  	})
	  	.catch((err) => {
			checkTokenValidate(err);
			reject(err.response.data.error);
	  	});
	});
}

//Update Journal
async function updateJournal(payload, id){
	return new Promise((resolve, reject) => {
		secureAPI()
	  	.put(`/journal/${id}`, payload)
	  	.then(({ data }) => {
			if (data.success) {
				resolve(data.data); //return single object
			}
	  	})
	  	.catch((err) => {
			checkTokenValidate(err);
			reject(err.response.data.error);
	  	});
	});
}

// patch a single journal
async function patchJournal(journal_id, journal_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/journal/${journal_id}`, journal_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data?.error);
      });
  });
}

export { createJournal, getAllJournal, journalDelete, getAJournal, updateJournal,patchJournal};