import React from 'react';
import { Modal } from 'react-bootstrap';
export default function CommonModal({
	t,
	modalClose,
	showModal,
	onSaveData,
	children,
	title,
	className = 'adcustmodal',
	size = 'md',
	pageType = 'taxes',
	...props

}) {
	const onSave = (data) => {
		onSaveData(data);
		modalClose();
	};

	return (
		<Modal size={size} show={showModal} onHide={modalClose} className={className} {...props}>
			{'true' === process.env.REACT_APP_BASE_SHOW_PATH_HINTS && <p style={{ color: 'red' }}>frontend-2.0/src/modal/CommonModal.js</p>}
			<Modal.Header closeButton>
				<Modal.Title>{t(title)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{children({
					isModal: true,
					onSave: onSave,
					onCancel: modalClose,
					pageType: pageType, //for tax add
					...props,
				})}
			</Modal.Body>
		</Modal>
	);
}
