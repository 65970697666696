//all English translations are added here
export const TRANSLATIONS_EN = {
  Login: 'Login',
  LoginH2: 'Sign In to your account',
  LoginEmail: 'email',
  LoginPassword: 'Password',
  LoginBtn: 'Login',
  ForgetPass: 'Forgot password?',
  SignUpText: 'Sign up',
  SignUpDetails:
    'An invoice is a time-stamped commercial document that itemizes and records a transaction between a buyer and a seller.',
  RegisterNow: 'Register Now',
  FooterText: 'Made With 💚 In',
  Register: 'Register',
  CreateYourAccount: 'Create your account',
  FisrtName: 'Fisrt Name',
  LastName: 'Last Name',
  Password: 'Password',
  CreateAccount: 'Create Account',
  AlreadyHave: 'Already have an account?. Please click here to',
  SignIn: 'Sign In',
  ItemNameEn: 'Item Name',
  ItemTypeAr: 'Item Type Ar',
  Type: 'Type',
  Unit: 'Unit',
  Price: 'Price',
  Tax: 'Tax',
  Action: 'Action',
  More: 'More',
  Description: 'Description',
  Account: 'Account',
  Category: 'Category',
  Close: 'Close',
  NoItemsfound: 'No Items found',
  ToAddNewItem: 'To add a new item, please click below Add button',
  AddAnItem: 'Add an Item',
  Goods: 'Goods',
  Service: 'Service',
  NameInEnglish: 'Name In English',
  NameInArabic: 'Name In Arabic',
  PleaseTypeOrChoose: 'Please type or choose',
  ItemCategory: 'Item Category',
  SalesInformation: 'Sales Information',
  SAR: 'SAR',
  Content: 'Content',
  'Customer Name': 'Customer Name',
  SalesbyItem: 'Sales by Item',
  Download: 'Download',
  ItemName: 'Item Name',
  QuantitySold: 'Quantity Sold',
  Amount: 'Amount',
  AveragePrice: 'Average Price',
  Total: 'Total',
  NosoldItemsfound: 'NosoldItemsfound',
  InvoiceNumber: 'Invoice Number',
  'Invoice Date': 'Invoice Date',
  DueDate: 'Due Date',
  Salesperson: 'Salesperson',
  Letyourcustomerknow: 'Let your customer know what this Invoice is for',
  ItemRatesAre: 'Item Rates Are',
  TaxExclusive: 'Tax Exclusive',
  TaxInclusive: 'Tax Inclusive',
  ITEMDETAILS: 'ITEM DETAILS',
  QUANTITY: 'QUANTITY',
  RATE: 'RATE',
  EditOrganization: 'Edit Organization',
  Pleaseselect: 'Please Select',
  PleaseSelect: 'Please Select',
  DISCOUNT: 'DISCOUNT',
  TAX: 'TAX',
  AMOUNT: 'AMOUNT',
  AddMoreItem: 'Add More Item +',
  CustomerNotes: 'Customer Notes',
  SubTotal: 'Sub Total',
  AllTaxes: 'All Taxes',
  TotalPrice: 'Total Price',
  TermsConditions: 'Terms & Conditions',
  AttachFiletoInvoice: 'Attach File to Invoice',
  Ihavereceivedthepayment: 'I have received the payment',
  PaymentMode: 'Payment Mode *',
  DepositTo: 'Deposit To *',
  EmailTo: 'Email To',
  NoContactPersonsEmailFound: 'No Contact Persons Email Found',
  AddNewContactEmail: 'Add New Contact Email',
  SaveasDraft: 'Save as Draft',
  Submit: 'Submit',
  SaveandPrint: 'Save and Print',
  Saveandshare: 'Save and share',
  AddContactPerson: 'Add Contact Person',
  FirstName: 'First Name',
  Email: 'Email',
  'Work phone': 'Work Phone',
  Mobile: 'Mobile',
  Save: 'Save',
  AddItem: 'Add Item',
  AddCustomer: 'Add Customer',
  CustomerType: 'Customer Type',
  Business: 'Business',
  Individual: 'Individual',
  PrimaryContact: 'Primary Contact',
  Pleaseselecttitle: 'Please select title',
  CompanyName: 'Company Name',
  CustomerEmail: 'Customer Email',
  CustomerPhone: 'Customer Phone',
  OtherDetails: 'Other Details',
  Address: 'Address',
  ContactPersons: 'ContactPersons',
  Remarks: 'Remarks',
  TaxTreatment: 'Tax Treatment',
  VatRegistered: 'Vat Registered',
  NoVatRegistered: 'No Vat Registered',
  PlaceofSupply: 'PlaceofSupply',
  TaxRegistration: 'TaxRegistration',
  Number: 'Number',
  Currency: 'Currency',
  BillingAddress: 'BillingAddress',
  Country: 'Country',
  City: 'City',
  ZipCode: 'Postal Code',
  Phone: 'Telephone',
  ShippingAddress: 'Shipping Address',
  Keepbillingaddress: 'Keep billing address >>',
  Attention: 'Attention',
  EmailAddress: 'Email Address',
  ForInternalUse: 'For Internal Use',
  'Invoice number': 'Invoice number',
  Yourinvoicenumbersareseton:
    'Your invoice numbers are set on auto-generate mode to save your time. Are you sure about changing this setting?',
  ManageSalespersons: 'Manage Sales Persons',
  Name: 'Name',
  SaveNew: 'Save New',
  AddNewItem: 'New Item +',
  DownloadFile: 'Download File',
  SaveandSentEmail: 'Save and Sent Email',
  AddRow: 'Add Row +',
  'Invoice ID': 'Invoice ID',
  'Order Number Type': 'Order Number Type',
  'Invoice Due Date': 'Invoice Due Date',
  Status: 'Status',
  Edit: 'Edit',
  DownloadInvoice: 'Download Invoice',
  Sharedlink: 'Shared link',
  CreateCreditNotes: 'Create Credit Notes',
  InvoiceFormat: 'Invoice Format',
  SalesPersonName: 'Sales Person Name',
  Subject: 'Subject',
  NoFileFound: 'No File Found',
  NoInvoicesfound: 'No Invoices found',
  Toaddanewinvoice: 'To add a new invoice, please click below Add button',
  AddanInvoice: 'Add an Invoice',
  ShareInvoiceLink: 'Share Invoice Link',
  Pleaseclickoncreatelink:
    'Please click on create link to generate a shareble link',
  CopyLink: 'Copy Link',
  invoicesareshareble:
    'Only <b>sent</b> invoices are shareble. Do you want to save this invoice as <b>sent</b>?',
  ConfirmContinue: 'Confirm & Continue',
  Pleasekeepthepaymentformatastext:
    'Please keep the payment format as text,hypen and number <b>( CN-001 )</b>, so that it will be auto generate for you.',

  SharedInvoicedetails: 'Shared Invoice details',
  AmountWithTax: 'Amount With Tax',
  Sellingprice: 'Selling Price',
  AddanItem: 'Add an Item',
  AddaReceivedPayment: 'Add a Received Payment',
  AddaCreditNote: 'Add a Credit Note',
  Profile: 'Profile',
  CustomerMobile: 'Customer Mobile',
  NoCustomersfound: 'No Customers found',
  Toaddanewcustomer: 'To add a new customer, please click below Add button',
  AddaCustomer: 'New customer',
  Street1: 'Street 1',
  Street2: 'Street 2',
  SalesbyCustomer: 'Sales by Customer',
  Pleaseselectadifferent:
    'Please select a different date to get more specific data',
  InvoiceQuantity: 'Invoice Quantity',
  NoRecordsForCustomerfound: "No Records For Customer's found",
  CheckStatement: 'Check Statement',
  CustomerStatement: 'Customer Statement',
  SendEmail: 'Send Email',
  StatementDetails: 'Statement Details',
  To: 'To',
  AccountSummary: 'Account Summary',
  InvoicesAmount: 'Invoices Amount',
  AmountReceived: 'Amount Received',
  Balance: 'Balance',
  InAdvanced: 'In Advanced',
  Due: 'Due',
  Date: 'Date',
  Transactions: 'Transactions',
  TotalPayments: 'Total Payments',
  OpeningBalance: 'Opening Balance',
  SelectanItem: 'Select an Item',
  Adddescriptiontoyouritem: 'Add description to your item',
  Taxis: 'Tax is',
  AddSalesperson: 'New Sales Person +',
  Enetrsalespersonname: 'Enetr sales person name',
  Entersalespersonemail: 'Enter sales person email',
  CreditNote: 'Credit Note',
  CreditNoteDate: 'Credit Note Date',
  Reference: 'Reference',
  DownloadNote: 'Download Note',
  NoCreditNotesfound: 'No Credit Notes found',
  ToaddanewCreditNotes:
    'To add a new Credit Notes, please click below Add button',
  AddaCreditNotes: 'Add a Credit Notes',
  CreditNoteNumber: 'Credit Note Number',
  SaveasOpen: 'Save as Open',
  RemainingBalance: 'Remaining Balance',
  Aerrorhasoccurred:
    'A error has occurred. Total Price cannot be bigger than Remaining Balance Or Total price cannot be bigger than invoice total price',
  InvoiceTotalPrice: 'Invoice Total Price',
  PaymentNumber: 'Payment number',
  Forgotpassword: 'Forgot password ?',
  Resetpasswordwithemail: 'Reset password with the email',
  ResetPassword: 'Reset Password',
  Pleasekeepthepaymentformat:
    'Please keep the payment format as text,hypen and number ( PAY-001 ), so that it will be auto generate for you.',
  Bankcharges: 'Bank charges',
  Receivedfullamount: 'Received full amount',
  PaymentDate: 'Payment Date',
  UnpaidInvoices: 'Unpaid Invoices',
  ListofSENTinvoicesonly: 'List of SENT invoices only',
  InvoiceAmount: 'Invoice amount',
  AmountDue: 'Amount Due',
  Amountusedforpayments: 'Amount used for payments',
  Amountinexcess: 'Amount in excess',
  PaymentNotes: 'Payment Notes',
  EmailaThankYounoteforthispayment: 'Email a Thank You note for this payment',
  Fullamount: 'Full amount',
  Amountusedforthispayments: 'Amount used for this payments',
  PaymentID: 'Payment ID',
  'Transaction charges': 'Transaction Charges',
  ActualAmountReceived: 'Actual Amount Received',
  NoPaymentRecordsfound: 'No Payment Records found',
  Areyousure: 'Are you sure?',
  Onlysentinvoicesareshareble:
    'Only sent invoices are shareble. Do you want to save this invoice as sent ?',
  logout: 'Sign Out',
  Welcome: 'Welcome',
  HereyoucanAddorEdityou: 'Here, you can Add or Edit you organization details',
  PleaseAddOrEditOrganization: 'Please Add Or Edit Organization Details',
  OrganizationNameInEnglish: 'Organization Name In English',
  OrganizationNameInArabic: 'Organization Name In Arabic',
  OrganizationAddress: 'Organization Address',
  VatID: 'Vat ID',
  SocialMediaLink: 'Social Media Link',
  OrganizationDetails: 'Organization Details',
  Dashboard: 'Dashboard',
  ClickHereToEdit: 'Click Here To Edit',
  HereyoucanAddorEdit: 'Here, you can Add or Edit personal details',
  PleaseAddOrEditpersonal: 'Please Add Or Edit personal Details',
  NewPassword: 'New Password',
  ConfirmNewPassword: 'Confirm New Password',
  UserDetails: 'User Details',
  TAXAMOUNT: 'قيمة الضريبة',
  "Don't have an account?": "Don't have an account?",
  Registration: 'Registration',
  'Confirm password': 'Confirm password',
  'I have an account?': 'I have an account?',
  'I know my password': 'I know my password',
  'Set New password': 'Set New password',
  Items: 'Items',
  Customers: 'Customers',
  Invoices: 'Invoices',
  'Credit notes': 'Credit notes',
  Payments: 'Payments',
  Settings: 'Settings',
  Accounts: 'Accounts',
  'Sales person': 'Sales person',
  ANINVOICE: 'ANINVOICE',
  'All Items': 'All Items',
  'Sl no.': 'Sl no.',
  'Sell price': 'Sell price',
  Delete: 'Delete',
  'No item found.': 'No item found.',
  'Choose account': 'Choose account',
  'Add Customers': 'Add Customers',
  'Company display name': 'Company display name',
  'Tax registration number (TRN)': 'Tax registration number (TRN)',
  'Place of supply': 'Place of supply',
  'Saudi Arabia': 'Saudi Arabia',
  ' UAE': ' UAE',
  Services: 'Services',
  CM: 'CM',
  Feet: 'Feet',
  Inch: 'Inch',
  'Choose category': 'Choose category',
  'Type something': 'Type something',
  'Add credit note': 'Add credit note',
  'Actual amount received': 'Actual amount received',
  'New payment': 'New payment',
  'Payment notes': 'Payment notes',
  'Amount in excess': 'Amount in excess',
  Referance: 'Referance',
  'Amount received': 'Amount received',
  'Add invoice': 'Add invoice',
  'Order number': 'Order number',
  'Due date': 'Due date',
  'Item rate': 'Item rate',
  'Item details': 'Item details',
  'Discount amount': 'Discount amount',
  'Sub total (without discount applied)':
    'Sub total (without discount applied)',
  'Total discount': 'Total discount',
  'All taxes': 'All taxes',
  'Total prices': 'Total prices',
  Cheque: 'Cheque',
  Cash: 'Cash',
  'Bank transfer': 'Bank transfer',
  'Bank remittance': 'Bank remittance',
  'Customer contacts': 'Customer contacts',
  'Shipping address': 'Shipping address',
  'No Invoice Found': 'No Invoice Found',
  'Tax amount': 'Tax amount',
  'Add contact persons': 'Add contact persons',
};
