import { secureAPI } from './API';
import { checkTokenValidate } from '../util/helper';

/** 
 * @description createRecurringExpenses function used
 * to create a new recurring expenses
 * call from browser
 * 
*/
async function createRecurringExpenses(recurring_expenses_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post('/recurring_expenses', recurring_expenses_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

/**
 * @description getRecurringExpenses function used to
 * fetch all recurring expenses 
 * call from browser
 * 
 */
async function getAllRecurringExpenses(searchParam = '', selectElement = '') {
  let url = '/recurring_expenses?status=A' + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }

  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/**
 * @description getARecurringExpenses function used to 
 * fetch a single recurring expenses
 * call from browser
 */
async function getARecurringExpenses(id) {       
  
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/recurring_expenses/${id}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}



/**
 * @description updateARecurringExpenses function used to 
 * update a single recurring expenses
 * call from browser
 */
async function updateARecurringExpenses(expenses_payload,id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/recurring_expenses/${id}`, expenses_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

/**
 * @description updateARecurringExpenses function used to 
 * update a single recurring expenses
 * call from browser
 */
async function deleteARecurringExpenses(id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .delete(`/recurring_expenses/${id}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

/**
 * @description updateARecurringExpenses function used to 
 * update a single recurring expenses
 * call from browser
 */
async function toggleTransactionStatusOfARecurringExpenses(id,status='') {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/recurring_expenses/${id}/transaction_status?status=${status}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}


/**
 * @description getAllChildExpenses function used to 
 * fetch all child expenses of recurring expenses according to payment_status
 * by default payment_status :=> all
 * call from browser
 * 
 */

async function getAllChildExpenses({id,searchParam = '', selectElement = ''}) { 
  
  let url = `/recurring_expenses/${id}/expenses?status=A&` + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }
  try {
    const response = await secureAPI().get(url);
    if (response.data.success) {
      return response.data;
    }
  } catch (err) {
    checkTokenValidate(err);
    throw new Error(err.response.data.error);
  }
}

export {
  createRecurringExpenses,
  getAllRecurringExpenses,
  getARecurringExpenses,
  updateARecurringExpenses,
  deleteARecurringExpenses,
  toggleTransactionStatusOfARecurringExpenses, 
  getAllChildExpenses,
}