import React from 'react';
import { Container } from 'reactstrap';
import './../translations/i18n';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <footer className="logfooter noprint">
        <Container>
          <p className="m-0">
            <span dir="ltr">&copy; ANINVOICE, 2020-{new Date().getFullYear()}</span>. {t('All Rights Reserved')} {'.'}
          </p>
        </Container>
      </footer>
    </>
  );
};
export default Footer;
