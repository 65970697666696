import { secureAPI } from './API';
import { checkTokenValidate } from '../util/helper';
import queryString from 'query-string';


/*
get all documents
return promise
resolve : list of objects
*/
//extra , fillter as array
async function getAllDocuments(searchParam = '', selectElement = null) {
  let url = '/documents?status=A&model=documents' + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }
  
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/*
get a document 
return promise
resolve : a object
*/
async function getADocument(document_id, selectElement = null) {
  let url = `/documents/${document_id}`;
  if (selectElement) {
    url = `${url}?select=${selectElement}`;
  }
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return a object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function getAllDocumentsByFolder(folderId, searchParam = '') {
  let url =
    '/documents/folder/' + folderId + '?status=A&model=documents' + searchParam;

  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

//new
//'folder_id' will always be present (null in case of root)
//"filter_by" will have value [sheets, pdf,image] etc.. can be an empty means a 'document_id' is present
async function getDocuments(folder_id, filter_by , include_associated_transactions=true, searchParam = '',) {
  let query_object={
    status: "A",
    model : "documents",
    include_associated_transactions : include_associated_transactions
  };
  if(folder_id){
    query_object.folder_id = folder_id
  };
  if(filter_by){
    query_object.filter_by=filter_by
  };
  

  let search_query_string = queryString.stringify(query_object)

  let url =`/documents/folder?${search_query_string}${searchParam}`;

  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/*
add a documents from local folder to collection and S3
return promise
resolve : a object
*/
async function addDocuments(itemPayload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post(`/documents`, itemPayload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/*
update an document
return promise
resolve : a object
*/
async function updateADocument(document_id, documentPayload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/documents/${document_id}`, documentPayload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/*
delete an document
return promise
resolve : a object
*/
async function deleteADocument(document_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .delete(`/documents/${document_id}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
export {
  addDocuments,
  getAllDocuments,
  deleteADocument,
  getAllDocumentsByFolder,
  getADocument,
  updateADocument,


  //new 
  getDocuments
};
