import { secureUploadAPI, secureAPI } from '../services/API';
import { checkTokenValidate } from './../util/helper';


/*Item  service
Store all items route api call here.
*/

/*
get all item of a organization
return promise
resolve : list of objects
*/
//extra , fillter as array
async function getAllItems(selectElement = null, searchParam = '') {
  let url = '/items?status=A' + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }

  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/*
get an item of a organization by id
return promise
resolve : a object
*/
async function getAnItem(item_id, selectElement = null) {
  let url = `/items/${item_id}`;
  if (selectElement) {
    url = `${url}?select=${selectElement}`;
  }
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
/*
crate an item of a organization
return promise
resolve : a object
*/
async function createAnItem(itemPayload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post(`/items`, itemPayload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
/*
update an item of a organization by id
return promise
resolve : a object

item_id will used to seach the requested document from db and the
payload will be updated.
itemPayload can be any number of valid fields
*/
async function updateAnItem(item_id, itemPayload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/items/${item_id}`, itemPayload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
/*
delete an item of a organization by id
return promise
resolve : a object
*/
async function deleteAnItem(item_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .delete(`/items/${item_id}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
// Import item excel file cde start here -------

async function previewItemsImport(previewPayload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post(`/items/preview`, previewPayload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

//get mapped columns in return
async function itemsImportUpload(file) {
  return new Promise((resolve, reject) => {
    secureUploadAPI()
      .post(`/items/import/upload`, file)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

//after mapping, send the mapped keys to the server
//for validation , it return validated data and other information
async function itemsImportPreview(file_id, payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post(`/items/import/preview/${file_id}`, payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
async function itemsImport(file_id, payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post(`/items/import/${file_id}`, payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}


/**
 * 
 * @param {*} item_id 
 * @returns 
 */
async function getItemMovement(item_id) {
  let url = `/items/${item_id}/movement`;
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/**
 * @description : fetch item transaction
 * @param {item_id : <String>}
 * @param {module_name : <String>}
 * @param {module_status : <String>}
 * @return {itemTransaction : []}
 * 
 * 
 */
async function getItemTransaction(item_id,module_name,module_status) {
  let url = `/items/${item_id}/transactions/${module_name}?transaction_status=${module_status}`;
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}


async function addNewProductImage(item_id, payload) {
  try{
    const response = await secureAPI().post(`/items/${item_id}/images`, payload);
    if(response.data.success){
        return response.data.data;
    }
  }
  catch (err){
    checkTokenValidate(err);
    throw new Error(err.response.data.error);
  }
}
async function deleteProductImages({item_id,product_image_ids}) {
  try {
    const query = `?product_image_ids=${product_image_ids.join(',')}`;
    const response = await secureAPI().delete(`/items/${item_id}/images${query}`);
    if (response.data.success) {
      return response.data.data;
    }
  } catch (err) {
    checkTokenValidate(err);
    throw new Error(err.response.data.error);
  }
}
async  function productImageMarkAsPrimary({item_id, product_image_id}) {
    try {
        const response = await secureAPI().put(`/items/${item_id}/images/${product_image_id}/primary`);
        if (response.data.success) {
        return response.data.data;
        }
    } catch (err) {
        checkTokenValidate(err);
        throw new Error(err.response.data.error);
    }
}


/**
 * @typedef WACOfItem
 * @property {number} wac
 * @property {number} in_hand_quantity
 * @property {number} asset_value
 */

/**
 *
 * @param item_ids
 * @param store_id
 * @param date
 * @param exclude_id
 * @return {Promise<WACOfItem[]>}
 */
async  function getWACOfItems({item_ids,store_id,date, exclude_id}) {
  try {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('item_ids', item_ids.join(','));
    urlSearchParams.set('store_id', store_id);
    urlSearchParams.set('date', date);
    if(exclude_id){
      urlSearchParams.set('exclude_id', exclude_id);
    }
    const url = `/items/wac?${urlSearchParams.toString()}`;
    const response = await secureAPI().get(url);
    if (response.data.success) {
      return response.data.data
    }
  } catch (err) {
    checkTokenValidate(err);
    throw new Error(err.response.data.error);
  }
}


async function getStockSummaryOneItemWise({ item_id }) {
  try {

    const url = `/items/${item_id}/store_wise_stock_summary`;
    const response = await secureAPI().get(url);
    if (response.data.success) {
      return response.data.data
    }
  } catch (err) {
    checkTokenValidate(err);
    throw new Error(err.response.data.error);
  }
}

export {
  createAnItem,
  getAllItems,
  getAnItem,
  deleteAnItem,
  updateAnItem,
  previewItemsImport,
  itemsImportUpload,
  itemsImportPreview,
  itemsImport,
  getItemMovement,
  getItemTransaction,
  addNewProductImage,
  deleteProductImages,
  productImageMarkAsPrimary,
  getWACOfItems,
  getStockSummaryOneItemWise
};
