import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faUsers,
	faTasks,
	faUserTag,
	faCashRegister,
	faPercent,
	faWeight,
	faListOl,
	faCodeBranch,
	faMoneyBillWave,
	faTag,
	faUniversity,
	faReceipt,
	faDollarSign,
	faPuzzlePiece,
	faEnvelope,
	faDownload,
	faBox,
	faStore,
	faMoneyCheckAlt,
	faFileInvoice,
	faCloudUploadAlt,
	faStream,
	faTools,
	faCity,
	faMoneyCheck,
	faUserTie,
	faList,
	faLink,
} from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import * as Icon from "react-bootstrap-icons";
import { Accordion, Form } from "react-bootstrap";
import "../../translations/i18n";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SidebarSettingsMenu = ({ leftSide }) => {
	const { language, userInfo } = useSelector((state) => state.auth);
	const { t } = useTranslation();
	let location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");

	const role_and_permission = useSelector((state) => state?.auth?.userInfo?.role_and_permission?.permission_group);
	const features_config = useSelector((state) => state?.auth?.userInfo?.default_organization_id?.features_config ?? null);
	const country = useSelector((state) => state?.auth?.userInfo?.default_organization_id?.country ?? null);

	const settingPermission = role_and_permission?.settings;
	const branchePermission = role_and_permission?.branches;
	const itemPermission = role_and_permission?.items;
	const salePermission = role_and_permission?.sales;
	const purchasePermission = role_and_permission?.purchases;

	// console.log('role_and_permission',role_and_permission)

	let organization_id = userInfo?.default_organization_id?._id;

	const [settingsMenu, setSettingsMenu] = useState([]);
	const [originalSettingsMenu, setOriginalSettingsMenu] = useState([]);
	const [activeKey, setActiveKey] = useState("0");
	const [menuSettingPermission, setMenuSettingPermission] = useState(null);

	//search box pop up
	const search_ref = useRef(null);
	const [searchPopUp, setSearchPopUp] = useState(false);
	const [popupSettingMenu, setPopupSettingMenu] = useState([]);

	// show Zatca onboard if organization country is SA && Show API integration if is_api_enabled is enabled
	const zatca_api_menu = [
		"SA" === country && {
			label: t("Zatca onboard"),
			permission: true,
			splitLocation: "zatca",
			to: `/${language}/zatca/egs_units`,
			icon: faPuzzlePiece,
			display_add_btn: `/${language}/zatca/egs_units`,
			group: "integration",
			header_name: t("Integration"),
			// tags: ["zatca"],
		},
		true === features_config?.is_api_enabled && {
			label: t("API integration"),
			permission: true, // === features_config?.is_api_enabled,
			splitLocation: "settings",
			to: `/${language}/settings/integrations/api`,
			icon: faCloudUploadAlt,
			display_add_btn: `/${language}settings/integrations/api`,
			group: "integration",
			header_name: t("Integration"),
			tags: ["api", "API integration", "الربط مع واجهة برمجة التطبيقات"],
		},
	];
	useEffect(() => {
		/** organization menu */
		const organization_menu = [
			{
				label: t("Organization profile"),
				permission: settingPermission?.settings_update_organization_profile,
				splitLocation: "organization_profile",
				to: `/${language}/settings/organization_profile/${organization_id}`,
				icon: faBuilding,
				group: "general",
				header_name: t("Organization"),
				tags: [t("organization"), t("profile"), t("Organization profile")],
			},
			{
				label: t("Opening balances"),
				permission: true,
				splitLocation: "opening_balance",
				to: `/${language}/settings/opening_balance`,
				icon: faReceipt,
				group: "general",
				header_name: t("Organization"),
				tags: [t("Opening"), t("balances"), t("Opening balances")],
			},

			{
				label: t("Branches"),
				permission: true === branchePermission?.branches_view || true === settingPermission?.settings_access_all_branches,
				splitLocation: "branches",
				to: `/${language}/settings/branches`,
				icon: faCodeBranch,
				display_add_btn: `/${language}/settings/branches`,
				group: "general",
				header_name: t("Organization"),
				tags: [t("Branches")],
			},
			{
				label: t("Stores"),
				permission: true === features_config?.is_inventory_enabled ? true : false,
				splitLocation: "store",
				to: `/${language}/settings/store`,
				icon: faStore,
				display_add_btn: `/${language}/settings/store`,
				group: "general",
				header_name: t("Organization"),
				tags: [t("Stores")],
			},
			{
				label: t("Transaction series"),
				permission: true === settingPermission?.settings_access_all_branches,
				splitLocation: "transaction_series",
				to: `/${language}/organizations/transaction_series`,
				icon: faListOl,
				display_add_btn: `/${language}/organizations/transaction_series`,
				group: "general",
				header_name: t("Organization"),
				tags: [t("Transaction"), t("Series"), t("Transaction series")],
			},
			{
				label: t("Currencies"),
				permission: true,
				splitLocation: "currencies",
				to: `/${language}/settings/currencies`,
				icon: faDollarSign,
				group: "general",
				header_name: t("Organization"),
				tags: [t("Currency"), t("Currencies")],
			},
			{
				label: t("Banks"),
				permission: true,
				splitLocation: "banks",
				to: `/${language}/organizations/banks`,
				icon: faUniversity,
				group: "general",
				header_name: t("Organization"),
				tags: [t("Bank"), t("Banks")],
			},
			{
				label: t("Data backup"),
				permission: true,
				splitLocation: "data_backup",
				to: `/${language}/settings/data_backup`,
				icon: faDownload,
				header_name: t("Organization"),
				group: "general",
			},
		];
		/** user and role */
		const user_role_menu = [
			{
				label: t("Users & Roles"),
				permission: settingPermission?.settings_users,
				splitLocation: "user_role",
				to: `/${language}/settings/user_role`,
				icon: faUsers,
				group: "user_role",
				header_name: t("Users"),
				// tags: [t("Users"), t("Roles"), t("Users & Roles"), t("Permissions")],
			},
		];
		/** items */
		const item_menu = [
			{
				label: t("Inventory"),
				permission: true === itemPermission?.items_create,
				splitLocation: "inventory",
				to: `/${language}/settings/inventory`,
				icon: faBox,
				group: "item",
				header_name: t("Item"),
				tags: ["Inventory", "قائمة الجرد"],
			},
			{
				label: t("Item categories"),
				permission: true === itemPermission?.items_create,
				splitLocation: "item-categories",
				to: `/${language}/settings/item-categories`,
				icon: faTasks,
				display_add_btn: `/${language}/settings/item-categories`,
				group: "item",
				header_name: t("Item"),
				// tags: [t("Item categories"), t("Item"), t("categories")],
			},
			{
				label: t("Units"),
				permission: true === itemPermission?.items_create,
				splitLocation: "units",
				to: `/${language}/organizations/units`,
				icon: faWeight,
				display_add_btn: `/${language}/organizations/units`,
				group: "item",
				header_name: t("Item"),
				// tags: [t("Units")],
			},
			// TODO: - Transfer Order - IVTRY
			//       - Inventory Adjustment - IVTRY
		];
		/** taxes */
		const taxes_menu = [
			{
				label: t("E-invoicing"),
				permission: true === settingPermission?.settings_taxes,
				splitLocation: "e-invoicing",
				to: `/${language}/organizations/e-invoicing`,
				icon: faFileInvoice,
				display_add_btn: `/${language}/organizations/e-invoicing`,
				group: "taxes",
				header_name: t("Taxes"),
				// tags: [t("Taxes")],
			},
			{
				label: t("Taxes"),
				permission: true === settingPermission?.settings_taxes,
				splitLocation: "taxes",
				to: `/${language}/organizations/taxes`,
				icon: faPercent,
				display_add_btn: `/${language}/organizations/taxes`,
				group: "taxes",
				header_name: t("Taxes"),
				// tags: [t("Taxes")],
			},

			// {
			// 	label: t('Tax settings'),
			// 	permission: true === settingPermission?.settings_taxes,
			// 	splitLocation: 'tax_return_setting',
			// 	to: `/${language}/organizations/tax_return_setting`,
			// 	icon: faPercent,
			// 	display_add_btn: `/${language}/organizations/tax_return_setting`,
			// 	group: 'taxes',
			// 	tags: ['Tax settings', 'إعدادات الضرائب'],
			// },
		];
		/** templates */
		const templates_menu = [
			{
				label: t("Email templates"),
				permission: true,
				splitLocation: "email_template",
				to: `/${language}/settings/email_template`,
				icon: faEnvelope,
				group: "templates",
				header_name: t("Templates"),
				// tags: [t("Email templates"), t("Email"), t("templates")],
			},
		];
		/** payments */
		const payments_menu = [
			{
				label: t("Payment accounts"),
				permission: true, //=== settingPermission?.settings_payment_terms,
				splitLocation: "payment_accounts",
				to: `/${language}/organizations/payment_accounts`,
				icon: faMoneyCheckAlt,
				// display_add_btn: `/${language}/organizations/payment_accounts`,
				group: "payments",
				header_name: t("Payments"),
				// tags: [t("Payment"), t("Account"),t("Payment accounts")],
			},
			{
				label: t("Payment modes"),
				permission: true === settingPermission?.settings_update_organization_profile,
				splitLocation: "payment_modes",
				to: `/${language}/organizations/payment_modes`,
				icon: faCashRegister,
				display_add_btn: `/${language}/organizations/payment_modes`,
				group: "payments",
				header_name: t("Payments"),
				// tags: [t("Payment modes"), t("Payments")],
			},
			{
				label: t("Payment terms"),
				permission: true === settingPermission?.settings_payment_terms,
				splitLocation: "payment_due_terms",
				to: `/${language}/organizations/payment_due_terms`,
				icon: faMoneyBillWave,
				display_add_btn: `/${language}/organizations/payment_due_terms`,
				group: "payments",
				header_name: t("Payments"),
				// tags: [t("Payments"), t("Terms"), t("Payment terms")],
			},
		];
		/**customers */
		const customers_menu = [
			{
				label: t("Sales person"),
				permission: true === salePermission?.invoices_create,
				splitLocation: "salesperson",
				to: `/${language}/settings/salesperson`,
				icon: faUserTag,
				display_add_btn: `/${language}/settings/salesperson`,
				group: "customer",
				header_name: t("Customer"),
				// tags: [t("Sales person")],
			},
		];
		/** settings */
		const settings_menu = [
			{
				label: t("Reporting tags"),
				permission: true,
				splitLocation: "reportingtags",
				to: `/${language}/settings/reportingtags`,
				icon: faTag,
				group: "settings",
				header_name: t("Settings"),
				// tags: [t("Reporting"), t("Tags"), t("Reporting tags")],
			},
			{
				label: t("Contacts sequence ID"),
				permission: true,
				splitLocation: "contact_sequence_number",
				to: `/${language}/settings/contact_sequence_number`,
				icon: faTag,
				group: "settings",
				header_name: t("Settings"),
			},
			//TODO: Donot delete this code
			// {
			// 	label: t('Account settings'),
			// 	permission: true,
			// 	splitLocation: 'account_settings',
			// 	to: `/${language}/settings/account-settings`,
			// 	icon: faUserCog,
			// 	group: 'settings',
			// 	tags: ['Account settings', 'Account settings',],
			// },
			//TODO: Donot delete this code
			//{
			// 	label: t('Automation'),
			// 	permission: true, // === salePermission?.invoices_create,
			// 	splitLocation: 'automations',
			// 	to: `/${language}/settings/automations`,
			// 	icon: faSyncAlt,
			// 	display_add_btn: `/${language}/settings/automations`,
			// 	group: 'settings',
			// 	tags: ['Automation'],
			// },
		];
		const setting_menu = [
			...organization_menu,
			...user_role_menu,
			...item_menu,
			...taxes_menu,
			...templates_menu,
			...payments_menu,
			...customers_menu,
			...settings_menu,
			...zatca_api_menu,
		];
		setOriginalSettingsMenu(setting_menu);
		groupCategory(setting_menu);


		//set open accordion
		openAccordionMenu();
	}, [pathname, role_and_permission]);

	const openAccordionMenu = () => {
		const urlKey = splitLocation[3];
		if (
			["organization_profile", "opening_balance", "store", "branches", "transaction_series", "currencies", "banks", "data_backup"].includes(urlKey)
		) {
			setActiveKey("0");
		} else if (["user_role"].includes(urlKey)) {
			setActiveKey("1");
		} else if (["inventory", "item-categories", "units"].includes(urlKey)) {
			setActiveKey("2");
		} else if (["e-invoicing", "taxes"].includes(urlKey)) {
			setActiveKey("3");
		} else if (["email_template"].includes(urlKey)) {
			setActiveKey("4");
		} else if (["payment_accounts", "payment_modes", "payment_due_terms"].includes(urlKey)) {
			setActiveKey("5");
		} else if (["salesperson"].includes(urlKey)) {
			setActiveKey("6");
		} else if (["reportingtags", 'contact_sequence_number'].includes(urlKey)) {
			setActiveKey("7");
		} else if (["egs_units", "integrations"].includes(urlKey)) {
			setActiveKey("8");
		}
	};

	const searchMenu = (event) => {
		const search = event.target.value.trim().toLowerCase();
		if (search?.length >= 2) {
			const filteredMenu = originalSettingsMenu.filter((menu) => {
				return [menu?.label]?.filter((e) => e?.toLowerCase().includes(search)).length > 0 && menu?.permission === true;
			});
			groupCategory(filteredMenu, "search");
			setSearchPopUp(true);
		} else {
			groupCategory(originalSettingsMenu);
			setSearchPopUp(false);
		}
	};
	const groupCategory = (menu, search = null) => {
		const groupedMenu = menu.reduce((acc, curr) => {
			if (!acc[curr?.group]) {
				acc[curr?.group] = [];
			}
			acc[curr?.group].push(curr);
			return acc;
		}, {});
		if (search) {
			setPopupSettingMenu(groupedMenu);
		} else {
			const result = {};
			for (const group in groupedMenu) {
				result[group] = groupedMenu[group].some((item) => item.permission);
			};
			setMenuSettingPermission(result);
			setSettingsMenu(groupedMenu);
		}
	};
	// onclick clear search value,
	// reset popUpMenu
	// set searchPopUp false,
	const clearSearchValue = () => {
		search_ref.current.value = "";
		setPopupSettingMenu([]);
		setSearchPopUp(false);
	};

	return (
		<>
			{"true" === process.env.REACT_APP_BASE_SHOW_PATH_HINTS && (
				<p style={{ color: "red" }}>frontend-2.0/src/components/shared/SidebarSettingsMenu.js</p>
			)}
			{!leftSide && (
				<div className="setsrch frmbox newfrmv2 position-relative">
					<Form.Control
						as="input"
						ref={search_ref}
						type="text"
						placeholder={t("Search")}
						className="search-input"
						onKeyUp={searchMenu}
						onChange={searchMenu}
					/>
					<Icon.Search />
					{searchPopUp && (
						<div className="srchsuggs position-absolute">
							{Object.keys(popupSettingMenu)?.length > 0 &&
								Object.keys(popupSettingMenu).map((each_obj, index_1) => {
									return (
										<section key={each_obj}>
											<h5>{popupSettingMenu?.[each_obj]?.[0]?.header_name}</h5>
											<ul key={index_1}>
												{popupSettingMenu[each_obj]?.map((obj, index_2) => {
													return (
														<li key={index_2} onClick={() => clearSearchValue()}>
															<Link to={obj?.to}>{obj?.label}</Link>
														</li>
													);
												})}
											</ul>
										</section>
									);
								})}
							{Object.keys(popupSettingMenu)?.length === 0 && (
								<section>
									<ul className="text-center">{t("No data found")}</ul>
								</section>
							)}
						</div>
					)}
				</div>
			)}
			<Accordion defaultActiveKey="0" activeKey={activeKey}>
				{menuSettingPermission?.general && (
				<Accordion.Item eventKey="0" onClick={() => setActiveKey("0")}>
					<Accordion.Header>
						<span className="icons">
							<FontAwesomeIcon icon={faCity} />
						</span>{" "}
						<span className="txt">{settingsMenu?.general?.[0]?.header_name}</span>
					</Accordion.Header>
					<Accordion.Body>
						{settingsMenu?.general?.length > 0 &&
							settingsMenu?.general.map((settings, index) => {
								return (
									<React.Fragment key={index}>
										{settings?.permission === true && (
											<div className={leftSide && splitLocation[3] === settings?.splitLocation ? "active" : ""}>
												<Link to={settings?.to} className="pglnk">
													{/*<span className="icons"><FontAwesomeIcon icon={settings?.icon}/></span>*/}
													<span className="txt">{settings?.label}</span>
												</Link>
												{settings?.display_add_btn && leftSide && !window.location.pathname.includes(settings?.display_add_btn + "/edit/") && (
													<Link to={settings?.display_add_btn + "/add"} className="addlnk">
														<Icon.PlusLg />
													</Link>
												)}
											</div>
										)}
									</React.Fragment>
								);
							})}
					</Accordion.Body>
				</Accordion.Item>
				)}
				{menuSettingPermission?.user_role && (
				<Accordion.Item eventKey="1" onClick={() => setActiveKey("1")}>
					<Accordion.Header>
						<span className="icons">
							<FontAwesomeIcon icon={faUsers} />
						</span>{" "}
						<span className="txt">{settingsMenu?.user_role?.[0]?.header_name}</span>
					</Accordion.Header>
					<Accordion.Body>
						{settingsMenu?.user_role?.length > 0 &&
							settingsMenu?.user_role.map((settings, index) => {
								return (
									<React.Fragment key={index}>
										{settings?.permission === true && (
											<div className={leftSide && splitLocation[3] === settings?.splitLocation ? "active" : ""}>
												<Link to={settings?.to} className="pglnk">
													<span className="txt">{settings?.label}</span>
												</Link>
												{settings?.display_add_btn && leftSide && !window.location.pathname.includes(settings?.display_add_btn + "/edit/") && (
													<Link to={settings?.display_add_btn + "/add"} className="addlnk">
														<Icon.PlusLg />
													</Link>
												)}
											</div>
										)}
									</React.Fragment>
								);
							})}
					</Accordion.Body>
				</Accordion.Item>
				)}
				{menuSettingPermission?.item && (
				<Accordion.Item eventKey="2" onClick={() => setActiveKey("2")}>
					<Accordion.Header>
						<span className="icons">
							<FontAwesomeIcon icon={faList} />
						</span>{" "}
						<span className="txt">{settingsMenu?.item?.[0]?.header_name} </span>
					</Accordion.Header>
					<Accordion.Body>
						{settingsMenu?.item?.length > 0 &&
							settingsMenu?.item.map((settings, index) => {
								return (
									<React.Fragment key={index}>
										{settings?.permission === true && (
											<div className={leftSide && splitLocation[3] === settings?.splitLocation ? "active" : ""}>
												<Link to={settings?.to} className="pglnk">
													{/* <span className="icons"><FontAwesomeIcon icon={settings?.icon}/></span> */}
													<span className="txt">{settings?.label}</span>
												</Link>
												{settings?.display_add_btn && leftSide && !window.location.pathname.includes(settings?.display_add_btn + "/edit/") && (
													<Link to={settings?.display_add_btn + "/add"} className="addlnk">
														<Icon.PlusLg />
													</Link>
												)}
											</div>
										)}
									</React.Fragment>
								);
							})}
					</Accordion.Body>
				</Accordion.Item>
				)}
				{menuSettingPermission?.taxes && (
				<Accordion.Item eventKey="3" onClick={() => setActiveKey("3")}>
					<Accordion.Header>
						<span className="icons">
							<FontAwesomeIcon icon={faPercent} />
						</span>{" "}
						<span className="txt">{settingsMenu?.taxes?.[0]?.header_name}</span>
					</Accordion.Header>
					<Accordion.Body>
						{settingsMenu?.taxes?.length > 0 &&
							settingsMenu?.taxes.map((settings, index) => {
								return (
									<React.Fragment key={index}>
										{settings?.permission === true && (
											<div className={leftSide && splitLocation[3] === settings?.splitLocation ? "active" : ""}>
												<Link to={settings?.to} className="pglnk">
													{/* <span className="icons"><FontAwesomeIcon icon={settings?.icon}/></span> */}
													<span className="txt">{settings?.label}</span>
												</Link>
												{settings?.display_add_btn && leftSide && !window.location.pathname.includes(settings?.display_add_btn + "/edit/") && (
													<Link to={settings?.display_add_btn + "/add"} className="addlnk">
														<Icon.PlusLg />
													</Link>
												)}
											</div>
										)}
									</React.Fragment>
								);
							})}
					</Accordion.Body>
				</Accordion.Item>
				)}
				{menuSettingPermission?.templates && (
				<Accordion.Item eventKey="4" onClick={() => setActiveKey("4")}>
					<Accordion.Header>
						<span className="icons">
							<FontAwesomeIcon icon={faStream} />
						</span>{" "}
						<span className="txt">{settingsMenu?.templates?.[0]?.header_name}</span>
					</Accordion.Header>
					<Accordion.Body>
						{settingsMenu?.templates?.length > 0 &&
							settingsMenu?.templates.map((settings, index) => {
								return (
									<React.Fragment key={index}>
										{settings?.permission === true && (
											<div className={leftSide && splitLocation[3] === settings?.splitLocation ? "active" : ""}>
												<Link to={settings?.to} className="pglnk">
													{/* <span className="icons"><FontAwesomeIcon icon={settings?.icon}/></span> */}
													<span className="txt">{settings?.label}</span>
												</Link>
												{settings?.display_add_btn && leftSide && !window.location.pathname.includes(settings?.display_add_btn + "/edit/") && (
													<Link to={settings?.display_add_btn + "/add"} className="addlnk">
														<Icon.PlusLg />
													</Link>
												)}
											</div>
										)}
									</React.Fragment>
								);
							})}
					</Accordion.Body>
				</Accordion.Item>
				)}
				{menuSettingPermission?.payments && (
				<Accordion.Item eventKey="5" onClick={() => setActiveKey("5")}>
					<Accordion.Header>
						<span className="icons">
							<FontAwesomeIcon icon={faMoneyCheck} />
						</span>{" "}
						<span className="txt">{settingsMenu?.payments?.[0]?.header_name}</span>
					</Accordion.Header>
					<Accordion.Body>
						{settingsMenu?.payments?.length > 0 &&
							settingsMenu?.payments.map((settings, index) => {
								return (
									<React.Fragment key={index}>
										{settings?.permission === true && (
											<div className={leftSide && splitLocation[3] === settings?.splitLocation ? "active" : ""}>
												<Link to={settings?.to} className="pglnk">
													{/* <span className="icons"><FontAwesomeIcon icon={settings?.icon}/></span> */}
													<span className="txt">{settings?.label}</span>
												</Link>
												{settings?.display_add_btn && leftSide && !window.location.pathname.includes(settings?.display_add_btn + "/edit/") && (
													<Link to={settings?.display_add_btn + "/add"} className="addlnk">
														<Icon.PlusLg />
													</Link>
												)}
											</div>
										)}
									</React.Fragment>
								);
							})}
					</Accordion.Body>
				</Accordion.Item>
				)}
				{menuSettingPermission?.customer && (
				<Accordion.Item eventKey="6" onClick={() => setActiveKey("6")}>
					<Accordion.Header>
						<span className="icons">
							<FontAwesomeIcon icon={faUserTie} />
						</span>{" "}
						<span className="txt">{t("Customer")}</span>
					</Accordion.Header>
					<Accordion.Body>
						{settingsMenu?.customer?.length > 0 &&
							(true === salePermission?.invoices_create ||
								true === salePermission?.credit_notes_create ||
								true === salePermission?.proforma_invoice_create ||
								true === purchasePermission?.bills_create ||
								true === purchasePermission?.vendor_credits_create) &&
							settingsMenu?.customer.map((settings, index) => {
								return (
									<React.Fragment key={index}>
										{settings?.permission === true && (
											<div className={leftSide && splitLocation[3] === settings?.splitLocation ? "active" : ""}>
												<Link to={settings?.to} className="pglnk">
													{/*<span className="icons"><FontAwesomeIcon icon={settings?.icon}/></span>*/}
													<span className="txt">{settings?.label}</span>
												</Link>
												{settings?.display_add_btn && leftSide && !window.location.pathname.includes(settings?.display_add_btn + "/edit/") && (
													<Link to={settings?.display_add_btn + "/add"} className="addlnk">
														<Icon.PlusLg />
													</Link>
												)}
											</div>
										)}
									</React.Fragment>
								);
							})}
					</Accordion.Body>
				</Accordion.Item>
				)}
				{menuSettingPermission?.settings && (
				<Accordion.Item eventKey="7" onClick={() => setActiveKey("7")}>
					<Accordion.Header>
						<span className="icons">
							<FontAwesomeIcon icon={faTools} />
						</span>{" "}
						<span className="txt">{settingsMenu?.settings?.[0]?.header_name}</span>
					</Accordion.Header>
					<Accordion.Body>
						{settingsMenu?.settings?.length > 0 &&
							settingsMenu?.settings.map((settings, index) => {
								return (
									<React.Fragment key={index}>
										{settings?.permission === true && (
											<div className={leftSide && splitLocation[3] === settings?.splitLocation ? "active" : ""}>
												<Link to={settings?.to} className="pglnk">
													<span className="txt">{settings?.label}</span>
												</Link>
												{settings?.display_add_btn && leftSide && !window.location.pathname.includes(settings?.display_add_btn + "/edit/") && (
													<Link to={settings?.display_add_btn + "/add"} className="addlnk">
														<Icon.PlusLg />
													</Link>
												)}
											</div>
										)}
									</React.Fragment>
								);
							})}
					</Accordion.Body>
				</Accordion.Item>
				)}
				{menuSettingPermission?.integration && zatca_api_menu?.[0] && (
					<Accordion.Item eventKey="8" onClick={() => setActiveKey("8")}>
						<Accordion.Header>
							<span className="icons">
								<FontAwesomeIcon icon={faLink} />
							</span>{" "}
							<span className="txt">{settingsMenu?.integration?.[0]?.header_name}</span>
						</Accordion.Header>
						<Accordion.Body>
							{settingsMenu?.integration?.map((settings, index) => {
								return (
									<React.Fragment key={index}>
										{true === settings?.permission && (
											<div className={leftSide && splitLocation[3] === settings?.splitLocation ? "active" : ""}>
												<Link to={settings?.to} className="pglnk">
													<span className="txt">{settings?.label}</span>
												</Link>
											</div>
										)}
									</React.Fragment>
								);
							})}
						</Accordion.Body>
					</Accordion.Item>
				)}
			</Accordion>
		</>
	);
};
export default SidebarSettingsMenu;
