export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const UPDATE_USERINFO = 'UPDATE_USERINFO';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';

//report
export const UPDATE_REPORT_SEARCH = 'UPDATE_REPORT_SEARCH';

