import { secureAPI, unsecureAPI } from './API';
import { checkTokenValidate } from '../util/helper';

//create purchase order
async function createPurchaseOrder(sales_order_payload) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .post('/purchase_order', sales_order_payload)
            .then((data) => {
                if (data.data.success) {
                    resolve(data.data); //return array
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err?.response?.data);
            });
    });
}

//get all purchase order
async function getAllpurchaseOrders(searchParam = '', selectElement = '') {
    let url = '/purchase_order?status=A' + searchParam;
    if (selectElement) {
        url = `${url}&select=${selectElement}`;
    }

    return new Promise((resolve, reject) => {
        secureAPI()
            .get(url)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data); //return array
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err.response.data.error);
            });
    });
}

//get a purchase order
async function getApurchaseOrder(purchase_order_id, content_type = '', for_clone = false) {
    // content_type = content_type ? `?content_type=${content_type}` : '';

    const query_params_array = [];
    if (content_type) {
        query_params_array.push(`content_type=${content_type}`)
    }
    if (for_clone) {
        query_params_array.push(`for_clone=${for_clone}`)
    }
    let query_string = "";
    if (query_params_array.length > 0) {
        query_string = "?" + query_params_array.join("&");
    }
    return new Promise((resolve, reject) => {
        secureAPI()
            .get(`/purchase_order/${purchase_order_id}${query_string}`)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data); //return array
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err.response.data.error);
            });
    });
}

//delete a purchase order
async function deleteApurchaseOrder(purchase_order_id) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .delete(`/purchase_order/${purchase_order_id}`)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data); //return single object
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err.response.data.error);
            });
    });
}

//update a purchase order
async function updateApurchaseOrder(
    purchase_order_id,
    sales_order_payload
) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .put(`/purchase_order/${purchase_order_id}`, sales_order_payload)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data); //return single object
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err?.response?.data?.error);
            });
    });
}

// patch a single purchase order, for update only specific fields
async function patchPurchaseOrder(purchase_order_id, sales_order_payload) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .patch(`/purchase_order/${purchase_order_id}`, sales_order_payload)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data); //return single object
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err?.response?.data?.error);
            });
    });
}

//get a purchase order infomation when using public url (by jwt)
async function getPurchaseOrderByJWT(jwt, organization_id, language = 'ar') {
    return new Promise((resolve, reject) => {
        unsecureAPI()
            .get(`/purchase_order/noauth/${organization_id}?token=${jwt}&content_type=html&language=${language}`)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data); //return single object
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err.response.data.error);
            });
    });
}

//get all items from sales order for a single invoice to use
async function getAvailablePurchaseOrderItemsForBills(purchase_order_ids) {
    const purchase_order_ids_as_string = purchase_order_ids.join(",");
    return new Promise((resolve, reject) => {
        secureAPI()
            .get(`/bills/include/purchase_order_items?purchase_order_ids=${purchase_order_ids_as_string}`)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data);
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err?.response?.data?.error);
            });
    });
}

// get bills of this purchase order
async function getBIllsFromPurchaseOrder(purchase_order_id) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .get(`/purchase_order/${purchase_order_id}/bills`)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data);
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err.response.data.error);
            });
    });
}

// get items of purchase orders that we can cancel
// all items of the purchase order will be available there.
async function getItemsToCancelFromPurchaseOrder(purchase_order_id) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .get(`/purchase_order/${purchase_order_id}/for_cancel_items`)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data);
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err.response.data.error);
            });
    });
}



// cancel items of a purchase order
async function cancelItemsOfPurchaseOrder(purchase_order_id, cancel_items_payload) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .put(`/purchase_order/${purchase_order_id}/cancel_items`, cancel_items_payload)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data);
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err?.response?.data);
            });
    });
}

// cancel items of a purchase order
async function reOpenCanceledItemsOfPurchaseOrder(purchase_order_id) {
    return new Promise((resolve, reject) => {
        secureAPI()
            .put(`/purchase_order/${purchase_order_id}/reopen_cancel_items`)
            .then(({ data }) => {
                if (data.success) {
                    resolve(data.data);
                }
            })
            .catch((err) => {
                checkTokenValidate(err);
                reject(err?.response?.data);
            });
    });
}
// fetch collection template with data
async function 	getPurchaseOrderTemplateWithData(invoice_id) {  
	return new Promise((resolve, reject) => {
	  secureAPI()
		.get(`/purchase_order/${invoice_id}/email`)
		.then(({ data }) => {
		  if (data.success) {
			resolve(data.data); //return array
		  }
		})
		.catch((err) => {
            console.log(err.response.data);
		  checkTokenValidate(err);
		  reject(err.response.data.error);
		});
	});
  }

export {
    createPurchaseOrder,
    getAllpurchaseOrders,
    getApurchaseOrder,
    deleteApurchaseOrder,
    updateApurchaseOrder,
    patchPurchaseOrder,
    getPurchaseOrderByJWT,

    //create bills from purchase order
    getAvailablePurchaseOrderItemsForBills,
    getBIllsFromPurchaseOrder,

    //cancel item from purchase order
    cancelItemsOfPurchaseOrder,
    getItemsToCancelFromPurchaseOrder,
    reOpenCanceledItemsOfPurchaseOrder,
    getPurchaseOrderTemplateWithData


};
