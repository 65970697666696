import React, {  } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { showDateOrTimeFormat } from "../../../util/helper";
import { RecurringFrequencyFormatted, RecurringTransactionStatusBadge } from "./RecurrringUtils";
import { CustomNumberFormatForDisplay } from '../../../util/CustomNumberFormat';


const RecurringDetailList = ({ listData, language, parentCallBack, listType,callType }) => {
    const { t } = useTranslation();
    let { id } = useParams();
    const history = useHistory();
    const { search } = useLocation(); 

    const setClass = "search" === callType ? "list_table" : "invLst"; //for css condition
    
    /**
     * on-click function handler on list page
     */
    const reFetchRecurringInvoice = (id) => {        
        
        if ('recurring-bills' === listType) {
            history.push(`/${language}/recurring-bills/details/${id}${search}`);
        }
        if ('recurring-invoices' === listType) {
            history.push(`/${language}/recurring-invoices/details/${id}${search}`);
        }
        if ('recurring-expenses' === listType) {            
            history.push(`/${language}/recurring-expenses/details/${id}${search}`);
        }
        if(['accounting','recurring-journals'].includes(listType)){
            history.push(`/${language}/accounting/recurring-journals/details/${id}${search}`);

        }
        // set default tab to overview when call from side bar list
        // set search list false when call from search value
        parentCallBack();   

        
    }

    return (
        <>
            <table className={`w-100 overflow-hidden ${setClass}`} id="searchListTable">
                <tbody>
                    {
                        listData?.length > 0 &&
                        listData?.map((each_list, index) => (
                            <tr key={index} className={id === each_list._id ? "active" : ""}>
                                <td>
                                    {("search" !== callType) &&(
                                        <Form.Check type="checkbox" />
                                    )
                                    }
                                </td>
                                <td onClick={() => reFetchRecurringInvoice(each_list._id)}>
                                    <div className="lst-pmr d-flex justify-content-between align-items-center fw-6">
                                        <span className="linkcolor">
                                            {each_list?.profile?.profile_name}
                                        </span>
                                        <samp className="text-nowrap">
                                            <span className="d-inline-block">
                                                {/* invoice,bills,expense */}
                                                {['recurring-bills', 'recurring-expenses','recurring-invoices'].includes(listType) && (

                                                    <CustomNumberFormatForDisplay
                                                        value={each_list?.net_amount ||0}
                                                        prefix={each_list?.contact_id?.currency ?? each_list?.currency}
                                                    />
                                                )}
                                                {['recurring-journals','accounting'].includes(listType) && (

                                                    <CustomNumberFormatForDisplay
                                                        value={each_list?.total_debit_amount ||0}
                                                        prefix={each_list?.currency}
                                                    />
                                                )}
                                            </span>
                                        </samp>
                                    </div>
                                    <div className="lst-sdr">
                                        <div className="row1 d-flex justify-content-between mt-2 text-capitalize">
                                            <div className="text-truncate lh-12 fw-5"style={{maxWidth:"70%",color:"#484848"}}>
                                                {each_list?.contact_id?.contact_name?.[language]}

                                                {/* here show  expenses account name only for recurring expenses  */}
                                                
                                                {
                                                    ('recurring-expenses' === listType )&& (
                                                        each_list?.item_list?.length > 1?
                                                        t("Split")
                                                        : each_list?.item_list[0]?.expense_account_id?.title?.[language]
                                                    )

                                                }  
                                                {
                                                    ['recurring-journals','accounting'].includes(listType)  &&(
                                                        showDateOrTimeFormat(each_list?.profile?.start_date, "", false)
                                                    )
                                                }                                              
                                            </div>
                                            <aside className="invsts">                                                
                                                <RecurringFrequencyFormatted
                                                    t={t}
                                                    frequency={each_list?.profile?.frequency}
                                                    repeat_frequency={each_list?.profile?.repeat_frequency} />
                                            </aside>
                                        </div>
                                        {/* next invoice date show only when transaction status is active*/}
                                        <div className="row2 d-flex justify-content-between mt-2">
                                            {/* {
                                                ('recurring-invoices' === listType && 'active' === each_list?.transaction_status) && (

                                                    <span>{t('Next invoice on')}:<bdo dir='ltr'>{showDateOrTimeFormat(each_list?.profile?.next_invoice_date, "", false)}</bdo></span>

                                                )
                                            } */}
                                            {/* FOR OTHER THAN INVOICE */}
                                            {
                                                (['recurring-invoices','recurring-bills', 'recurring-expenses','recurring-journals','accounting'].includes(listType)) && (
                                                    <span>
                                                        {('recurring-bills' === listType) ?
                                                        t('Next bill on') :('recurring-journals'=== listType)?
                                                        t('Next journal on'):('recurring-invoices'=== listType)?
                                                        t('Next invoice on'): t('Next expense on')} 
                                                        <bdo dir='ltr'>{showDateOrTimeFormat(each_list?.profile?.next_invoice_date, "", false)}</bdo>
                                                    </span>
                                                )
                                            }
                                            <span>
                                                {/* todo : other status need to consider */}
                                                <RecurringTransactionStatusBadge transactionStatus={each_list?.transaction_status} t={t} />
                                            </span>
                                        </div>
                                    </div>
                                </td>

                            </tr>
                        ))
                    }
                    {"search" === callType && listData?.length === 0 && (
						<tr>
							<td className="text-center">{t("No result found")}</td>
						</tr>
					)}
                </tbody>
            </table>
        </>
    );
};

export default RecurringDetailList;
