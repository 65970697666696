import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
// import {createStateSyncMiddleware,initStateWithPrevTab} from 'redux-state-sync';

const middleware = [thunk];
// const config = {
	// TOGGLE_TODO will not be triggered in other tabs
	// blacklist: ['SET_LANGUAGE', '&_RECEIVE_INIT_STATE'],
	// Only 'TOGGLE_TODO' will be triggered in other tabs
	// whitelist: ['TOGGLE_TODO'],
// };
// const middleware = [createStateSyncMiddleware(config)];

const store = createStore(
	rootReducer,
	{},
	composeWithDevTools(applyMiddleware(...middleware)) //createStateSyncMiddleware
);

// initStateWithPrevTab(store);


export default store;
