import React, { useState, useEffect } from "react";
//for validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Row, Col, Form, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { createSalesPerson, getASalesPerson, updateASalesPerson } from "./../../../services/salesPerson.service";
import { showAlert } from "../../../util/alertModal";

import { useSelector } from "react-redux";
import "./../../../translations/i18n";
import { useTranslation } from "react-i18next";
import PageTitle from "../../../common/PageTitle";
import { closeQuickCreateMenu } from "./../../../util/helper";
// import { Loading } from "../../../common";

const AddSalesPerson = ({ isModal = false, onSave, onCancel, commonitemid = null }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { id } = useParams();
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [salespersons, setSalespersons] = useState({});
	const heading = id ? t("Edit sales person") : t("New sales person");
	const language = useSelector((state) => state.auth.language);
	const [loadingItems, setLoadingItems] = useState(false);
	//form validation scheme
	const salespersonValidationSchema = Yup.object().shape({
		spname: Yup.string().required(t("Please enter salesperson name")),
		email: Yup.string().required(t("Please enter email")).email(t("Email is invalid")),
	});

	//use form hook
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(salespersonValidationSchema),
	});

	//populate all field if it has id in params
	const populateFieldWithData = async () => {
		if (commonitemid) { //id || 
			try {
				const fetch_id = id ?? commonitemid;
				setLoadingItems(true);
				let salesperson_response = await getASalesPerson(fetch_id);
				if (salesperson_response) {
					setSalespersons(salesperson_response);
				}
				//set the value for validation
				setValue("spname", salesperson_response?.name);
				setValue("email", salesperson_response?.email);
				setLoadingItems(false);
			} catch (err) {
				setLoadingItems(false);
				showAlert(t("Something wrong happened!"), true);
			}
		}
	};

	useEffect(() => {
		closeQuickCreateMenu();
		populateFieldWithData();
	}, []);

	const insertSalespersonHandler = async (data, event) => {
		event.preventDefault();
		setIsButtonLoading(true); //add loading class into save button
		let authPayload = {
			name: data.spname,
			email: data.email,
		};

		try {
			let return_data;
			if (!commonitemid) {
				// !id &&
				let sales_person_res = await createSalesPerson(authPayload);
				if (sales_person_res) {
					return_data = sales_person_res;
					showAlert(t("Sales person is added successfully"));
				}
			} else {
				const update_id = id ?? commonitemid;
				let update_salesperson_res = await updateASalesPerson(update_id, authPayload);
				if (update_salesperson_res) {
					return_data = update_salesperson_res;
					showAlert(t("Sales person updated successfully"));
				}
			}
			//common section
			if (isModal && return_data) {
				setIsButtonLoading(false);
				reset(); //clear form
				onSave(return_data);
			} else {
				history.push(`/${language}/settings/salesperson`);
			}
		} catch (err) {
			setIsButtonLoading(false);
			showAlert(t(err), true);
		}
	};

	return (
		<>
			<PageTitle title={t(heading)} description="Add new Sales person" />
			{/* {loadingItems && <Loading loadClass="onlycont" />} */}
			<div className="addSalesperson">
				<header className="d-flex justify-content-between pg-header align-items-center mxwdth p-4">
					<div>
						<h4 className="mb-0">{heading}</h4>
					</div>
				</header>
				<main className="mainconts pb-5">
					{/* <Form onSubmit={handleSubmit(insertSalespersonHandler)}> */}
					<Form
						onSubmit={(e) => {
							e.stopPropagation();
							return handleSubmit(insertSalespersonHandler)(e);
						}}
					>
						<section className="contsbox p-4 pt-0">
							<div className="frmbox newfrmv2 frwdt">
								<Row xs={1} md={2}>
									<Col>
										<Form.Group className="frmfld pb-1">
											<Form.Label>
												{t("Name")}
												<i>*</i>
											</Form.Label>
											<section>
												<Form.Control
													defaultValue={id && salespersons?.name}
													type="text"
													name="spname"
													{...register("spname")}
													className={`${errors.spname ? "is-invalid" : ""}`}
												/>
												<div className="invalid-feedback">{errors.spname?.message}</div>
											</section>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="frmfld pb-1">
											<Form.Label>
												{t("Email")}
												<i>*</i>
											</Form.Label>
											<section>
												<Form.Control
													defaultValue={id && salespersons?.email}
													type="email"
													name="email"
													{...register("email")}
													className={` ${errors.email ? "is-invalid" : ""}`}
												/>
												<div className="invalid-feedback">{errors.email?.message}</div>
											</section>
										</Form.Group>
									</Col>
								</Row>
							</div>
							<section className="ftrstkpnl nofix noshw p-0 pt-2">
								<Button variant="primary" type="submit" className={`thm2 ${isButtonLoading && "loading"}`} disabled={isButtonLoading}>
									{t("Save")}
								</Button>{" "}
								<Button
									variant="secondary"
									type="button"
									onClick={() => {
										if (isModal) {
											onCancel();
										} else history.push(`/${language}/settings/salesperson`);
									}}
								>
									{t("Cancel")}
								</Button>
							</section>
						</section>
					</Form>
				</main>
			</div>
		</>
	);
};
export default AddSalesPerson;
