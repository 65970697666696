import { secureAPI} from './API';
import { checkTokenValidate } from '../util/helper';

//get all expense
async function getAllExpense(selectElement = null, searchParam = ''){
    let url = '/expense?status=A' + searchParam;
      if (selectElement) {
          url = `${url}&select=${selectElement}`;
      }
      return new Promise((resolve, reject) => {
          secureAPI()
              .get(url)
              .then(({ data }) => {
                  if (data.success) {
                      resolve(data); //return array
                  }
              })
              .catch((err) => {
                  checkTokenValidate(err);
                  reject(err.response.data.error);
              });
      });
  }
//Expense create
async function createExpense(payload) {
    return new Promise((resolve, reject) => {
      secureAPI()
      .post('/expense', payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
    });
}
//Expense delete
async function expenseDelete(expenseId) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/expense/${expenseId}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
// get one Expense based on id
async function getAExpense(id, content_type = '') {
	content_type = content_type ? `?content_type=${content_type}` : '';
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/expense/${id}${content_type}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//Update Expense
async function updateExpense(payload, id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/expense/${id}`, payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

// patch a single Expense
async function patchExpense(expenseId, expense_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/expense/${expenseId}`, expense_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err?.response?.data?.error);
			});
	});
}

export { getAllExpense, createExpense, expenseDelete, getAExpense, updateExpense, patchExpense};