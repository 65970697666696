import axios from 'axios';

function getTimeZone() {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2);
}

function secureAPI() {
  let time_zone = getTimeZone(); //Intl.DateTimeFormat().resolvedOptions().timeZone;
  let lang = window.location.pathname.split('/')[1]; //fetch language from url en or ar
  let language = lang ? lang : localStorage.getItem('language'); //from store
  return axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'time-zone': time_zone,
      language, //TODO: need to work on this
    },
  });
}

function unsecureAPI() {
  let time_zone = getTimeZone();
  return axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    headers: { 'Content-Type': 'application/json', 'time-zone': time_zone, },
  });
}

function unsecureDownloadAPI() {
  let time_zone = getTimeZone();
  return axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    headers: { 'Content-Type': 'application/json', 'time-zone': time_zone, },
    responseType: 'blob', //important, must be type blob to succefully reassable.
  });
}

function secureDownloadAPI() {
  let time_zone = getTimeZone();
  return axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'time-zone': time_zone
    },
    responseType: 'blob',
  });
}

//this function used for retrn response
function responseAPI(apiPath, body) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`${apiPath}`, body)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        reject(err.response.data.error);
      });
  });
}
//email endpoint with file attachment
function secureEmailAPI(body) {
  let time_zone = getTimeZone();
  return axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'time-zone': time_zone
    },
  });
}

function secureUploadAPI() {
  let time_zone = getTimeZone();
  return axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'time-zone': time_zone
    },
  });
}
/****************** SUPPORT TICKET API ******************************/
function secureSupportAPI() {
  let time_zone = getTimeZone();//Intl.DateTimeFormat().resolvedOptions().timeZone;
  return axios.create({
    baseURL: `${process.env.REACT_APP_SUPPORT_BASE_API_URL}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `CustomerBearer ${localStorage.getItem('token')}`,
      'time-zone': time_zone,
      ServiceProvider: 'Aninvoice'
    },
  });
}

function secureAPICSRF() {
  let time_zone = getTimeZone(); //Intl.DateTimeFormat().resolvedOptions().timeZone;
  let lang = window.location.pathname.split('/')[1]; //fetch language from url en or ar
  let language = lang ? lang : localStorage.getItem('language'); //from store
  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'time-zone': time_zone,
      language, //TODO: need to work on this
    },
    withCredentials: true, // Include cookies in requests
  });

  // Interceptor to include the CSRF token in all requests
  axiosInstance.interceptors.request.use(
    (config) => {
      console.log('CSRF token:', document.cookie);
      const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('XSRF-TOKEN='))
        ?.split('=')[1];

      if (csrfToken) {
        config.headers['X-XSRF-TOKEN'] = csrfToken; // Attach the CSRF token
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return axiosInstance;
}



export {
  secureAPI,
  unsecureAPI,
  responseAPI,
  secureDownloadAPI,
  unsecureDownloadAPI,
  secureEmailAPI,
  secureUploadAPI,
  secureSupportAPI,
  getTimeZone,
  secureAPICSRF
};
