import { secureAPI } from './API';
import {checkTokenValidate, isNotEmpty} from '../util/helper';

const basePath = '/contacts';
//get opening balance of a contact (vendor or customer)
async function getOpeningBalance(contact_type, contact_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`${basePath}/${contact_id}/opening_balance?contact_type=${contact_type}`)
      .then((data) => {
        if (data.data.success) {
          resolve(data.data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

//update opening balance of a contact (vendor or customer)
async function updateOpeningBalanceOfContact(contact_id,payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`${basePath}/${contact_id}/opening_balance/update`, payload)
      .then((data) => {
        if (data.data.success) {
          resolve(data.data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

// both customer and vendor all unused credits 
async function getDueContactCredits(contact_id,filter = '') {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`${basePath}/${contact_id}/unused_credits${filter}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//get partials paid or unpaid bills (of vendor)
async function getOutstandingBills(contact_id, query = '') {
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`${basePath}/${contact_id}/outstanding_bills${query}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}


async function getDueCreditNotesOfContact({contact_id, query_string=""}) {
	const qs = isNotEmpty(query_string) ? `?${query_string}` : '';
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`${basePath}/${contact_id}/due_credit_notes${qs}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function getDuePaymentsOfContact({contact_id, query_string=""}) {
	const qs = isNotEmpty(query_string) ? `?${query_string}` : '';
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`${basePath}/${contact_id}/due_payments${qs}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function getDueVendorCreditsOfContact({contact_id, query_string=""}) {
	const qs = isNotEmpty(query_string) ? `?${query_string}` : '';
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`${basePath}/${contact_id}/due_vendor_credits${qs}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
// refresh contact balance or update contact balance
async function refreshOrUpdateContactBalance({ contact_id }) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`${basePath}/${contact_id}/update_contact_balance`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}


export { getOpeningBalance, updateOpeningBalanceOfContact, getDueContactCredits, getOutstandingBills, getDueCreditNotesOfContact, getDuePaymentsOfContact, getDueVendorCreditsOfContact, refreshOrUpdateContactBalance };