import {  secureAPI } from './API';
import { checkTokenValidate } from '../util/helper';


/*
get all country or currency list
return promise
resolve : list of objects
*/
async function getCountryCurrencies(searchParam = '') {
  let url = '/country_currency?status=A' + searchParam;
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
export {
  getCountryCurrencies,  
};
