// import 'alertifyjs/build/css/alertify.css';
// import 'alertifyjs/build/css/alertify.rtl.css';

import 'alertifyjs/build/css/themes/bootstrap.min.css';
// import * as alertify from 'alertifyjs';
import * as Icon from 'react-bootstrap-icons';
import {showAlertNotification} from "./helper";

function showAlert(message, isFailed = false, timeout = 4) {
	// alertify.set('notifier', 'position', 'top-center');
	// let type = isFailed ? 'error' : 'success';
	// alertify.notify(message, type, timeout, function () {});

	const alert_type = isFailed ? "fail" : "success"
	showAlertNotification(message,alert_type,{timer: timeout})

}
//common info model for show the information each user input
const showTooltipInfo = (text, positionClass='') => {
	return (
		<>
			<article className={'popinfo '+positionClass}>
				<div>
					<Icon.InfoCircle />
					<span>{text}</span>
				</div>
			</article>
		</>
	);
};
export { showAlert, showTooltipInfo };
