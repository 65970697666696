import { secureAPI } from './API';
import { checkTokenValidate } from './../util/helper';

// fetch all payments
async function getAllPayments(fillter = '', selectElement = '') {
  let url = '/payments?status=A' + fillter;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }

  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
// fetch a payment
async function getAPayment(payment_id, content_type = '') {
  content_type = content_type ? `&content_type=${content_type}` : '';
  let language = localStorage.getItem('language');
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/payments/${payment_id}?language=${language}${content_type}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
//create a payment
async function createPayment(payment_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post('/payments', payment_payload)
      .then((data) => {
        if (data.data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
// update a payment
async function updateAPayment(payment_id, payment_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/payments/${payment_id}`, payment_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
// add attachment to payment
async function patchPayment(payment_id, payment_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/payments/${payment_id}/attachment`, payment_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
// delete a payment
async function deleteAPayment(payment_id, payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .delete(`/payments/${payment_id}`, { data: payload })
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

//Patch   used n popup   apply payment in bill
async function paymentApply(payment_id, payment_payload) {
  return new Promise((resolve, reject) => {    
    secureAPI()
      .patch(`/payments/${payment_id}/apply_on_bills`, payment_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
//remove a payment from a bill
async function deletePaymentFromBill(payment_id, payment_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/payments/${payment_id}/delete_from_bill`, payment_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
//patchPaymentTemplate used to update the pdf_template
async function patchPaymentTemplate(payment_id, payment_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/payments/${payment_id}/template`, payment_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

// fetch payment template with data
async function 	getPaymentTemplateWithData(invoice_id) {  
	return new Promise((resolve, reject) => {
	  secureAPI()
		.get(`/payments/${invoice_id}/email`)
		.then(({ data }) => {
		  if (data.success) {
			resolve(data.data); //return array
		  }
		})
		.catch((err) => {
		  checkTokenValidate(err);
		  reject(err.response.data.error);
		});
	});
  }

//create payment refund
async function createRefundAPayment(payment_id, refund_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`/payments/${payment_id}/refunds`, refund_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//get single payment refund
async function getARefundFromPayment(payment_id,refund_id){
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/payments/${payment_id}/refunds/${refund_id}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
} 

//edit single payment refund
async function updateRefundsToPayment(payment_id,refund_id,refund_payload){

	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/payments/${payment_id}/refunds/${refund_id}`, refund_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//delete payment refund
async function deleteRefundsFromPayment(payment_id,refund_id){
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/payments/${payment_id}/refunds/${refund_id}`,{})
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
} 

export {
  getAllPayments,
  getAPayment,
  createPayment,
  updateAPayment,
  patchPayment,
  deleteAPayment,
  paymentApply,
  deletePaymentFromBill,
  patchPaymentTemplate,
  getPaymentTemplateWithData,
  createRefundAPayment,
  getARefundFromPayment,
  updateRefundsToPayment,
  deleteRefundsFromPayment,
};
