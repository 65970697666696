import { secureAPI } from './API';
import { checkTokenValidate } from '../util/helper';

/** 
 * @description createRecurringJournal function used
 * to create a new recurring journal
 * call from browser
 * 
*/
async function createRecurringJournal(recurring_journal_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post('/recurring_journals', recurring_journal_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

/**
 * @description getAllRecurringJournals function used to
 * fetch all recurring journal 
 * call from browser
 * 
 */
async function getAllRecurringJournals(searchParam = '', selectElement = '') {
  let url = '/recurring_journals?status=A' + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }

  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/**
 * @description getARecurringJournal function used to 
 * fetch a single recurring journal
 * call from browser
 */
async function getARecurringJournal(id,content_type='') {  
     
  content_type = content_type ? `?content_type=${content_type}` : '';
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/recurring_journals/${id}${content_type}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/**
 * @description patchRecurringJournal function used to 
 * fetch a single recurring journal
 * call from browser
 */
async function patchRecurringJournal(id, journal_payload) {       
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/recurring_journals/${id}`, journal_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data?.error);
      });
  });
}

/**
 * @description updateARecurringJournal function used to 
 * update a single recurring journal
 * call from browser
 */
async function updateARecurringJournal(id, journal_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/recurring_journals/${id}`, journal_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

/**
 * @description updateARecurringJournal function used to 
 * update a single recurring journal
 * call from browser
 */
async function deleteARecurringJournal(id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .delete(`/recurring_journals/${id}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

/**
 * @description updateARecurringJournal function used to 
 * update a single recurring journal
 * call from browser
 */
async function toggleTransactionStatusOfARecurringJournal(id,status='') {
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/recurring_journals/${id}/transaction_status?status=${status}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}



/**
 * @description getAllChildJournals function used to 
 * fetch all child journals of recurring journal according to payment_status
 * by default payment_status :=> all
 * call from browser
 * 
 */

async function getAllChildJournals({id,searchParam = '', selectElement = '',payment_status=''}) { 
  
  let url = `/recurring_journals/${id}/journals?status=A&payment_status=${payment_status}` + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }
  try {
    const response = await secureAPI().get(url);
    if (response.data.success) {
      return response.data;
    }
  } catch (err) {
    checkTokenValidate(err);
    throw new Error(err.response.data.error);
  }
}

export {
  createRecurringJournal,
  getAllRecurringJournals,
  getARecurringJournal,
  patchRecurringJournal,
  updateARecurringJournal,
  deleteARecurringJournal,
  toggleTransactionStatusOfARecurringJournal,
  getAllChildJournals,
}