import { secureAPI, unsecureAPI, secureUploadAPI } from './API';
import { checkTokenValidate } from './../util/helper';

/*
Invoice service api caller
*/
async function createInvoice(invoice_payload) {

  const formData = new FormData();
  // add invoice_payload to form data
  if (invoice_payload && Object.keys(invoice_payload).length > 0) {
    formData.append('json_string', JSON.stringify({ ...invoice_payload }));
  }
  return new Promise((resolve, reject) => {
    secureAPI()
      .post('/invoices', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

async function getAllInvoices(searchParam = '', selectElement = '') {
  //fillter
  let url = '/invoices?status=A' + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }

  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function getAnInvoice(invoice_id, content_type='') {
  content_type = content_type ? `?content_type=${content_type}` : '';
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/invoices/${invoice_id}${content_type}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function deleteAnInvoice(invoice_id,commentPayload={}) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .delete(`/invoices/${invoice_id}`,{data:commentPayload})  
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function updateAnInvoice(invoice_id, invoice_payload) {
  const formData = new FormData();
  // add invoice_payload to form data
  if (invoice_payload && Object.keys(invoice_payload).length > 0) {
    formData.append('json_string', JSON.stringify({ ...invoice_payload }));
  }
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/invoices/${invoice_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}

// patch a single invoice, for update only specific fields
async function patchInvoice(invoice_id, invoice_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/invoices/${invoice_id}`, invoice_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data?.error);
      });
  });
}

//get a invoice infomation when using public url (by jwt)
async function getInvoiceByJWT(jwt, organization_id, language='ar') {
  return new Promise((resolve, reject) => {
    unsecureAPI()
      .get(`/invoices/noauth/${organization_id}?token=${jwt}&content_type=html&language=${language}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/* DANGER */
// async function showDeleteInvoice() {
//   return new Promise((resolve, reject) => {
//     secureAPI()
//       .get(`/invoices?status=D`)
//       .then(({ data }) => {
//         if (data.success) {
//           resolve(data.data); //return single object
//         }
//       })
//       .catch((err) => {
//         checkTokenValidate(err);
//         reject(err.response.data.error);
//       });
//   });
// }

//get all the payments based on invoice id
async function getAllCollectionByInvoiceId(invoiceId) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/invoices/${invoiceId}/collections`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data?.error);
      });
  });
}
//get all the credit notes applied in a particular invoice id
async function getAllCreditNoteAppliedByInvoiceId(invoiceId) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/invoices/${invoiceId}/credit_notes_applied`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data?.error);
      });
  });
}

//No NEED REMOVE IT
// async function getDueInvoices(fillter = '') {
//   return new Promise((resolve, reject) => {
//     secureAPI()
//       .get('/invoices/get_due_invoices' + fillter)
//       .then(({ data }) => {
//         if (data.success) {
//           resolve(data); //return array
//         }
//       })
//       .catch((err) => {
//         checkTokenValidate(err);
//         reject(err.response.data.error);
//       });
//   });
// }

// async function getOutstandingInvoices(fillter = '') {
//   return new Promise((resolve, reject) => {
//     secureAPI()
//       .get('/invoices/get_outstanding_invoices' + fillter)
//       .then(({ data }) => {
//         if (data.success) {
//           resolve(data); //return array
//         }
//       })
//       .catch((err) => {
//         checkTokenValidate(err);
//         reject(err.response.data.error);
//       });
//   });
// }

// get all summary amount for invoice
async function getInvoiceSummaryAmount() {
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/invoices/get_invoice_summary`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

///////////bulk import///////////
async function invoicesImportUpload(file) {
  return new Promise((resolve, reject) => {
    secureUploadAPI()
      .post(`/invoices/import/upload`, file)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

//after mapping, send the mapped keys to the server
//for validation , it return validated data and other information
async function invoicesImportPreview(file_id, payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post(`/invoices/import/preview/${file_id}`, payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function invoicesImport(file_id, payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post(`/invoices/import/${file_id}`, payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

//write off an invoice
async function writeOffInvoice(invoice_id, write_off_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post(`/invoices/${invoice_id}/writeoff`, write_off_payload)
      .then((data) => {
        if (data.data.success) {
          resolve(data.data); 
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}
//cancel write off of an invoice
async function cancelWriteOffInvoice(invoice_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/invoices/${invoice_id}/writeoff/cancel`,)
      .then((data) => {
        if (data.data.success) {
          resolve(data.data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}


// update invoice balance from invoice details page
async function updateinvoicebalance(invoice_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/invoices/${invoice_id}/update_invoice_balance`,)
      .then((data) => {
        if (data.data.success) {
          resolve(data.data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data);
      });
  });
}


//get all items from sales order for a single invoice to use
async function getAvailableSalesOrderItemsForInvoice(sales_order_ids) {
  const sales_order_ids_as_string = sales_order_ids.join(",");
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/invoices/include/sales_order_items?sales_order_ids=${sales_order_ids_as_string}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data?.error);
      });
  });
}

// get details of all sales order that are associated with this invoice.
async function getAssociatedSalesOrdersOfInvoice(invoice_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/invoices/${invoice_id}/sales_orders`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data?.error);
      });
  });
}

// attach a sales order with an invoice
async function linkSalesOrderWithInvoice(invoice_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/invoices/${invoice_id}/sales_orders/link_sales_order`,)
      .then(({data}) => {
        if (data.success) {
          resolve(data);
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data.error);
      });
  });
}

// fetch invoice template with data
async function getInvoiceTemplateWithData(invoice_id) {  
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/invoices/${invoice_id}/email`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

export {
  createInvoice,
  getAnInvoice,
  getAllInvoices,
  updateAnInvoice,
  deleteAnInvoice,
  getInvoiceByJWT,
  getAllCollectionByInvoiceId,
  // getDueInvoices,
  // getOutstandingInvoices,
  getAllCreditNoteAppliedByInvoiceId,
  patchInvoice,
  getInvoiceSummaryAmount,

  //invoice import
  invoicesImportUpload,
  invoicesImportPreview,
  invoicesImport,

  //invoice write off
  writeOffInvoice,
  cancelWriteOffInvoice,
  //invoice update balance form details page
  updateinvoicebalance,

  // invoice - sales order
  getAvailableSalesOrderItemsForInvoice,
  getAssociatedSalesOrdersOfInvoice,
  linkSalesOrderWithInvoice,
  getInvoiceTemplateWithData
};
