import { secureAPI } from './API';
import { checkTokenValidate } from './../util/helper';
/*
Credit notes service api caller
*/

const basePath = '/credit_notes';

async function createCNote(cnote_payload) {
  const formData = new FormData();
  // add cnote_payload to form data
  if (cnote_payload && Object.keys(cnote_payload).length > 0) {
    formData.append('json_string', JSON.stringify({ ...cnote_payload }));
  }
  return new Promise((resolve, reject) => {
    secureAPI()
      .post('/credit_notes', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((data) => {
        if (data.data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function getAllCNotes(searchParam = '', selectElement = '') {
  //fillter
  let url = '/credit_notes?status=A' + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }

  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function getACNote(cnote_id, content_type='') {
  content_type = content_type ? `?content_type=${content_type}` : '';
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/credit_notes/${cnote_id}${content_type}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function deleteCNote(cnote_id,commentPayload={}) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .delete(`/credit_notes/${cnote_id}`,{data:commentPayload})
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function updateACNote(cnote_id, cnote_payload) {
  const formData = new FormData();
  // add cnote_payload to form data
  if (cnote_payload && Object.keys(cnote_payload).length > 0) {
    formData.append('json_string', JSON.stringify({ ...cnote_payload }));
  }
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/credit_notes/${cnote_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

// patch a single invoice, for update only specific fields @arijit
async function patchCreditNote(creditNote_id, creditNote_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/credit_notes/${creditNote_id}`, creditNote_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err?.response?.data?.error);
      });
  });
}


async function applyCreditNoteAtInvoices(cnote_payload) {
  let cid = cnote_payload._id;
  delete cnote_payload._id;
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/credit_notes/${cid}/apply_on_invoices`, cnote_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

//Delete invoice from credit notes @asif
async function deleteInvoiceFromCNote(credit_note_id, playload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/credit_notes/${credit_note_id}/delete_from_invoice`, playload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
// fetch credit note template with data
async function getCreditNoteTemplateWithData(invoice_id) {  
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/credit_notes/${invoice_id}/email`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}






/**------------ Refund related routes--------------- */
async function createRefundOfCreditNote(collection_id, refund_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
        .post(`${basePath}/${collection_id}/refunds`, refund_payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data); //return single object
          }
        })
        .catch((err) => {
          checkTokenValidate(err);
          reject(err.response.data.error);
        });
  });
}
async function getRefundOfCreditNote(collection_id,refund_id){
  return new Promise((resolve, reject) => {
    secureAPI()
        .get(`${basePath}/${collection_id}/refunds/${refund_id}`)
        .then(({ data }) => {
          if (data.success) {
            resolve(data); //return single object
          }
        })
        .catch((err) => {
          checkTokenValidate(err);
          reject(err.response.data.error);
        });
  });
}
async function updateRefundOfCreditNote(collection_id,refund_id,refund_payload){

  return new Promise((resolve, reject) => {
    secureAPI()
        .put(`${basePath}/${collection_id}/refunds/${refund_id}`, refund_payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data); //return single object
          }
        })
        .catch((err) => {
          checkTokenValidate(err);
          reject(err.response.data.error);
        });
  });
}
async function deleteRefundOfCreditNote(collection_id,refund_id){
  return new Promise((resolve, reject) => {
    secureAPI()
        .delete(`${basePath}/${collection_id}/refunds/${refund_id}`,{})
        .then(({ data }) => {
          if (data.success) {
            resolve(data); //return single object
          }
        })
        .catch((err) => {
          checkTokenValidate(err);
          reject(err.response.data.error);
        });
  });
}
/**----------- End of Refund related routes----------- */

export {
  createCNote,
  getACNote,
  getAllCNotes,
  updateACNote,
  deleteCNote,
  applyCreditNoteAtInvoices,
  patchCreditNote,
  deleteInvoiceFromCNote,
  getCreditNoteTemplateWithData,

  // refunds
  createRefundOfCreditNote,
  getRefundOfCreditNote,
  updateRefundOfCreditNote,
  deleteRefundOfCreditNote
};
