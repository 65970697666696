import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import './../translations/i18n';
import { useTranslation } from 'react-i18next';
import { userLanguage } from '../util/helper';
import { patchCustomer } from '../services/customer.service';
import { patchVendor } from '../services/vendor.service';
import alertify from 'alertifyjs';

//used as a modal/pop up
//allReportingTags - all tags that a fetch from the server (Array)
//selectedTags -that are specified within an item (Array)
//onSave - parent callback (Function)
//callServiceInside - will call the related patch service inside
const AssignReportTagsModal = ({ allReportingTags = [], selectedTags = [], onSave, colspan, rowIndex }) => {
	const [rpttgshow, setrpttgShow] = useState();
	const { t } = useTranslation();
	const language = useSelector((state) => state.auth.language);
	const fieldLanguage = userLanguage(language); //en or ar
	const [tagList, setTagList] = useState([]); //will loop through all tags and create a list of tags
	const [selectPrgMessage, setSelectPrgMessage] = useState(''); //show text how many has been selected out of how many

	//tag_index (or root index) is index of the dropdown
	const handleChange = (tag_index) => (ev) => {
		const { value } = ev.target;
		let new_tags_array = [...tagList];
		new_tags_array[tag_index].option_id = value;
		setTagList(new_tags_array);
	};

	//handle save button click
	//trim out the selection payload and send it to the parent
	//fillter out entries with empty "option_id"
	//remove "tag_name", "options" from the payload
	const handleSave = async () => {
		let rp_tags_payload = tagList.filter((tag) => tag.option_id !== '').map((tag) => ({ tag_id: tag.tag_id, option_id: tag.option_id }));

		onSave?.(rp_tags_payload);
		setrpttgShow(false); //close the modal
	};

	//map out data to set the dropdowns with a value selected
	useEffect(() => {
		let mapped_tags = allReportingTags.map((tag) => {
			let tag_name = tag.tag_name?.[fieldLanguage];
			let tag_id = tag._id;
			let options = tag.options?.map((option) => {
				//"options" are options under a tag
				return {
					label: option?.[fieldLanguage],
					value: option._id,
				};
			});

			// set selected option_id
			let option_id = '';
			if (selectedTags.length > 0) {
				let select_root = selectedTags.find((selected) => selected.tag_id === tag_id); //find the root tag
				option_id = select_root?.option_id ?? ''; //get the option of the root
			}

			return {
				tag_name,
				tag_id,
				options,
				option_id,
			};
		});
		setTagList(mapped_tags);

		//set the message to show how many has been selected out of how many
		const message = `${selectedTags.length} ${t('out of')} ${allReportingTags.length} ${t('selected')}`; //show text how many has been selected out of how many
		setSelectPrgMessage(message);
	}, [allReportingTags, selectedTags, fieldLanguage]);

	//hide popup
	const closeReportingTagPopup = (e) => {
		//!important - need to fix this code, it is working but not the best way to close the modal
		// let allDivTd = document.getElementById('invoiceTable').getElementsByClassName('rpttg_drop');
		// let allDivTdDR = document.getElementById('invoiceTable').getElementsByClassName('dropdown');
		// console.log('allDivTd', allDivTd);
		// for (let i = 0; i < allDivTd.length; i++) {
		// 	allDivTd[i].classList.remove('active');
		// }
		// for (let i = 0; i < allDivTdDR.length; i++) {
		// 	allDivTdDR[i].classList.remove('show');
		// }

		setrpttgShow(!rpttgshow);
	};

	return (
		// <tr className="bottom">
		<td colSpan={colspan}>
			<div className={`rpttg_drop${rpttgshow ? ' active' : ''}`} id="tagPopup">
				<Button variant="link p-0 nobdr noline lh-1" onClick={(e) => closeReportingTagPopup()}>
					<Icon.TagFill /> {t('Report tag')}
					<span>: {selectPrgMessage}</span>
				</Button>
				<div className={`dropdown${rpttgshow ? ' show' : ''}`}>
					<section>
						<h6>{t('Report tag')}</h6>
						<div className="p-2">
							<Row>
								{tagList.map((tag, tag_index) => (
									<Col key={`tag${tag_index}`} sm={6} className="pb-3">
										<Form.Label className="mb-1">{tag?.tag_name ?? ''}</Form.Label>
										<Form.Select className="dflt" value={tag?.option_id ?? ''} onChange={handleChange(tag_index)}>
											<option value={''}>--{t('Select')}--</option>
											{tag?.options?.map((opt, option_index) => (
												<option key={`tagoption${tag_index}-${option_index}`} value={opt?.value ?? ''}>
													{opt?.label}
												</option>
											))}
										</Form.Select>
									</Col>
								))}
							</Row>
							<Row>
								<Col>
									<Button variant="success" size="sm" className="thm2" onClick={handleSave}>
										{t('Save')}
									</Button>{' '}
									<Button variant="secondary" type="button" size="sm" onClick={() => closeReportingTagPopup()}>
										{t('Cancel')}
									</Button>
								</Col>
							</Row>
						</div>
					</section>
				</div>
			</div>
		</td>
		// </tr>
	);
};

//use inside form
// onSave, onCancel, isModal = false, contact_type -- are modal related props
const AssignReportTagsForm = ({
	allReportingTags = [],
	selectedTags = [],
	onChange,
	onSave,
	onCancel,
	isModal = false,
	contactDetails,
}) => {
	const { t } = useTranslation();
	const language = useSelector((state) => state.auth.language);
	const fieldLanguage = userLanguage(language); //en or ar
	const [tagList, setTagList] = useState([]); //will loop through all tags and create a list of tags
	const [isButtonLoading, setIsButtonLoading] = useState(false);

	//tag_index (or root index) is index of the dropdown
	const handleChange = (tag_index) => (ev) => {
		const { value } = ev.target;
		let new_tags_array = [...tagList];
		new_tags_array[tag_index].option_id = value;

		let rp_tags_payload = tagList
			.filter((tag) => tag.option_id !== '')
			.map((tag) => ({ tag_id: tag.tag_id, option_id: tag.option_id }));

		if (isModal) {
			setTagList(new_tags_array);
		} else {
			onChange?.(rp_tags_payload);
		}
	};

	const handleSave = async () => {
		let rp_tags_payload = tagList
			.filter((tag) => tag.option_id !== '')
			.map((tag) => ({ tag_id: tag.tag_id, option_id: tag.option_id }));

		try {
			const payload = {
				patch_type: 'reporting_tags_edit',
				patch_payload: rp_tags_payload,
			};
			const contact_type = contactDetails.contact_type;
			const contact_id = contactDetails._id;
			let patchContactFn;

			if ('C' === contact_type) {
				patchContactFn = patchCustomer;
			} else if ('V' === contact_type) {
				patchContactFn = patchVendor;
			}
			setIsButtonLoading(true);
			const contact_patch_res = await patchContactFn(contact_id, payload);
			setIsButtonLoading(false);
			if (contact_patch_res) {
				onSave?.(contact_patch_res);
			}
		} catch (error) {
			console.error('some error while updating reporting tags', error);
			setIsButtonLoading(false);
		}

		// onSave?.(rp_tags_payload);
	};

	const handleClose = () => {
		onCancel?.();
	};

	//map out data to set the dropdowns with a value selected
	useEffect(() => {
		let mapped_tags = allReportingTags?.map((tag) => {
			let tag_name = tag?.tag_name?.[fieldLanguage];
			let tag_id = tag._id;
			let options = tag.options?.map((option) => {
				//"options" are options under a tag
				return {
					label: option?.[fieldLanguage],
					value: option?._id,
				};
			});

			// set selected option_id
			let option_id = '';
			if (selectedTags.length > 0) {
				let select_root = selectedTags?.find(
					(selected) => selected.tag_id === tag_id
				); //find the root tag
				option_id = select_root?.option_id ?? ''; //get the option of the root
			}

			return {
				tag_name,
				tag_id,
				options,
				option_id,
			};
		});
		setTagList(mapped_tags);
	}, [allReportingTags, selectedTags, fieldLanguage]);

	return (
		<>
			<div className="frmbox newfrmv2 asgnrpttg">
				<Row xs={1} md={2}>


				{tagList.map((tag, tag_index) => (
					<Col key={`tag${tag_index}`}>
						<Form.Group className="frmfld pb-1"><Form.Label>{tag?.tag_name ?? ''}</Form.Label>
							<section>
								<Form.Select
									value={tag?.option_id ?? ''}
									onChange={handleChange(tag_index)}
								>
									<option value="">
										{tag?.option_id ? t('None') : t('Select')}
									</option>
									{tag?.options?.map((opt, option_index) => (
										<option
											key={`tagoption${tag_index}-${option_index}`}
											value={opt?.value ?? ''}
										>
											{opt?.label}
										</option>
									))}
								</Form.Select>
								<div className="invalid-feedback"></div>
							</section>
						</Form.Group>
					</Col>
				))}
				</Row>
				{allReportingTags?.length === 0 && (
					<div className="text-center">{t('No tags found')}</div>
				)}
				{isModal && (
					<div className="btn-pnl">
						<Row className="p-0">
							<Col>
								<Button
									variant={`primary thm2 mr-5 ${isButtonLoading && 'loading'}`}
									size="sm"
									onClick={handleSave}
								>
									{t('Save')}
								</Button>{' '}
								<Button variant="secondary" size="sm" onClick={handleClose}>
									{t('Cancel')}
								</Button>
							</Col>
						</Row>
					</div>
				)}
			</div>
		</>
	);
};

//show reporting tags on details page
const ReportingTagsBadge = ({
	allReportingTags = [],
	selectedTags = [],
	contactDetails,
	onActionSuccess,
	contactType, //contact type [C,V]
}) => {
	const { t } = useTranslation();
	const language = useSelector((state) => state.auth.language);
	const fieldLanguage = userLanguage(language); //en or ar
	const [tagList, setTagList] = useState([]); //will loop through all tags and create a list of tags

	const salePermission = useSelector(
		(state) =>
			state?.auth?.userInfo?.role_and_permission?.permission_group?.sales
	);
	const purchasePermission = useSelector(
		(state) =>
			state?.auth?.userInfo?.role_and_permission?.permission_group?.purchases
	);

	//map out data to set the dropdowns with a value selected
	useEffect(() => {
		let mapped_tags = allReportingTags
			.map((tag) => {
				let tag_name = tag.tag_name?.[fieldLanguage];
				let tag_id = tag._id;

				// set selected option_id
				let option_name = '';
				let option_id = '';
				if (selectedTags.length > 0) {
					let select_root = selectedTags.find(
						(selected) => selected.tag_id === tag_id
					); //find the root tag
					option_id = select_root?.option_id ?? ''; //get the option of the root
					option_name =
						tag?.options?.find((opt) => opt._id === option_id)?.[
							fieldLanguage
						] ?? '';
				}

				return {
					tag_name,
					tag_id,
					option_id,
					option_name,
				};
			})
			.filter((tag) => tag.option_name != '');
		setTagList(mapped_tags);
	}, [allReportingTags, selectedTags, fieldLanguage]);

	//when a 'X' button is clicked, remove the tag from the selectedTags array
	const onRemove = async (tag_id) => {
		alertify
			.confirm(
				`${t('Remove tag')}`,
				`${t('Do you want to remove the tag?')}`,

				async function () {
					//remove the tag from the selected tags
					let rp_tags_payload = tagList.filter((tag) => tag.option_id !== '' && tag.tag_id !== tag_id);

					try {
						const payload = {
							patch_type: 'reporting_tags_edit',
							patch_payload: rp_tags_payload,
						};

						const contact_type = contactDetails.contact_type;
						const contact_id = contactDetails._id;
						let patchContactFn;

						if ('C' === contact_type) {
							patchContactFn = patchCustomer;
						} else if ('V' === contact_type) {
							patchContactFn = patchVendor;
						}
						const contact_patch_res = await patchContactFn(contact_id, payload);
						if (contact_patch_res) {
							onActionSuccess?.(contact_patch_res);
						}
					} catch (err) {}
				},

				function () {
					//do nothing on cancel
				}
			)
			.set('labels', { ok: t('Ok'), cancel: t('Cancel') })
			.set({ transitionOff: true });
	};

	return (
		<Row xs="auto" className="g-2">
			{tagList?.map((tag, tag_index) => (
				<Col key={`rpt${tag_index}`}>
					<div className="tagCls">
						<small>{tag?.tag_name}</small>
						<span>{tag?.option_name}</span>
						{((true === salePermission?.customers_edit && contactType === 'C') || 
                                          (true === purchasePermission?.vendors_edit && contactType === 'V')) && (<Button
							variant="link p-0 nobdr lh-1 close"
							onClick={() => {
								onRemove(tag?.tag_id);
							}}
						>
							<Icon.XLg />
						</Button>)}
					</div>
				</Col>
			))}
			{tagList?.length === 0 && <p className="txt-gry mb-1 mt-2">{t('Add reporting tags')}</p>}
		</Row>
	);
};

export { AssignReportTagsModal, AssignReportTagsForm, ReportingTagsBadge };
