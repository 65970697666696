import { secureAPI } from './API';
import { checkTokenValidate } from './../util/helper';
/*Sales Person  service
Store all SalesPersons route api call here.
*/

/*
create a sales person for a organization
return promise
resolve : object
*/
async function createSalesPerson(sales_person_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post('/salespersons', sales_person_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/*
get all sales person of a organization
return promise
resolve : list of objects
*/
async function getAllSalesPersons(selectElement = null, searchParam = '') {
  let url = '/salespersons?status=A' + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/*
get a sales person of a organization
return promise
resolve : object
*/
async function getASalesPerson(sales_person_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/salespersons/${sales_person_id}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/*
delete a sales person of a organization
return promise
resolve : a object
*/
async function deleteASalesPerson(sales_person_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .delete(`/salespersons/${sales_person_id}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/*
update an sales person of a organization
return promise
resolve : a object
*/
async function updateASalesPerson(sales_person_id, sales_person_payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/salespersons/${sales_person_id}`, sales_person_payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/* DANGER
get all deleted sales person of a organization
return promise
resolve : list of objects
*/
async function showDeleteSalesPerson() {
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/salespersons?status=D`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

export {
  createSalesPerson,
  getAllSalesPersons,
  getASalesPerson,
  updateASalesPerson,
  deleteASalesPerson,
  showDeleteSalesPerson,
};
