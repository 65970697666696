import React, { useState, useEffect, lazy, useMemo, useRef } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import queryString from "query-string";
import { getAllJournal } from "../../services/journal.service";
import { getAllCustomers } from "../../services/customer.service";
import { getAllVendors } from "../../services/vendor.service";
import { getAllInvoices } from "../../services/invoice.service";
import { getAllBills } from "../../services/bill.service";
import { getAllProformaInvoices } from "../../services/proformaInvoices.service";
import { getAllCNotes } from "../../services/creditnotes.service";
import { getAllVendorCredits } from "../../services/vendorcredit.service";
import { getAllPayments } from "../../services/payment.service";
import { getAllCollections } from "../../services/collection.service";
import { getAllDocuments } from "../../services/document.service";
import { getFuzzySearchResult } from "../../services/search.service";
import { Loading } from "../../common";
import { getAllOrganization } from "../../services/organizatiom.service";
import { getAllsalesOrders } from "../../services/salesOrder.service";
import { getAllpurchaseOrders } from "../../services/purchaseOrder.service";
import { getAllRecurringInvoices } from "../../services/recurring_invoice.service";
import { getAllRecurringBills } from "../../services/recurring_bills.service";
import { getAllRecurringExpenses } from "../../services/recurring_expense.service";
import { getAllRecurringJournals } from "../../services/recurring_journal.service";
import { getAllTransferOrders } from "../../services/transfer.service";
import { getAllInventoryAdjustments } from "../../services/inventory/InventoryAdjustment.service"
// import { isEmpty, userLanguage } from '../../util/helper';
import SearchBox from "../../common/SearchBox";
import { getAllExpense } from "../../services/expense.service";
import ListPage from '../../common/listPage/ListPage';
// const ListPage = lazy(() => import("../../common/listPage/ListPage"));
import { INVENTORY_ORG_REPORTS, NON_INVENTORY_ORG_REPORTS } from "../../constants/Report.Constant";
import RecurringDetailList from "../Recurring/common/RecurringDetailList";

const CommonSearch = ({ t }) => {
	const history = useHistory();
	const { search } = useLocation();
	const searchInputRef = useRef(null);
	const language = useSelector((state) => state.auth.language);
	// const payment_modes = useSelector((state) => state.auth.userInfo.default_organization_id.payment_modes);

	//chart of account search is using data stored in redux no api call is made
	const chart_of_accounts = useSelector((state) => state.auth.userInfo.chart_of_account);
	const chartOfAccountsList = useMemo(() => chart_of_accounts?.filter((acc) => acc?.depth > 1), [chart_of_accounts]);
	const prime_organization_id = process.env.REACT_APP_PRIME_ORG_ID;
	const { _id: organization_id, features_config } = useSelector((state) => state.auth.userInfo.default_organization_id);
	const IS_INVENTORY_ENABLED = features_config?.is_inventory_enabled ?? false;


	// const fieldLanguage = userLanguage(language);
	const [searchText, setSearchText] = useState("");
	const [searchItems, setSearchItems] = useState([]);
	const [searchBoxActive, setSearchBoxActive] = useState(false);
	const [searchElement, setSearchElement] = useState("");
	const [loadingItems, setLoadingItems] = useState(false);
	// let currentDate = moment().format('YYYY-MM-DD'); //current date
	let url = window.location.pathname; //current url
	let params = queryString.parse(search); //search query string
	let pageUrlTitle = url.split("/")[2]; //like Items, Customers etc
	if (pageUrlTitle === "inventory") {
		pageUrlTitle = url.split("/")[3];
	}
	// let pageUrlTitle = url.split("/")[3]?url.split("/")[3]:url.split("/")[2];
	const [pageTitle, setPageTitle] = useState("");
	const [seachModalName, setSeachModalName] = useState("");

	const [advsrchshow, setadvsrchShow] = useState(false);
	const advsrchClose = () => setadvsrchShow(false);
	const advsrchShow = () => {
		setadvsrchShow(true);
	};

	useEffect(() => {
		setPageTitle(pageUrlTitle); //set page title for search box
		setSeachModalName(pageUrlTitle);
		// document.getElementById('search-input').value = ''; //clear search input
	}, [pageUrlTitle]);
	// const notSearchPage = ['my-account', 'organizations', 'organization', 'settings', 'dashboard', 'reports', 'accounting', 'expenses']; //search not work in this page list

	//search list array
	//these are segment of url after /en cause after url split by "/" we are taking 3 element(index 2)
	let searchPageList;
	searchPageList = useMemo(() => {
		if (prime_organization_id === organization_id) {
			//for prime organization
			return ["customers", "organizations-list"];
		} else {
			//for all other organization
			return [
				"items",
				"customers",
				"vendors",
				"invoices",
				"bills",
				"proforma_invoices",
				"credit-notes",
				"vendorcredits",
				"collections",
				"payments",
				"documents",
				"accounting", //these segment has children  "/accounting/chartofaccounts" and "/accounting/journals",
				"salesorders",
				"purchaseorders",
				"expenses",
				// "recurring-invoices",
				// "recurring-bills",
				// "recurring-expenses",
				// "recurring-journals",
				"reports",
				// "inventory",
				"transfer_orders",
				"adjustments",
			];
		}
	}, [organization_id]);

	let pageText = "",
		pageTextVal = "";
	if (searchPageList.includes(pageTitle)) {
		pageTextVal = pageTitle === "vendorcredits" ? "Vendor credits" : pageTitle;
		let text = pageTextVal.replace(/-/g, " "); //replace
		text = text.replace(/_/g, " "); //replace
		pageText = t("in") + " " + t(text.charAt(0).toUpperCase() + text.slice(1)); //capitalize
	} else {
		//default search from customer
		setPageTitle("customers"); //default search from customer
		let text = pageTitle.replace(/-/g, " "); //replace
		text = text.replace(/_/g, " "); //replace
		pageText = t("in") + " " + t(text.charAt(0).toUpperCase() + text.slice(1)); //capitalize
	}

	//get input place holder text based on page title and current page url
	//if any segment url have child then consider the child
	const place_holder_text = useMemo(() => {
		let page_name = ""; //normalized name of the page, remove any underscore or any special characters
		switch (pageTitle) {
			case "vendorcredits": {
				page_name = t("Vendor credits");
				break;
			}
			case "credit-notes": {
				page_name = t("Credit notes");
				break;
			}
			case "proforma_invoices": {
				page_name = t("Proforma invoices");
				break;
			}
			case "salesorders": {
				page_name = t("Sales order");
				break;
			}
			case "purchaseorders": {
				page_name = t("Purchase order");
				break;
			}
			case "recurring-invoices": {
				page_name = t("Recurring invoice");
				// setSeachModalName("recurring_invoices");
				break;
			}
			case "recurring-bills": {
				page_name = t("Recurring bills");
				break;
			}
			case "recurring-expenses": {
				page_name = t("Recurring expenses");
				break;
			}
			case "organizations-list": {
				page_name = t("Organizations");
				setSeachModalName("organizations");
				break;
			}
			case "reports": {
				page_name = t("Reports");
				setSeachModalName("reports");
				break;
			}
			case "transfer_orders": {
				page_name = t("Transfer orders");
				setSeachModalName("transfer-orders");
				break;
			}
			case "adjustments": {
				page_name = t("Adjustments");
				setSeachModalName("adjustments");
				break;
			}

			//accounting has childs, manual journals, chart of accounts
			case "accounting": {
				let child_segment = url.split("/")[3];
				if ("journals" === child_segment) {
					page_name = t("Manual journals");
					setSeachModalName("journal"); //replace the modal name, depending on this a default adavance search/form will be selected on keyboad press
				} else if ("recurring-journals" === child_segment) {
					page_name = t("Recurring journals");
					setSeachModalName("recurring-journals");
				} else {
					page_name = t("Accounts");
					setSeachModalName("chartofaccounts");
				}
				break;
			}
			// case "inventory": {
			// 	let child_segment = url.split("/")[3];
			// 	if ("transfer_orders" === child_segment) {
			// 		page_name = t("Transfer orders");
			// 		setSeachModalName("transfer_orders");
			// 	}
			// 	break;
			// }

			//default just capitalized first character
			default: {
				if (searchPageList.includes(pageTitle)) {
					page_name = t(`${pageTitle.charAt(0).toUpperCase()}${pageTitle.slice(1)}`);
					setSeachModalName(pageTitle); //set the model type
				} else {
					page_name = t("Customers"); //customer is default place holder, if any other page is selected other then those are listed it will be shown
					setSeachModalName("customers"); //default search module
				}
				break;
			}
		}
		return `${t("in")} ${page_name}`;
	}, [pageTitle, t, searchPageList, url]);

	//top search function............
	useEffect(() => {
		setLoadingItems(true);
		try {
			if (searchText.length >= 3 && searchPageList.includes(pageTitle)) {
				setSearchBoxActive(true);
				//check the length also not search few page
				const delayDebounceFn = setTimeout(async () => {
					let searchParam = "&limit=10";
					//for item...........
					if ("items" === pageTitle) {
						let selectElement = "_id,item_type,item_name,unit,selling_information,is_inventory_item";
						selectElement += "&search_field=item_name.en,item_name.ar&search_value=" + searchText;
						//set state for url
						setSearchElement("&search_field=item_name.en,item_name.ar&search_value=" + searchText);
						// let returnResult = await getAllItems(selectElement, searchParam);
						let returnResult = await getFuzzySearchResult("item", searchText, selectElement);
						if (returnResult) {
							setSearchItems(returnResult.data);
						}
						setLoadingItems(false);
						//for customer..............
					} else if ("customers" === pageTitle || "vendors" === pageTitle) {
						let selectElement = "_id,contact_name,customer_type,currency,outstanding_receivables";
						selectElement += "&search_field=contact_name.en,contact_name.ar&search_value=" + searchText;
						//set state for url
						setSearchElement("&search_field=contact_name.en,contact_name.ar&search_value=" + searchText);
						let getAllContacts; //assign a function
						if ("customers" === pageTitle) {
							getAllContacts = getAllCustomers;
						} else if ("vendors" === pageTitle) {
							getAllContacts = getAllVendors;
						}
						//fetch all contacts (vendors or customers)
						let returnResult = await getAllContacts(selectElement, searchParam);
						if (returnResult) {
							setSearchItems(returnResult.data);
						}
						setLoadingItems(false);
						//for invoice...............
					} else if (["invoices", "proforma_invoices", "bills", "salesorders", "purchaseorders"].includes(pageTitle)) {
						let selectElement =
							"_id,contact_name,net_amount,contact_details,transaction_number,issue_date,transaction_status,payment_status,due_date,order_number"; //add specific column leter
						selectElement +=
							"&search_field=contact_details.contact_name.en,contact_details.contact_name.ar,transaction_number&search_value=" + searchText;

						//set state for url
						setSearchElement(
							"&search_field=contact_details.contact_name.en,contact_details.contact_name.ar,transaction_number&search_value=" + searchText
						);

						let getAllBillAndInvoice; //assigin a function
						if ("invoices" === pageTitle) {
							getAllBillAndInvoice = getAllInvoices;
						} else if ("bills" === pageTitle) {
							getAllBillAndInvoice = getAllBills;
						} else if ("proforma_invoices" === pageTitle) {
							getAllBillAndInvoice = getAllProformaInvoices;
						} else if ("salesorders" === pageTitle) {
							getAllBillAndInvoice = getAllsalesOrders;
						} else if ("purchaseorders" === pageTitle) {
							getAllBillAndInvoice = getAllpurchaseOrders;
						}

						let returnResult = await getAllBillAndInvoice(searchParam, selectElement);
						if (returnResult) {
							setSearchItems(returnResult.data);
						}
						setLoadingItems(false);

						//for credit notes..............
					} else if ("credit-notes" === pageTitle || "vendorcredits" === pageTitle) {
						let selectElement = "_id,contact_details,net_amount,transaction_number,issue_date,transaction_status"; //add specific column leter
						selectElement +=
							"&search_field=contact_details.contact_name.en,contact_details.contact_name.ar,transaction_number&search_value=" + searchText;
						//set state for url
						setSearchElement(
							"&search_field=contact_details.contact_name.en,contact_details.contact_name.ar,transaction_number&search_value=" + searchText
						);
						let getAllCnAndVcn; //assigin a function
						if ("credit-notes" === pageTitle) {
							getAllCnAndVcn = getAllCNotes; //get all credit notes
						} else if ("vendorcredits" === pageTitle) {
							getAllCnAndVcn = getAllVendorCredits; //get all vendor credits
						}

						let returnResult = await getAllCnAndVcn(searchParam, selectElement);
						if (returnResult) {
							setSearchItems(returnResult.data);
						}
						setLoadingItems(false);

						//for payments...................
					} else if ("collections" === pageTitle || "payments" === pageTitle) {
						let selectElement =
							"_id,contact_name,contact_id,amount_recived,payment_date,payment_mode,applied_transactions,transaction_number, issue_date"; //add specific column leter
						selectElement += "&search_field=contact_id.contact_name.en,contact_id.contact_name.ar,transaction_number&search_value=" + searchText;
						//set state for url
						setSearchElement("&search_field=contact_id.contact_name.en,contact_id.contact_name.ar,transaction_number&search_value=" + searchText);
						let getAllPaymentAndCollection; //assign a function
						if ("collections" === pageTitle) {
							getAllPaymentAndCollection = getAllCollections; //get all collections
						} else if ("payments" === pageTitle) {
							getAllPaymentAndCollection = getAllPayments; //get all payments
						}

						let returnResult = await getAllPaymentAndCollection(searchParam, selectElement);
						if (returnResult) {
							setSearchItems(returnResult.data);
						}
						setLoadingItems(false);
					} else if ("documents" === pageTitle) {
						let selectElement = "_id,document_name,type";
						selectElement += "&search_field=document_name&search_value=" + searchText;
						//set state for url
						setSearchElement("&search_field=document_name&search_value=" + searchText);
						let returnResult = await getAllDocuments(searchParam, selectElement);
						if (returnResult) {
							setSearchItems(returnResult.data);
						}
						setLoadingItems(false);
					} else if ("accounting" === pageTitle) {
						let child_segment = url.split("/")[3];
						if ("journals" === child_segment) {
							let selectElement = "_id,transaction_number,reference,issue_date,journal_status,total_debit_amount,total_credit_amount,currency";
							selectElement += "&search_field=transaction_number,reference&search_value=" + searchText;
							setSearchElement("&search_field=transaction_number&search_value=" + searchText);
							let returnResult = await getAllJournal(selectElement, searchParam);
							if (returnResult) {
								setSearchItems(returnResult.data);
							}
							setLoadingItems(false);
						} else if ("recurring-journals" === child_segment) {
							setPageTitle(child_segment) // used to load component RecurringDetailsList

							let selectElement =
								"_id,issue_date,profile,currency,transaction_status,total_credit_amount,total_debit_amount,total_debit_amount_fcy,total_credit_amount_fcy";
							selectElement += "&search_value=" + searchText + "&model=recurring-journals";
							setSearchElement("&search_value=" + searchText);
							let returnResult = await getAllRecurringJournals(searchParam, selectElement);
							if (returnResult) {
								setSearchItems(returnResult.data);
							}
							setLoadingItems(false);

						} else {
							//search on account title en, ar and account code from redux
							let match_regex = new RegExp(`${searchText}`, "i");
							setSearchElement("&search_field=title.en,title.ar,account_code&search_value=" + searchText);
							let matched_account = chartOfAccountsList.filter((acc) => {
								return match_regex.test(acc.title.en) || match_regex.test(acc.title.ar) || match_regex.test(acc.account_code);
							});
							if (matched_account) {
								setSearchItems(matched_account);
							}
							setLoadingItems(false);
						}
					} else if ("organizations-list" === pageTitle) {
						let selectElement = "_id,organization_name,vat_id,currency,createdAt";
						selectElement += "&search_field=organization_name.en,organization_name.ar&search_value=" + searchText;
						//set state for url
						// setSearchElement('&search_field=organization_name&search_value=' + searchText);
						setSearchElement("&model=organizations&search=true&organization_name=" + searchText); //it's buils as require
						let returnResult = await getAllOrganization(selectElement, searchParam);
						if (returnResult) {
							setSearchItems(returnResult.data);
						}
						setLoadingItems(false);
					} else if ("expenses" === pageTitle) {
						let selectElement =
							"_id,reference,issue_date,amount,account_id,expense_type,expense_category,expense_sub_category,expense_description,expense_status,currency,net_amount";
						selectElement += "&search_value=" + searchText;
						setSearchElement("&search_value=" + searchText);
						let returnResult = await getAllExpense(selectElement, searchParam); // +"&account_id=" + matched_account_id
						if (returnResult) {
							setSearchItems(returnResult.data);
						}
						setLoadingItems(false);
					} else if ("recurring-invoices" === pageTitle) {
						let selectElement =
							"_id,contact_id,issue_date,amount,profile,currency,net_amount";
						selectElement += "&search_value=" + searchText + "&model=recurring-invoices";
						setSearchElement("&search_value=" + searchText);
						let returnResult = await getAllRecurringInvoices(selectElement, searchParam);
						if (returnResult) {
							setSearchItems(returnResult.data);
						}
						setLoadingItems(false);
					} else if ("recurring-bills" === pageTitle) {
						let selectElement =
							"_id,contact_id,issue_date,amount,profile,currency,net_amount";
						selectElement += "&search_value=" + searchText + "&model=recurring-bills";
						setSearchElement("&search_value=" + searchText);
						let returnResult = await getAllRecurringBills(selectElement, searchParam);
						if (returnResult) {
							setSearchItems(returnResult.data);
						}
						setLoadingItems(false);
					} else if ("recurring-expenses" === pageTitle) {
						let selectElement =
							"_id,contact_id,issue_date,amount,profile,currency,net_amount,transaction_status";
						selectElement += "&search_value=" + searchText + "&model=recurring-expenses";
						setSearchElement("&search_value=" + searchText);

						let returnResult = await getAllRecurringExpenses(searchParam, selectElement);
						if (returnResult) {
							setSearchItems(returnResult.data);
						}
						setLoadingItems(false);
					}else if ("reports" === pageTitle) {
						// depending on if inventory is enabled or not, we will show different reports
						// inventory enabled org will show inventory reports
						const all_report_list = IS_INVENTORY_ENABLED ? INVENTORY_ORG_REPORTS : NON_INVENTORY_ORG_REPORTS;
						// note: report name will be searched from an predefined object "all_report_list"
						const match_regex = new RegExp(`${searchText}`, "i");
						const matched_reports = all_report_list.filter((report) => {
							return match_regex.test(t(report.title));
						});
						setSearchItems(matched_reports)
						setLoadingItems(false);
					}else if("transfer_orders" === pageTitle){
						let selectElement = "_id,transaction_number,transaction_status,transfer_reason,from_store_id,to_store_id,total_transfer_quantity,net_amount,status,";
						selectElement += "&search_value=" + searchText+"&model=transfer-orders";
						setSearchElement(selectElement);
						let returnResult = await getAllTransferOrders(selectElement, searchParam);							
						if (returnResult) {
							setSearchItems(returnResult);
						}
						setLoadingItems(false);						
					}else if("adjustments" === pageTitle){						
							// let selectElement = "_id,transaction_status,reason,store_id,adjustment_type,";
							// selectElement += "&search_field=transaction_status&search_value=" + searchText+"&model=adjustments";
							setSearchElement("&search_value=" + searchText);
							let returnResult = await getAllInventoryAdjustments({ queries: {
								model:'adjustments',
								search_value : searchText,								
							} });
							if (returnResult) {
								setSearchItems(returnResult?.data);
							}
							setLoadingItems(false);
					}
				}, 600);
				return () => clearTimeout(delayDebounceFn);
			} else {
				setSearchBoxActive(false);
				setLoadingItems(false);
			}
		} catch (err) {
			setLoadingItems(false);
		}
	}, [searchText]);

	//set page title on select from dropdown
	const onSearchItemSelect = (pageName) => {
		setPageTitle(pageName);
		setSeachModalName(pageName);
		document.getElementById("search-input").value = ""; //clear search input
	};
	//show and close custom search popup
	const openCustomSearchPopup = (event) => {
		if (event.ctrlKey && event.key === "/") {
			setadvsrchShow(true); //open custom search box
		} else if (event.key === "Escape") {
			setadvsrchShow(false); //close custom search box
		}
	};
	//active top search input box
	const searchInputFocus = (event) => {
		if (event.key === "/" && !isTypingInFormInput()) {
			searchInputRef.current.focus();
			event.preventDefault();
		} else if (event.key === "Escape") {
			searchInputRef.current.blur(); // Blur (defocus) the input when Escape key is pressed
			event.preventDefault();
		}
	};

	//for inactive / function when type in other input or textarea. For addition you can add "contenteditable" attribute to prevent this function
	const isTypingInFormInput = () => {
		const activeElement = document.activeElement;
		return activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA"; // activeElement.hasAttribute("contenteditable")
	};

	//set custom search box active on key slash press
	useEffect(() => {
		//event listener for key press
		document.addEventListener("keydown", openCustomSearchPopup, false); //custom search popup
		document.addEventListener("keydown", searchInputFocus, false); //top search box focus
		//On clear 'X' icon click
		document.getElementById("search-input").addEventListener("search", function (event) {
			setSearchItems([]);
			// warning: commented out below line because it was causing the page to reload by @Surojit Paul
			// history.push(window.location.pathname);
		});
		return () => {
			document.removeEventListener("keydown", openCustomSearchPopup, false);
			document.removeEventListener("keydown", searchInputFocus, false);
			document.removeEventListener("search", function (event) { }); //On clear 'X' icon click
		};
	}, []);

	/****************************************** */	

	return (
		<>
			<div className={`hdsrch d-inline-block position-relative ${searchBoxActive ? "active" : ""} `}>
				<div className="srdrop">
					<UncontrolledDropdown>
						<DropdownToggle caret color="link p-0">
							<Icon.Search />
						</DropdownToggle>
						<DropdownMenu className="overflow-auto" style={{maxHeight:"calc(100vh - 50px)"}}>
							{searchPageList?.map((pageName, index) => (
								<DropdownItem className={pageName === pageTitle ? "active" : ""} key={index} onClick={() => onSearchItemSelect(pageName)}>
									{t(
										pageName.replace(/-/g, " ").replace(/_/g, " ").charAt(0).toUpperCase() +
										pageName
											.replace(/-/g, " ")
											.replace(/_/g, " ")
											.replace(/credits/g, " credits")
											.replace(/orders/g, " orders")
											.slice(1)
									)}
								</DropdownItem>
							))}
							<DropdownItem divider />
							<DropdownItem onClick={advsrchShow} className="d-flex justify-content-between align-items-center">
								<span>
									<Icon.Search /> {t("Advance search")}
								</span>
								<span className="keybx" dir="ltr">
									Ctrl + /
								</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
				<Input
					type="search"
					id="search-input"
					innerRef={searchInputRef}
					placeholder={`${t("Search")} ${place_holder_text} ( / )`}
					onKeyUp={(e) => setSearchText(e.target.value.trim())}
				/>
				<section className="srchdrop">
					<div className="tabledata">
						{"true" === process.env.REACT_APP_BASE_SHOW_PATH_HINTS && (
							<p style={{ color: "red" }}>frontend-2.0/src/components/shared/CommonSearch.js</p>
						)}
						{loadingItems && <Loading loadClass="onlycont" />}
						{/* For recurring type search list */}
						{
							["recurring-invoices","recurring-bills","recurring-expenses","recurring-journals",].includes(seachModalName) && (
								<RecurringDetailList
								listData ={searchItems}
								language={language}
								parentCallBack={()=>{
									setSearchBoxActive(false);
								}}
								listType={seachModalName}
								callType="search"
								/>
							)
						}
						{/* For non-recurring type search list */}
						{
							!["recurring-invoices","recurring-bills","recurring-expenses","recurring-journals"].includes(seachModalName) && (

								<ListPage
									listType={seachModalName} //list type //previously it was pageTitle
									allListData={searchItems} //all list data
									t={t} //translate function
									callType="search" //call type
									parentCallbackForSearch={() => {
										//parent callback
										setSearchBoxActive(false);
									}}
									searchElementParams={searchElement} //set search text
								/>
							)
						}
					</div>
				</section>
				<div className="overlay" onClick={() => setSearchBoxActive(false)}></div>
			</div>

			{advsrchshow && "documents" !== seachModalName && (
				<SearchBox srchshow={advsrchshow} handleSrchClose={advsrchClose} queryParams={params} search_model={seachModalName} />
			)}
		</>
	);
};

export default CommonSearch;
