import { secureAPI, secureUploadAPI } from '../services/API';
import { checkTokenValidate } from './../util/helper';
/*Customer  service
Store all custoner route api call here.
*/

/*
create an customer of a organization by id
return promise
resolve : a object
*/
async function createACustomer(customerPayload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post('/customers', customerPayload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/*
get all customer of a organization
return promise
resolve : list of objects
*/
async function getAllCustomers(selectElement = null, searchParam = '', contact_type='C') {
  let contact_type_param = `&contact_type=${contact_type}`;
  if (contact_type === 'B') {
		contact_type_param = ``;
	}
  let url = '/customers?status=A'+contact_type_param + searchParam;
  if (selectElement) {
    url = `${url}&select=${selectElement}`;
  }
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return array
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/*
get a customer of a organization
return promise
resolve : a object
*/
async function getACustomers(contact_id, selectElement = null, query_params_string=null) {
  let url = `/customers/${contact_id}`;
  if (selectElement) {
    url = `${url}?select=${selectElement}`;
  }
  if(query_params_string ){
    url = `${url}${query_params_string}`
  }
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return a object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/*
delete an customer of a organization by id
return promise
resolve : a object
*/
async function deleteACustomer(contact_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .delete(`/customers/${contact_id}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

/*
update an customer of a organization by id
return promise
resolve : a object
*/
async function updateACustomer(contact_id, customerPayload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .put(`/customers/${contact_id}`, customerPayload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
//new asif when apply in
async function getCustomerDueInvoices(contact_id, searchParam='') {
  let url = `/customers/${contact_id}/due_customer_invoices${searchParam}`;
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return a object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function createComment(customerPayload, contact_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post(`/customers/${contact_id}/comments`, customerPayload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function getComments(contact_id) {
  let url = `/customers/${contact_id}/comments`;
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return a object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function deleteAComments(id, contact_id) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .delete(`/customers/${contact_id}/comments/${id}`)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function getAllMailLog(contact_id) {
  let url = `/customers/${contact_id}/mails`;
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(url)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return a object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

//get mapped columns in return
async function customersImportUpload(file) {
  return new Promise((resolve, reject) => {
    secureUploadAPI()
      .post(`/customers/import/upload`, file)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function customersImportPreview(file_id, payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post(`/customers/import/preview/${file_id}`, payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}
async function customersImport(file_id, payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .post(`/customers/import/${file_id}`, payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function patchCustomer(contact_id, payload) {
  return new Promise((resolve, reject) => {
    secureAPI()
      .patch(`/customers/${contact_id}`, payload)
      .then(({ data }) => {
        if (data.success) {
          resolve(data.data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

async function getCustomerCollection(contact_id,branch_id) {
  
  return new Promise((resolve, reject) => {
    secureAPI()
      .get(`/customers/${contact_id}/customer_collections`,{params: {branch_id: branch_id}})
      .then(({ data }) => {
        if (data.success) {
          resolve(data); //return single object
        }
      })
      .catch((err) => {
        checkTokenValidate(err);
        reject(err.response.data.error);
      });
  });
}

export {
  createACustomer,
  getAllCustomers,
  getACustomers,
  deleteACustomer,
  updateACustomer,
  getCustomerDueInvoices,
  createComment,
  getComments,
  deleteAComments,
  getAllMailLog,
  customersImportUpload,
  customersImportPreview,
  customersImport,
  patchCustomer,
  getCustomerCollection,
};
