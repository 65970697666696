import React from "react";
import * as Icon from 'react-bootstrap-icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SET_LANGUAGE, LOGOUT } from '../store/actions/types';
import { Navbar, Container, Button } from 'reactstrap';
import logo from '../assets/images/logo.svg';
import Enflag from '../assets/images/eng-lang.svg';
import Arflag from '../assets/images/sa-lang.svg';
import './../translations/i18n';
import { useTranslation } from 'react-i18next';

const Header = () => {
	//const token = localStorage.getItem('token');
	const history = useHistory();
	const dispatch = useDispatch();
	const { isLoggedIn, language } = useSelector((state) => state.auth);
	const { t, i18n } = useTranslation();
	const { search, pathname } = useLocation();
	const changeLanguage = (lang) => {
		document.body.classList.add('langChange');
		let url = pathname;

		let searchParam = search;
		let finalUrl = url.concat(searchParam);
		url = finalUrl.replace("/" + language, "/" + lang);

		// let url = window.location.pathname;
		// console.log('	url', url);
		// url = url.replace('/' + language, '/' + lang);
		i18n.changeLanguage(lang);
		localStorage.setItem('language', lang);
		dispatch({
			type: SET_LANGUAGE,
			payload: { language: lang },
		});
		history.push(url);
		window.location.reload();
		// window.location.href = url;
	};

	const userLogout = () => {
		dispatch({
			type: LOGOUT,
		});
		history.push(`/${language}/login`);
	};
	return (
		<>
			<header className="logheader noprint">
				<Navbar>
					<Container>
						{/* <NavbarBrand className='topnavs' href={`/${language}`}>
							<img src={logo} alt='ANINVOICE' />
						</NavbarBrand> */}
						<Link className="topnavs" to={`/${language}`}>
							<img src={logo} alt="Aninvoice" />
						</Link>
						<div className="justify-content-end">
							<div className="langswitch position-relative rnd">
								<Button color="link nofcs clrdflt noline">
									<samp>
										<img src={language === 'en' ? Enflag : Arflag} alt={language === 'en' ? 'English' : 'Arabic'} />
									</samp>{' '}
								</Button>
								<div className="drop">
									<button onClick={(e) => changeLanguage(language === 'en' ? 'ar' : 'en')}>
										<samp>
											<img src={language === 'en' ? Arflag : Enflag} alt={language === 'en' ? 'Arabic' : 'English'} />
										</samp>{' '}
									</button>
								</div>
							</div>
							{isLoggedIn && (
								<div className="lgout">
									<Button color="link" onClick={userLogout} className="p-0">
										<Icon.BoxArrowLeft className="arflp" /> {t('Log out')}
									</Button>
								</div>
							)}
						</div>
					</Container>
				</Navbar>
			</header>
		</>
	);
};;
export default Header;
