import React, { useEffect, useState,useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Button } from 'reactstrap';
import '../translations/i18n';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { TAX_CATEGORY } from "../util/helper";


export default function SearchCriteriaBar({
	onSearchShow,
	// onSearchClear,
	queryParams, //see below
	search_model, //model such as 'invoice', 'payments', 'customer', 'item' etc
}) {
	const { t } = useTranslation();
	const language = useSelector((state) => state.auth.language);
	const { search } = useLocation();
	const history = useHistory();
	const [showBar, setShowBar] = useState(false);
	useEffect(() => {
		hideSelf();
	}, [search]);

	const { default_organization_id } = useSelector((state) => state.auth.userInfo);
	let taxes = default_organization_id?.taxes;
	let payment_modes = default_organization_id?.payment_modes;
	let deposit_to = default_organization_id?.deposit_to;


	//merger tax list
	const taxList = useMemo(() => {
		let system_tax = TAX_CATEGORY?.default_categories?.map((tax) => ({
			label: tax[language],
			value: { value: tax.value, tax_category: tax.tax_category },
		}));
		//don't show 0% as standard rate
		let user_tax = taxes
			?.map((tax) => (tax?.tax_category ? tax : { ...tax, tax_category: "S" }))
			?.map((tax) => ({ label: tax[language] + " - " + tax.value + "%", value: { value: tax.value, tax_category: tax.tax_category } })); //have structure  {ar : "tax name", en: "tax name", value : a number , tax_category : string | undefined}
		let tax_list = [...system_tax, ...user_tax];
		return tax_list;
	},[language,taxes]);

	//invoices criteria
	let criteria = {}; //this will be an url.
	criteria = queryParams; //this will be an url.
	let searchFormFieldsArray = {};
	let updateFieldsArray = {};
	let returnedFinalObj;
	searchFormFieldsArray = {
		contact_name: {
			label: 'Customer name',
			value: 'contact_name',
		},
	};
	switch (search_model) {
		//set invoice criteria only
		//add others below
		//modify this values to match your model extra need
		case 'invoices': {
			updateFieldsArray = {
				reference: {
					label: 'Reference',
					value: 'order_number',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;
		}
		case 'sales_orders': {
			updateFieldsArray = {
				reference: {
					label: 'Reference',
					value: 'order_number',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;
		}
		case 'credit-notes': {
			updateFieldsArray = {
				reference: {
					label: 'Reference',
					value: 'reference',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);

			break;
		}
		case 'vendorcredits': {
			updateFieldsArray = {
				contact_name: {
					label: 'Vendor name',
					value: 'contact_name',
				},
				reference: {
					label: 'Reference',
					value: 'reference',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;
		}
		case 'customers': {
			updateFieldsArray = {
				outstanding_range: {
					label: 'Outstanding receivables',
					value: 'outstanding_receivables_from',
					value2: 'outstanding_receivables_to',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;
		}
		case 'vendors': {
			updateFieldsArray = {
				contact_name: {
					label: 'Vendor name',
					value: 'contact_name',
				},
				outstanding_range: {
					label: 'Outstanding payables',
					value: 'outstanding_payable_from',
					value2: 'outstanding_payable_to',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;
		}
		case 'payments': {
			updateFieldsArray = {
				contact_name: {
					label: 'Vendor name',
					value: 'contact_name',
				},
				reference: {
					label: 'Reference',
					value: 'reference_number',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;
		}
		case 'collections': {
			updateFieldsArray = {
				reference: {
					label: 'Reference',
					value: 'reference_number',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;
		}
		case 'proforma_invoices': {
			updateFieldsArray = {
				reference: {
					label: 'Reference',
					value: 'order_number',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;
		}
		case 'bills': {
			updateFieldsArray = {
				contact_name: {
					label: 'Vendor name',
					value: 'contact_name',
				},
				reference: {
					label: 'Reference',
					value: 'order_number',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;
		}
		case 'chartofaccounts':
			updateFieldsArray = {
				account_name: {
					label: 'Account name',
					value: 'account_name',
				},
				account_code: {
					label: 'Account code',
					value: 'account_code',
				},
				account_group: {
					label: 'Account group',
					value: 'account_group_name',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;
		case 'journal':
			updateFieldsArray = {
				contact_name: {
					label: t('Customer') + '/' + t('Vendor'),
					value: 'contact_name',
				},
				reference: {
					label: 'Reference',
					value: 'reference_number',
				},
				account_name: {
					label: 'Account name',
					value: 'account_name',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;

		case 'expenses':
			updateFieldsArray = {
				contact_name: {
					label: t('Customer name'),
					value: 'contact_name',
				},
				vendor_name: {
					label: t('Vendor name'),
					value: 'vendor_name',
				},

				reference: {
					label: 'Reference',
					value: 'reference',
				},

				expenses_account_name: {
					label: 'Expenses account',
					value: 'expenses_account_name',
				},

				paid_through_account_name: {
					label: 'Paid through account',
					value: 'paid_through_account_name',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;
		case 'recurring-invoices':
		case 'recurring-bills':
			updateFieldsArray = {
				contact_name: {
					label: ('recurring-invoices'=== search_model)?t('Customer name'):t('Vendor name'),
					value: 'contact_name',
				},
				profile_name: {
					label: t('Profile name'),
					value: 'profile_name',
				},
				frequency: {
					label: t('Frequency'),
					value: 'frequency',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;
		case "recurring-expenses":
			updateFieldsArray = {
				contact_name: {
					label: t('Customer name'),
					value: 'contact_name',
				},
				vendor_name: {
					label: t('Vendor name'),
					value: 'vendor_name',
				},

				reference: {
					label: 'Reference',
					value: 'reference',
				},

				expenses_account_name: {
					label: 'Expenses account',
					value: 'expenses_account_name',
				},

				paid_through_account_name: {
					label: 'Paid through account',
					value: 'paid_through_account_name',
				},
				profile_name: {
					label: t('Profile name'),
					value: 'profile_name',
				},
				frequency: {
					label: t('Frequency'),
					value: 'frequency',
				},
			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;
		case 'recurring-journals':
			updateFieldsArray = {
				profile_name: {
					label: "Profile name",
					value: "profile_name",
				},
				frequency: {
					label: "Frequency",
					value: "frequency"
				},
				contact_name: {
					label: t("Customer") + "/" + t("Vendor"),
					value: "contact_name",
				},
				reference: {
					label: "Reference",
					value: "reference_number",
				},
				account_name: {
					label: "Account name",
					value: "account_name",
				},

			};
			returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
			break;
			case 'transfer-orders':
				updateFieldsArray = {
					item_input: {
						label: "Item name",
						value: "item_name",
					},
					reason: {
						label: "Reason",
						value: "reason",
					},
					source_ware_house:{
						label :'Source warehouse',
						value : 'from_store_id'
					},
					destination_ware_house:{
						label :'Destination warehouse',
						value : 'to_store_id'
					},					

				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				break;	
			case 'adjustments':
				updateFieldsArray = {
					
					adjustment_type:{
						label :'adjustment type',
						value : 'adjustment_type'
					},					

				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				break;
		default:
			break;
	}

	/**
	 * when one filter condition is removed
	 *@param {string|string[]} remove_filter
	 *@returns {void}
	 */
	const handleRemoveFilter = (remove_filter) => {
		//built a object from url query params
		//remove a sepcific key from that object
		//rebuilt the query string from that object and push
		let query_object = queryString.parse(search); //search query string
		if (Array.isArray(remove_filter)) {
			remove_filter.forEach((filter) => {
				delete query_object[filter];
			});
		} else {
			delete query_object[remove_filter];
		}

		query_object.page = 1; //reset page to 1
		// clear the search bar when you remove all filters
		if (document.querySelectorAll('.menu > li').length < 2) {
			delete query_object.search;
		}
		let query_string = queryString.stringify(query_object);
		// if ('chartofaccounts' == search_model) {
		// 	search_model = `${'accounting/chartofaccounts'}`;
		// } else if ('journal' == search_model) {
		// 	search_model = `${'accounting/journals'}`;
		// }
		// history.push(`/${language}/${search_model}` + '?' + query_string);
		history.push(window.location.pathname + '?' + query_string);
	};

	//when there is no search criteria
	//(only limit, page, search and model remain as search query)
	const hideSelf = () => {
		let query_object = queryString.parse(search);
		//delete this keys [limit, page, search, model]
		//check if there is any key left
		//if not, hide self
		['limit', 'page', 'search', 'model', 'sort', 'sort_order', 'mode'].forEach((key) => {
			delete query_object[key];
		});
		let show = false;
		if (Object.keys(query_object).length === 0) {
			show = false;
		} else {
			show = true;
		}
		setShowBar(show);
	};

	//when user click 'x' to clear search
	const onSearchClear = () => {
		history.push(window.location.pathname);
	};
	

	return (
		<>
			{showBar && (
				<section className="contsbox srchcrte position-relative">
					<Link to="#" onClick={(e) => e.preventDefault()} className="close">
						<Icon.XLg
							onClick={(ev) => {
								onSearchClear();
							}}
						/>
					</Link>
					<h6>
						<strong className="fw-5">{t('Search criteria')}</strong>
					</h6>
					<ul id="criteria" className="menu">
						{/**Add common here */}

						{criteria?.account_name && (
							<li>
								{t('Account name is')} <strong>{criteria?.account_name}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['account_name', 'account_id']} />
							</li>
						)}
						{criteria?.account_code && (
							<li>
								{t('Account code is')} <strong>{criteria?.account_code}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={'account_code'} />
							</li>
						)}
						{criteria?.account_group && (
							<li>
								{t('Account group is')} <strong>{criteria?.account_group_name}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['account_group', "account_group_name"]} />
							</li>
						)}
						{/* expense search */}
						{criteria?.expense_account_id && (
							<li>
								{t('Expenses account is')} <strong>{criteria?.expenses_account_name}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['expenses_account_name', 'expense_account_id']} />
							</li>
						)}
						{criteria?.paid_through_account_id && (
							<li>
								{t('Paid through account is')} <strong>{criteria?.paid_through_account_name}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['paid_through_account_name', 'paid_through_account_id']} />
							</li>
						)}
						{/* {criteria?.vendor_id && (
							<li>
								{t('Vendor name  is')} <strong>{criteria?.vendor_name}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['vendor_name', 'vendor_id']} />
							</li>
						)} */}

						{/* {criteria?.reference_number && (
              <li>
                {t('Reference number is')} <strong>{criteria?.reference_number}</strong>
                <span className="and">({t('and')})</span>
                <RemoveFilterICON onRemove={handleRemoveFilter} filter_name={'reference_number'} />
              </li>
            )} */}

						{criteria?.item_type && (
							<li>
								{t('Item type is')} <strong>{criteria?.item_type === 'S' ? t('Services') : t('Goods')}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={'item_type'} />
							</li>
						)}
						{criteria?.unit && (
							<li>
								{t('Item unit is')} <strong>{criteria?.unit}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={'unit'} />
							</li>
						)}
						{criteria?.item_tax && (
							<li>
								{/* {t('Item tax is')} <strong>{taxes?.find((tax) => tax?.value?.toString() === queryParams?.item_tax?.toString())?.[language]}</strong>{' '} */}
								{t('Item tax is')} <strong>{taxList?.find(tax => tax?.value?.value === Number(queryParams?.item_tax) && (tax?.value?.tax_category === queryParams?.item_tax_category))?.label   }</strong>{' '}
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['item_tax','item_tax_category']} />
							</li>
						)}

						{/* item sales account id search */}
						{criteria?.sales_account_name && (
							<li>
								{t('Sales account is')} <strong>{criteria?.sales_account_name}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['sales_account_name', 'sales_account_id']} />
							</li>
						)}
						{criteria?.purchase_account_name && (
							<li>
								{t('Purchase account is')} <strong>{criteria?.purchase_account_name}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['purchase_account_name', 'purchase_account_id']} />
							</li>
						)}

						{criteria?.email && (
							<li>
								{t('Email is')} <strong>{criteria?.email}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={'email'} />
							</li>
						)}
						{criteria?.contact && (
							<li>
								{t('Contact is')} <strong>{criteria?.contact}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={'contact'} />
							</li>
						)}
						{criteria?.outstanding_receivables_from && (
							<li>
								{t('Outstanding receivables is between')}
								<strong>{criteria?.outstanding_receivables_from}</strong> {t('and')} <strong>{criteria?.outstanding_receivables_to}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['outstanding_receivables_from', 'outstanding_receivables_to']} />
							</li>
						)}
						{criteria?.outstanding_payable_from && (
							<li>
								{t('Outstanding payables is between')} <strong>{criteria?.outstanding_payable_from}</strong> {t('and')}{' '}
								<strong>{criteria?.outstanding_payable_to}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['outstanding_payable_from', 'outstanding_payable_to']} />
							</li>
						)}
						{criteria?.balance_from && (
							<li>
								{t('Unused credit is between')} <strong>{criteria?.balance_from}</strong> {t('and')} <strong>{criteria?.balance_to}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['balance_from', 'balance_to']} />
							</li>
						)}
						{criteria?.customer_type && (
							<li>
								{t('Customer type is')} <strong>{criteria?.customer_type === 'B' ? t('Business') : t('Individual')}</strong>{' '}
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={'customer_type'} />
							</li>
						)}

						{criteria?.sales_person_details && (
							<li>
								{t('Sales person is')} <strong>{criteria?.sales_person_name}</strong> <span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['sales_person_name', 'sales_person_details']} />
							</li>
						)}
						{criteria?.created_date_from && criteria?.created_date_to && (
							<li>
								{t('Created date between')} <strong>{moment(criteria.created_date_from).format('DD MMM YYYY')}</strong> {t('and')}{' '}
								<strong>{moment(criteria.created_date_to).format('DD MMM YYYY')}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['created_date_from', 'created_date_to']} />
							</li>
						)}
						{/* for item search popup */}
						{criteria?.item_name && !criteria?.item_id && (
							<li>
								{t('Item name is')} <strong>{criteria?.item_name}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={'item_name'} />
							</li>
						)}
						{/* for invoice, profinv, bill, VC, CN etc.. search popup */}
						{criteria?.item_id && criteria?.item_name && (
							<li>
								{t('Item name is')} <strong>{criteria?.item_name}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['item_id', 'item_name']} />
							</li>
						)}
						{criteria?.transaction_number && (
							<li>
								{t('Number is')} <strong>{criteria?.transaction_number}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={'transaction_number'} />
							</li>
						)}
						{criteria?.[returnedFinalObj?.reference?.value] && (
							<li>
								{t(returnedFinalObj?.reference?.label)} <strong>{criteria?.[returnedFinalObj?.reference?.value]}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={returnedFinalObj?.reference?.value} />
							</li>
						)}
						{criteria?.issue_date_to && criteria?.issue_date_from && (
							<li>
								{t('Issue Date between')} <strong>{moment(criteria.issue_date_from).format('DD MMM YYYY')}</strong> {t('and')}{' '}
								<strong>{moment(criteria.issue_date_to).format('DD MMM YYYY')}</strong> <span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['issue_date_from', 'issue_date_to']} />
							</li>
						)}
						{criteria?.sales_persons && (
							<li>
								{t('Sales person is')} <strong>{criteria?.sales_persons}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={'sales_persons'} />
							</li>
						)}
						{criteria?.payment_status && (
							<li>
								{t('Payment status is')}{' '}
								<strong>
									{criteria?.payment_status === 'PP' && t('Partially paid')}
									{criteria?.payment_status === 'FP' && t('Paid')}
									{criteria?.payment_status === 'NP' && t('Not paid')}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={'payment_status'} />
							</li>
						)}
						{criteria?.net_amount_from && (
							<li>
								{t('Amount between')} <strong>{criteria?.net_amount_from}</strong> {t('and')} <strong>{criteria?.net_amount_to}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['net_amount_from', 'net_amount_to']} />
							</li>
						)}
						{criteria?.[returnedFinalObj?.contact_name?.value] && (
							<li>
								{t(returnedFinalObj?.contact_name?.label)} <strong>{criteria?.[returnedFinalObj?.contact_name?.value]}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={[returnedFinalObj?.contact_name?.value, 'customer_id']} />
							</li>
						)}

						{criteria?.[returnedFinalObj?.vendor_name?.value] && (
							<li>
								{t(returnedFinalObj?.vendor_name?.label)} <strong>{criteria?.[returnedFinalObj?.vendor_name?.value]}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={[returnedFinalObj?.vendor_name?.value, 'vendor_id']} />
							</li>
						)}

						{criteria?.transaction_status && (
							<li>
								{t('Status is')}{' '}
								<strong>
									{criteria?.transaction_status === 'CL' && t('Closed')}
									{criteria?.transaction_status === 'OP' && t('Open')}
									{criteria?.transaction_status === 'DR' && t('Draft')} {/* transfer orders */}
									{criteria?.transaction_status === 'V' && t('Void')}
									{criteria?.transaction_status === 'IN_TRANSIT' && t('In transit')}
									{criteria?.transaction_status === 'TRANSFERRED' && t('Transferred')}
									{criteria?.transaction_status === 'AD' && t('Adjusted')}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={'transaction_status'} />
							</li>
						)}
						{criteria?.payment_mode && (
							<li>
								{t('Payment mode is')} <strong>{payment_modes?.find((mode) => mode._id === criteria?.payment_mode)?.[language]}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={'payment_mode'} />
							</li>
						)}
						{criteria?.deposit_to && (
							<li>
								{t('Account name is')} <strong>{criteria?.deposit_account_name}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['deposit_to', 'deposit_account_name']} />
							</li>
						)}
						{criteria?.status && (
							<li>
								{t('Status is ')}
								<strong>
									{criteria?.status === 'A' && t('Active')}
									{criteria?.status === 'D' && (search_model === 'organizations' ? t('Suspended') : t('Inactive'))}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['status',]} />
							</li>
						)}
						{criteria?.vat_id && (
							<li>
								{t('Vat id is ')}
								<strong>
									{criteria?.vat_id}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['vat_id']} />
							</li>
						)}
						{criteria?.organization_name && (
							<li>
								{t('Organization name is ')}
								<strong>
									{criteria?.organization_name}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['organization_name']} />
							</li>
						)}
						{criteria?.id_of_organization && (
							<li>
								{t('Organization id is ')}
								<strong>
									{criteria?.id_of_organization}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['id_of_organization']} />
							</li>
						)}
						{criteria?.email_id && (
							<li>
								{t('Email id is ')}
								<strong>
									{criteria?.email_id}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['email_id']} />
							</li>
						)}
						{criteria?.phone_no && (
							<li>
								{t('Phone no is ')}
								<strong>
									{criteria?.phone_no}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['phone_no']} />
							</li>
						)}
						{/* FOR RECCURING INVOICES */}
						{criteria?.frequency && (
							<li>
								{t('Frequency is ')}
								<strong>
									{
									(criteria?.frequency === 'days')?'Daily'
									:(criteria?.frequency === 'weeks')?'Weekly'
									:(criteria?.frequency === 'months')?'Monthly':'Yearly'
									}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['frequency']} />
							</li>
						)}
						{criteria?.profile_name && (
							<li>
								{t('Profile name is ')}
								<strong>
									{criteria?.profile_name}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['profile_name']} />
							</li>
						)}
						{/* todo : need to implement search_value could be [profile_name,frequency, contact_name] */}
						{criteria?.search_value && (
							<li>
								{t('Profile name is ')}
								<strong>
									{criteria?.search_value}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['search_value']} />
							</li>
						)}
						{/* For transfer order source & destination store */}						
						{criteria?.from_store_name && criteria?.from_store_id && ('transfer-orders'=== search_model) && (
							<li>
								{t('Source store is')}{' '}
								<strong>
									{criteria?.from_store_name}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['from_store_name','from_store_id']} />
							</li>
						)}
						{criteria?.to_store_name && criteria?.to_store_id &&(
							<li>
								{t('Destination store is')}{' '}
								<strong>
									{criteria?.to_store_name}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['to_store_id','to_store_name']} />
							</li>
						)}
						{/* transfer order date */}
						{criteria?.transfer_date_from && criteria?.transfer_date_to && (
							<li>
								{t('Transfer date between')} <strong>{moment(criteria.transfer_date_from).format('DD MMM YYYY')}</strong> {t('and')}{' '}
								<strong>{moment(criteria.transfer_date_to).format('DD MMM YYYY')}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['transfer_date_from', 'transfer_date_to']} />
							</li>
						)}
						{/* inventory adjustment date */}
						{criteria?.adjustment_date_from && criteria?.adjustment_date_to && (
							<li>
								{t('Adjustment date between')} <strong>{moment(criteria.adjustment_date_from).format('DD MMM YYYY')}</strong> {t('and')}{' '}
								<strong>{moment(criteria.adjustment_date_to).format('DD MMM YYYY')}</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['adjustment_date_from', 'adjustment_date_to']} />
							</li>
						)}
						{criteria?.from_store_name && criteria?.from_store_id && ('adjustments'=== search_model) && (
							<li>
								{t('Store is')}{' '}
								<strong>
									{criteria?.from_store_name}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['from_store_name','from_store_id']} />
							</li>
						)}
						{criteria?.branch && criteria?.branch_id && (
							<li>
								{t('Branch is')}{' '}
								<strong>
									{criteria?.branch}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['branch','branch_id']} />
							</li>
						)}
						{criteria?.reason && criteria?.reason_id && (
							<li>
								{t('Reason is')}{' '}
								<strong>
									{criteria?.reason}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['reason','reason_id']} />
							</li>
						)}
						{criteria?.adjustment_type && (
							<li>
								{t('Adjustment type is')}{' '}
								<strong>
									{criteria?.adjustment_type}
								</strong>
								<span className="and">({t('and')})</span>
								<RemoveFilterICON onRemove={handleRemoveFilter} filter_name={['adjustment_type']} />
							</li>
						)}
					</ul>
					<div>
						<Button color="link" className="p-0 lh-1 nofcs" onClick={onSearchShow}>
							<Icon.Search className="mt-n3x" /> {t('Change criteria')}
						</Button>
					</div>
				</section>
			)}
		</>
	);
}

const RemoveFilterICON = ({ onRemove, filter_name }) => {
	return (
		<span className="dlt">
			<Icon.XLg onClick={() => onRemove(filter_name)} />
		</span>
	);
};
